import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiProvider } from '../Services/api';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { QtyselectorComponent } from '../component/qtyselector/qtyselector.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.css']
})
export class WishlistComponent implements OnInit {
  public wishlist: any = [];
  public items1: any = [];
  constructor(public api: ApiProvider, private router: Router, private sanitizer: DomSanitizer, public dialog: MatDialog) { }

  ngOnInit(): void {
    if (!this.api.userWEBprofile.login) this.router.navigateByUrl("/home");
    else {
      this.getitems();
    }
  }
  getitems() {
    var datout = {
      ID: this.api.userWEBprofile.UID,
      IDCLOUD: this.api.IDCLOUD
    }
    this.api.get(this.api.endpoint + '/wishlist/Getwishlist/' + btoa(JSON.stringify(datout))).subscribe((data: any) => {
        console.log(data);
      this.wishlist = data;

    });
  }
  loadDefault(event: any) {
    (event.target as HTMLImageElement).style.display = 'none';

  }
  getImg(item: any): SafeUrl {
    if (item.ISPHOTO == 'False') return this.sanitizer.bypassSecurityTrustUrl("assets/images/notItems.png");
    else
      return this.sanitizer.bypassSecurityTrustUrl(this.api.endointdata + item.UPC + '.jpg');
  }
  createRange(number: any) {

    this.items1 = [];
    for (var i = 1; i <= Number(number); i++) {
      this.items1.push(i);
    }
    return this.items1;
  }
  async selectItem(item: any) {
    if (this.wishlist.length == 0) return;
    if (item == undefined) return;
    var badge = this.wishlist[item].BADGE;
    if (badge == 0) badge = 1;
    const dialogRef = this.dialog.open(QtyselectorComponent, {
      data: {
        productCount: badge
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //  console.log(result);
      this.setnewval(result, item);
    });
  }

  setnewval(count: any, item: any) {
    var index = this.api.isupc(this.wishlist[item].UPC);
    var price = this.calculateprice(item);
    var oldprice = this.Oldprice(item);

    if (index != -1) {
      if (count == 0)
        this.api.orders.splice(index, 1);
      else {
        this.api.orders[index].QTY = count;
        this.api.orders[index].PRICE = price;
        this.api.orders[index].OLDPRICE = oldprice;

      }
      this.api.UpdateOders();
      this.api.productCount += count;
      this.api.purchaseamount += count * price;
    }
    else {
      if (count > 0) {
        var dat = {
          UPC: this.wishlist[item].UPC,
          DESCRIPTION: this.wishlist[item].DESCRIPTION,
          SIZE: this.wishlist[item].SIZE_IT + " " + this.wishlist[item].CASE_SIZE,
          QTY: count,
          PRICE: price,
          OLDPRICE: oldprice
        }
        this.api.orders.push(dat);
        this.api.UpdateOders();
        this.api.productCount += count;
        this.api.purchaseamount += count * price;
      }
    }
    this.wishlist[item].BADGE = count;
    //if (this.api.orders.length > 0) this.shoppincar.calculation();
    // console.log(this.api.orders);
  }
  calculateprice(item: any) {
    var units;
    if (Number(this.wishlist[item].SPECIAL_PRICE) > 0) {
      if (Number(this.wishlist[item].NP_SPECIAL) == 0) units = 1;
      else
        units = Number(this.wishlist[item].NP_SPECIAL)
      return Number(this.wishlist[item].SPECIAL_PRICE) / units;

    }
    else {
      if (Number(this.wishlist[item].NP_PRICE) == 0) units = 1;
      else
        units = Number(this.wishlist[item].NP_PRICE)
      return Number(this.wishlist[item].PRICE) / units;
    }

  }

  Oldprice(item: any) {
    var units;
    if (Number(this.wishlist[item].SPECIAL_PRICE) > 0) {
      if (Number(this.wishlist[item].NP_PRICE) == 0) units = 1;
      else
        units = Number(this.wishlist[item].NP_PRICE)
      return Number(this.wishlist[item].PRICE) / units;

    }
    else {
      return 0;
    }

  }
  Delete(item: number) {
      var id=this.wishlist[item].ID;
    this.api.delete(this.api.endpoint + '/wishlist/' + id).subscribe(data => {
      this.wishlist.splice(item,1);
      console.log(this.wishlist);
    });
  }
}

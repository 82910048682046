<div class="page-content checkout-page">
<form [formGroup]="checkFormbilling">
    <h2 class="checkout-sep" sytle="font-weight: bolder;">Create New Account</h2>
    <div class="box-border" >
      <ul>
        <li class="row">
          <div class="col-sm-6">
            <label for="first_name" class="required">First Name</label>
            <input type="text" class="input form-control" formControlName="Name">
            <div class="alert"
              *ngIf="checkFormbilling.controls['Name'].invalid && (checkFormbilling.controls['Name'].dirty || checkFormbilling.controls['Name'].touched)">
              First Name is required.
            </div>
          </div>
          <!--/ [col] -->
          <div class="col-sm-6">
            <label for="last_name" class="required">Last Name</label>
            <input type="text" name="" class="input form-control" formControlName="Lastname">
            <div class="alert"
              *ngIf="checkFormbilling.controls['Lastname'].invalid && (checkFormbilling.controls['Lastname'].dirty || checkFormbilling.controls['Lastname'].touched)">
              Last Name is required.
            </div>
          </div>
          <!--/ [col] -->
        </li>
        <!--/ .row -->
        <li class="row">
          <div class="col-sm-6">
            <label for="company_name">Company Name</label>
            <input type="text" name="" class="input form-control" formControlName="Company">
          </div>
          <!--/ [col] -->
          <div class="col-sm-6">
            <label for="email_address" class="required">Email Address</label>
            <input type="email" class="input form-control" name="" formControlName="Email">
            <div class="alert"
              *ngIf="checkFormbilling.controls['Email'].invalid && (checkFormbilling.controls['Email'].dirty || checkFormbilling.controls['Email'].touched)">
              Entry valid EMAIL
            </div>
          </div>
          <!--/ [col] -->
        </li>
        <!--/ .row -->
        <li class="row">
          <div class="col-xs-12">
            <label for="address" class="required">Address</label>
            <input type="text" class="input form-control" name="" formControlName="Address">
            <div class="alert"
              *ngIf="checkFormbilling.controls['Address'].invalid && (checkFormbilling.controls['Address'].dirty || checkFormbilling.controls['Address'].touched)">
              Invalid Address
            </div>
          </div>
          <!--/ [col] -->

        </li>
        <!-- / .row -->

        <li class="row">
          <div class="col-sm-6">
            <label for="city" class="required">City</label>
            <input class="input form-control" type="text" name="" formControlName="City">
            <div class="alert"
              *ngIf="checkFormbilling.controls['City'].invalid && (checkFormbilling.controls['City'].dirty || checkFormbilling.controls['City'].touched)">
              Invalid City
            </div>
          </div>
          <!--/ [col] -->

          <div class="col-sm-6">
            <label class="required">State/Province</label>
            <select class="input form-control" name="" formControlName="State">
              <option value="CT">Connecticut</option>
              <option value="NY">New York</option>
              <option value="Kansas">Kansas</option>
            </select>
            <div class="alert"
              *ngIf="checkFormbilling.controls['State'].invalid && (checkFormbilling.controls['State'].dirty || checkFormbilling.controls['bstate'].touched)">
              Invalid State
            </div>
          </div>
          <!--/ [col] -->
        </li>
        <!--/ .row -->

        <li class="row">
          <div class="col-sm-6">
            <label for="postal_code" class="required">Zip/Postal Code</label>
            <input class="input form-control" type="number" name="" formControlName="Zip">
            <div class="alert"
              *ngIf="checkFormbilling.controls['Zip'].invalid && (checkFormbilling.controls['Zip'].dirty || checkFormbilling.controls['Zip'].touched)">
              Invalid ZipCode
            </div>
          </div>
          <!--/ [col] -->

          <div class="col-sm-6">
            <label class="required">Country</label>
            <select class="input form-control" name="" formControlName="Country">
              <option value="USA">USA</option>
            </select>
            <div class="alert"
              *ngIf="checkFormbilling.controls['Country'].invalid && (checkFormbilling.controls['Country'].dirty || checkFormbilling.controls['Country'].touched)">
              Invalid Country selected
            </div>
          </div>
          <!--/ [col] -->
        </li>
        <!--/ .row -->
        <li class="row">
          <div class="col-sm-6">
            <label for="telephone" class="required">Telephone</label>
            <input class="input form-control" type="tel" name="" formControlName="Phone">
            <div class="alert"
              *ngIf="checkFormbilling.controls['Phone'].invalid && (checkFormbilling.controls['Phone'].dirty || checkFormbilling.controls['Phone'].touched)">
              Invalid Phone number
            </div>
          </div>
          <!--/ [col] -->

          <div class="col-sm-6">
            <label for="fax">Fax</label>
            <input class="input form-control" type="tel" name="" formControlName="Fax">
          </div>
          <!--/ [col] -->

        </li>
        <!--/ .row -->

        <li class="row">
          <div class="col-sm-6">
            <label for="password" class="required">Password</label>
            <input class="input form-control" type="password" name="" formControlName="password">
            <div class="alert"
              *ngIf="checkFormbilling.controls['password'].errors?.minlength ">
              Password must have at least 4 characters
            </div>
          </div>
          <!--/ [col] -->

          <div class="col-sm-6">
            <label for="confirm" class="required">Confirm Password</label>
            <input class="input form-control" type="password" name="" formControlName="compassword" (change)="onPasswordChange()">
            <div class="alert"
              *ngIf="checkFormbilling.controls['compassword'].hasError('mismatch')">
              Password Doesn't match
            </div>
          </div>
          <!--/ [col] -->
        </li>
      </ul>
    </div>
    <div class="loader" *ngIf="Showspinner"></div>
    <div class="cart_navigation">
        <button class="pull-right" mat-raised-button color="warn" [disabled]="checkvalidation()" 
          (click)="Create()">Create</button>
        </div>
    </form>
</div>
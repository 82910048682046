<!-- Breadcrumbs -->
<div class="content1">
    <div class="breadcrumbs">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <ul>
                        <li class="home"> <a routerLink="/home" title="Go to Home Page">Home</a> <span>/</span> </li>
                        <li> <span style="color: black;">{{department}}</span> </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- Breadcrumbs End -->

    <!-- Main Container -->
    <section class="main-container col2-left-layout">
        <div class="container" #scrooll>
            <div class="row">
                <div class="col-sm-9 col-sm-push-3">

                    <article class="col-main">
                        <div style="white-space: nowrap; width: 500px;display: inline-block;">
                            <button mat-button [matMenuTriggerFor]="menu"><mat-icon>menu</mat-icon><h2 class="page-heading"> <span class="page-heading-title"
                                style="font-weight:bolder;position:absolute;margin-top: -30px;margin-left: 20px;">{{department}}</span> </h2></button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item *ngFor="let dep of api.departments"
                                    (click)="selectdep(dep.ID)">{{dep.DESCRIPTION}}</button>
                            </mat-menu>
                            
                        </div>
                        <div class="toolbar">
                            <div class="row">
                                <div class="col-md-4 col-sm-8">
                                    <mat-select [(ngModel)]="subsel" #singleSelect (selectionChange)="selecsubdep()">
                                        <mat-option style="font-size: 12px;" *ngFor="let resul of subdesp "
                                            [value]="resul.SID">
                                            {{resul.DESCRIPTION}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                                <div class="col-sm-2 text-right sort-by hidden-sm">
                                    <label class="control-label" for="input-sort">Sort By:</label>
                                </div>
                              
                                <div class="col-md-3 col-sm-6 text-right">
                                    <select id="input-sort" class="form-control col-sm-3">
                                        <option value="" selected="selected">Default</option>
                                        <option value="">Name (A - Z)</option>
                                        <option value="">Name (Z - A)</option>
                                        <option value="">Price (Low &gt; High)</option>
                                        <option value="">Price (High &gt; Low)</option>
                                        <option value="">Rating (Highest)</option>
                                        <option value="">Rating (Lowest)</option>
                                        <option value="">Model (A - Z)</option>
                                        <option value="">Model (Z - A)</option>
                                    </select>
                                </div>
                              
                                <div class="col-sm-1 text-right show-limit hidden-sm">
                                    <label class="control-label" for="input-limit">Show:</label>
                                </div>
                                <div class="col-md-2 col-sm-6 text-right">
                                    <mat-select [(ngModel)]="inputlimit" #singleSelect (selectionChange)="recall()">
                                        <mat-option [value]="15">15</mat-option>
                                        <mat-option [value]="20">20</mat-option>
                                        <mat-option [value]="25">25</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                        <div class="loader" *ngIf="Showspinner"></div>
                        <div class="category-products">
                            <ul class="products-grid">
                                <li class="item col-lg-4 col-md-4 col-sm-4 col-xs-6"
                                    *ngFor="let item of listitems ;let i=index">
                                    <div class="item-inner">
                                        <div class="item-img">
                                            <div class="item-img-info"> <a class="product-image"
                                                    title="{{item.DESCRIPTION}}"  [routerLink]="['/SingleProduct/',item.UPC]" >
                                                    <img alt="{{item.DESCRIPTION}}" [src]="getImg(item)"
                                                        style="height: 200px; object-fit: contain;"
                                                        (error)="loadDefault($event)">
                                                </a>
                                                <div class="new-label new-top-left" *ngIf="!item.ISNEW">new</div>
                                                <div class="jtv-box-hover">
                                                    <ul class="add-to-links">
                                                        <li><a class="link-quickview" href="javascript:;" (click)="amplifphoto(item.UPC)"><i
                                                                    class="icon-magnifier-add icons"></i><span
                                                                    class="hidden">Quick View</span></a></li>
                                                        <li><a class="link-wishlist" href="javascript:;" (click)="wishlist(item.UPC)"><i
                                                                    class="icon-heart icons"></i><span
                                                                    class="hidden">Wishlist</span></a></li>
                                                        <li><a class="link-compare" href="javascript:;" (click)="compareitem(i)"><i
                                                                    class="icon-shuffle icons"></i><span
                                                                    class="hidden">Compare</span></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="item-info">
                                            <div class="info-inner">
                                                <div class="item-title">
                                                    <h5> <a [routerLink]="['/SingleProduct/',item.UPC]">
                                                            <div>
                                                                <mat-icon matBadge="{{item.BADGE}}" *ngIf="item.BADGE>0"
                                                                    matBadgeOverlap="false" matBadgeColor="warn"
                                                                    matBadgeSize="small"
                                                                    style="position:absolute;margin-left: -40px;margin-top: 10px;">
                                                                </mat-icon>{{item.DESCRIPTION}}
                                                            </div>

                                                        </a></h5>
                                                </div>
                                                <div class="item-content">
                                                    <div class="rating">
                                                        <ng-template ngFor let-item
                                                            [ngForOf]="createRange(item.RATING)">
                                                            <span class="material-icons">star</span>
                                                        </ng-template>
                                                        <label></label>
                                                    </div>
                                                    <div class="item-price">
                                                        <div *ngIf="item.SPECIAL_PRICE>0">
                                                            <img src="assets/images/golder_offer2.png"
                                                                style="width:30px;position: absolute;margin-left: -80px;">
                                                            <div class="price-box"><span class="regular-price"
                                                                    style="color:red"
                                                                    *ngIf="item.NP_SPECIAL>1">{{item.NP_SPECIAL}}/</span><span
                                                                    class="regular-price"
                                                                    style="color:red;font-weight:bolder ;">{{item.SPECIAL_PRICE|currency}}
                                                                    <span style="font-weight: normal;"
                                                                        *ngIf="item.WEIGHT=='True'"> Per pound</span>
                                                                </span>
                                                                <span
                                                                    style="text-decoration:line-through;color: gray; margin-left: 10px;"><span
                                                                        *ngIf="item.NP_PRICE>1">{{item.NP_PRICE}}/</span>{{item.PRICE|currency}}
                                                                    <span style="font-weight: normal;"
                                                                        *ngIf="item.WEIGHT=='True'"> Per pound</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="item.SPECIAL_PRICE===0">
                                                            <div class="price-box"> <span class="regular-price1">
                                                                    <span
                                                                        class="price">{{item.PRICE|currency:'USD'}}</span>
                                                                    <span style="font-weight: normal;"
                                                                        *ngIf="item.WEIGHT=='True'"> Per pound</span>
                                                                </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="action">
                                                        <button class="button btn-cart" type="button" title=""
                                                            data-original-title="Add to Cart"
                                                            (click)="selectItem(i)"><span>Add to Cart</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="toolbar">
                            <div class="row">
                                <div class="col-sm-6 text-left">
                                    <ul class="pagination">
                                        <li><a (click)="rewindfirst()">|&lt;</a></li>
                                        <li><a (click)="rewindnext()">&lt;</a></li>
                                        <li><a (click)="fordwardnext()">&gt;</a></li>
                                    </ul>
                                </div>
                                <!-- <div class="col-sm-6 text-right">Showing 1 to 15 of 25 (2 Pages)</div>-->
                            </div>
                        </div>
                    </article>
                    <!--	///*///======    End article  ========= //*/// -->
                </div>
                <div class="sidebar col-sm-3 col-xs-12 col-sm-pull-9">
                    <aside class="sidebar">
                        <!--
                        <div class="block block-layered-nav">
                            <div class="block-title">
                                <h3>Shop By</h3>
                            </div>
                            <div class="block-content">
                                <p class="block-subtitle">Shopping Options</p>
                                <dl id="narrow-by-list">
                                    <dt class="odd">Price</dt>
                                    <dd class="odd">
                                        <ol>
                                            <li> <a href="#"><span class="price">$0.00</span> - <span
                                                        class="price">$99.99</span></a> (6) </li>
                                            <li> <a href="#"><span class="price">$100.00</span> and above</a> (6) </li>
                                        </ol>
                                    </dd>
                                    <dt class="even">Manufacturer</dt>
                                    <dd class="even">
                                        <ol>
                                            <li> <a href="#">Colorido</a> (20) </li>
                                            <li> <a href="#">Adidas</a> (25) </li>
                                            <li> <a href="#">Barisa Epic</a> (8) </li>
                                            <li> <a href="#">Esbeda</a> (5) </li>
                                        </ol>
                                    </dd>
                                    <dt class="odd">Handbag Material</dt>
                                    <dd class="odd">
                                        <ol class="bag-material">
                                            <li> <a href="#">
                                                    <input type="checkbox" name="Material" value="Beaded">
                                                    Beaded</a></li>
                                            <li> <a href="#">
                                                    <input type="checkbox" name="Material" value="Beaded">
                                                    Canvas</a></li>
                                            <li> <a href="#">
                                                    <input type="checkbox" name="Material" value="Beaded">
                                                    Velvet</a></li>
                                            <li> <a href="#">
                                                    <input type="checkbox" name="Material" value="Beaded">
                                                    Rubber</a></li>
                                            <li> <a href="#">
                                                    <input type="checkbox" name="Material" value="Beaded">
                                                    Leather</a></li>
                                        </ol>
                                    </dd>
                                    <dt class="odd">Color</dt>
                                    <dd class="odd">
                                        <ol>
                                            <li> <a href="#">Green</a> (1) </li>
                                            <li> <a href="#">White</a> (5) </li>
                                            <li> <a href="#">Black</a> (5) </li>
                                            <li> <a href="#">Gray</a> (4) </li>
                                            <li> <a href="#">Dark Gray</a> (3) </li>
                                            <li> <a href="#">Blue</a> (1) </li>
                                        </ol>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                   
                        <div class="block product-price-range ">
                            <div class="block-title">
                                <h3>Price</h3>
                            </div>

                            <div class="block-content">
                                <div class="slider-range">
                                    <div data-label-reasult="Range:" data-min="0" data-max="500" data-unit="$"
                                        class="slider-range-price ui-slider ui-slider-horizontal ui-widget ui-widget-content ui-corner-all"
                                        data-value-min="50" data-value-max="350">
                                        <div class="ui-slider-range ui-widget-header ui-corner-all"
                                            style="left: 10%; width: 60%;"></div>
                                        <span class="ui-slider-handle ui-state-default ui-corner-all" tabindex="0"
                                            style="left: 10%;"></span><span
                                            class="ui-slider-handle ui-state-default ui-corner-all" tabindex="0"
                                            style="left: 70%;"></span>
                                    </div>
                                    <div class="amount-range-price">Range: $10 - $550</div>
                                    <ul class="check-box-list">
                                        <li>
                                            <input type="checkbox" id="p1" name="cc">
                                            <label for="p1"> <span class="button"></span> $20 - $50<span
                                                    class="count">(5)</span> </label>
                                        </li>
                                        <li>
                                            <input type="checkbox" id="p2" name="cc">
                                            <label for="p2"> <span class="button"></span> $50 - $100<span
                                                    class="count">(10)</span> </label>
                                        </li>
                                        <li>
                                            <input type="checkbox" id="p3" name="cc">
                                            <label for="p3"> <span class="button"></span> $100 - $250<span
                                                    class="count">(12)</span> </label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
 -->
                        <div class="block block-cart">
                            <div class="block-title ">
                                <h3>My Cart</h3>
                            </div>
                            <div class="block-content">
                                <div class="summary">
                                    <p class="amount">There are <a routerLink="/ShoppingCart">{{api.productCount}}
                                            items</a>
                                        in your cart.</p>
                                    <p class="subtotal"> <span class="label">Cart Subtotal:</span> <span class="price">
                                            {{api.purchaseamount|currency}}</span> </p>
                                </div>
                                <div class="ajax-checkout">
                                    <button class="button button-checkout" title="Submit"
                                        type="submit"><span>Checkout</span></button>
                                </div>
                                <p class="block-subtitle">Recently added item(s) </p>
                                <ul>
                                    <li class="item" *ngFor="let order of api.orders">
                                        <a routerLink="/ShoppingCart" title="{{order.DESCRIPTION}}"
                                            class="product-image"><img [src]="api.getImgContent(order.UPC)"
                                                alt="{{order.DESCRIPTION}}"></a>
                                        <div class="product-details">
                                            <div class="access"> <a routerLink="/ShoppingCart" title="Remove This Item"
                                                    class="jtv-btn-remove"> <span class="icon"></span> Remove </a>
                                            </div>
                                            <strong>1</strong> x <span class="price">{{order.PRICE|currency}}</span>
                                            <p class="product-name"> <a
                                                    routerLink="/ShoppingCart">{{order.DESCRIPTION}}</a>
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="block block-compare">
                            <div class="block-title ">Compare Products ({{compare.length}})</div>
                            <div class="block-content">
                                <ol id="compare-items">
                                    <li class="item" *ngFor="let comp of compare;let i=index">
                                        <input type="hidden"  class="compare-item-id">
                                        <a class="jtv-btn-remove" title="Remove This Item" href="javascript:;" (click)="deletecompare(i)"></a> <a href="#"
                                            class="product-name"><i class="fa fa-angle-right"></i>{{comp.DESCRIPTION}}</a>
                                    </li>
                                </ol>
                                <div class="ajax-checkout">
                                    <button type="submit" title="Submit"
                                        class="button button-compare" (click)="showcompare()"><span>Compare</span></button>
                                    <button type="submit" title="Submit"
                                        class="button button-clear" (click)="deletecompareall()"><span>Clear</span></button>
                                </div>
                            </div>
                        </div>
 <!--
                        <div class="custom-slider">
                            <div>
                                <div id="carousel-example-generic" class="carousel slide" data-ride="carousel">
                                    <ol class="carousel-indicators">
                                        <li class="active" data-target="#carousel-example-generic" data-slide-to="0">
                                        </li>
                                        <li data-target="#carousel-example-generic" data-slide-to="1" class=""></li>
                                        <li data-target="#carousel-example-generic" data-slide-to="2" class=""></li>
                                    </ol>
                                    <div class="carousel-inner">
                                        <div class="item active"><img src="assets/images/slide3.jpg" alt="slide3">
                                            <div class="carousel-caption">
                                                <h3><a title=" Sample Product" href="#">Big sale</a></h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                                <a class="link" href="#">Shop Now</a>
                                            </div>
                                        </div>
                                        <div class="item"><img src="assets/images/slide1.jpg" alt="slide1">
                                            <div class="carousel-caption">
                                                <h3><a title=" Sample Product" href="#">Bag collection</a></h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                            </div>
                                        </div>
                                        <div class="item"><img src="assets/images/slide2.jpg" alt="slide2">
                                            <div class="carousel-caption">
                                                <h3><a title=" Sample Product" href="#">New special</a></h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <a class="left carousel-control" href="#" data-slide="prev"> <span
                                            class="sr-only">Previous</span> </a> <a class="right carousel-control"
                                        href="#" data-slide="next"> <span class="sr-only">Next</span> </a>
                                </div>
                            </div>
                        </div>
                        <div class="block block-list block-viewed">
                            <div class="block-title">
                                <h3> Recently Viewed</h3>
                            </div>
                            <div class="block-content">
                                <ol id="recently-viewed-items">
                                    <li class="item odd">
                                        <p class="product-name"><a href="#"><i class="fa fa-angle-right"></i>Product
                                                Title
                                                Here</a></p>
                                    </li>
                                    <li class="item even">
                                        <p class="product-name"><a href="#"><i class="fa fa-angle-right"></i>Product
                                                Title
                                                Here</a></p>
                                    </li>
                                    <li class="item last odd">
                                        <p class="product-name"><a href="#"><i class="fa fa-angle-right"></i>Product
                                                Title
                                                Here</a></p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                       
                    <div class="block block-poll">
                        <div class="block-title">
                            <h3>Community Poll</h3>
                        </div>
                        <form id="pollForm" action="#" method="post" onSubmit="return validatePollAnswerIsSelected();">
                            <div class="block-content">
                                <p class="block-subtitle">What is your favorite color</p>
                                <ul id="poll-answers">
                                    <li class="odd">
                                        <input type="radio" name="vote" class="radio poll_vote" id="vote_1" value="1">
                                        <span class="label">
                                            <label for="vote_1">Green</label>
                                        </span>
                                    </li>
                                    <li class="even">
                                        <input type="radio" name="vote" class="radio poll_vote" id="vote_2" value="2">
                                        <span class="label">
                                            <label for="vote_2">Red</label>
                                        </span>
                                    </li>
                                    <li class="odd">
                                        <input type="radio" name="vote" class="radio poll_vote" id="vote_3" value="3">
                                        <span class="label">
                                            <label for="vote_3">Black</label>
                                        </span>
                                    </li>
                                    <li class="last even">
                                        <input type="radio" name="vote" class="radio poll_vote" id="vote_4" value="4">
                                        <span class="label">
                                            <label for="vote_4">Pink</label>
                                        </span>
                                    </li>
                                </ul>
                                <div class="actions">
                                    <button type="submit" title="Vote"
                                        class="button button-vote"><span>Vote</span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                    -->
                        <div class="block block-tags">
                            <div class="block-title">
                                <h3> Popular Tags</h3>
                            </div>
                            <div class="block-content">
                                <div class="tags-list"> <a href="#">Fruits</a> <a href="#">Bag</a> <a href="#">Healthy
                                        Eating</a> <a href="#">Drinks</a> <a href="#">Backpacks</a> <a href="#">Piper
                                        Bag</a> </div>
                                <div class="actions"> <a href="#" class="view-all">View All Tags</a> </div>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </section>
</div>
import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiProvider } from '../Services/api';

declare function showslider(): any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements AfterViewInit {
  title = 'Cherry Valley';
  public leyends = [];
  public logo: any;
  public hide: boolean = false;
  public featured: any = [];
  constructor(public api: ApiProvider, private router: Router) { }

  ngAfterViewInit(): void {

    showslider();

  }

  ngOnInit() {
     
    this.getfeatured();
    this.api.OpenUserprofile();
  }
  loadDefault(event: any) {
    event.target.src = "assets/images/notItems.png";
  }
  loadimage(upc: string) {
    return this.api.endointdata + upc + ".jpg";
  }
  getfeatured() {
    this.api.get(this.api.endpoint + '/StoreItems/Featuretems/' + this.api.IDCLOUD  ).subscribe(data => {
   //     console.log(data);
      if (data.toString().length > 5) {
        this.featured = data;
        //    console.log(this.featured );
      }
    }, error => {
      console.log("error");

    });
  }
  getinfo(item:any){
  
    if(item.NOTES.length>0){
      this.router.navigateByUrl("/SingleProduct", { state: { ID: item.UPC} });
  
    }
  }
  addcar(item: any) {
 
    var dat = {
      UPC: item.UPC,
      DESCRIPTION: item.DESCRIPTION,
      QTY: 1,
      TAX:item.TAX,
      PRICE: item.PRICE
    }
    this.api.orders.push(dat);
    this.api.UpdateOders();
    this.api.productCount = 0;
    this.api.purchaseamount = 0;
    for (var i = 0; i < this.api.orders.length; i++) {

      this.api.productCount += this.api.orders[i].QTY;
      this.api.purchaseamount += this.api.orders[i].QTY * this.api.orders[i].PRICE;
    }
   // console.log(this.api.orders);
  }
  
}



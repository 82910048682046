import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PromotionalItemsComponent } from '../component/promotional-items/promotional-items.component';
import { ApiProvider } from '../Services/api';

@Component({
  selector: 'app-promotional',
  templateUrl: './promotional.component.html',
  styleUrls: ['./promotional.component.css']
})
export class PromotionalComponent implements OnInit {
  public SearchCtrlFilter: FormControl = new FormControl();
  public Items: any = [];
  config: any;
  Showspinner=false;
  public deparments:any=[];
  public depsel:string="0";
  public results: any = "";
  public subscriber: any;
  constructor(public api: ApiProvider,public dialog: MatDialog,private sanitizer: DomSanitizer,
    private router: Router) { }

  ngOnInit(): void {
    if (this.api.userprofile.login == undefined) {
      this.router.navigateByUrl("/home");
    }
    else {
      this.config = {
        itemsPerPage: 7,
        currentPage: 1,
        totalItems: 0
      };
      this.deparments = JSON.parse("[" + (this.api.userprofile.DEPARTMENTS) + "]");
      if (this.deparments.length > 0) this.depsel = this.deparments[0].ID;
      this.loadProduct();
      this.subscriber = this.SearchCtrlFilter.valueChanges
        .subscribe(filtered => {
          this.getsearch(this.SearchCtrlFilter.value);
        },
          error => {
          });
    }
  }
  setcolor(id: string) {
    if (id == this.depsel)
      return "primary";
    else
      return "";
  }
  selectdep(id: string) {
    this.depsel = id;
    this.loadProduct();
  }
  loadProduct() {
    var dataout = {
      STORE: this.api.userprofile.IDCLOUD,
      INDEX: this.api.iniIndex
    }
    this.Showspinner = true;
    this.api.get(this.api.endpoint + '/StoreItems/Getrewards/' + btoa(JSON.stringify(dataout))).subscribe((data: any) => {
     //   console.log(data);
      this.Showspinner = false;
      this.Items = [];
      if (data.length > 0) {

        this.Items = data;


      }
      else {


      }
    }, error => {

    });


  }
  setflag(it: string) {
    return this.api.toBoolean(it);
  }
 
  AddProduct() {
    const dialogRef = this.dialog.open(PromotionalItemsComponent, {
      data: {
        "PRODUCTS": "",
        "DEP": "0"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == "1") this.loadProduct();
    });
  }

  edit(item: any) {
    const dialogRef = this.dialog.open(PromotionalItemsComponent, {
      panelClass: 'my-class',
      data: {
        "PRODUCTS": item,
        "DEP": item.DEPID
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == "1") this.loadProduct();


    });
  }
  delete(id: string) {

    this.api.ConfirmationDlg("This Procedure Delete records permanently, Are you sure you want to continue?",
      "WARNING....", "Yes", "No").then(result => {
        if (result) {
          var dataout = {
            ID: id,
            IDCLOUD: this.api.userprofile.IDCLOUD
          }
          this.api.delete(this.api.endpoint + '/storeitems/' + id).subscribe(data => {
            this.loadProduct();
          });

        }
      })

  }
  getsearch(ev: any) {
    this.depsel = "0";
    // var text = ev.target.value;
    var text = ev;
    // console.log(text.includes('/'));
    // console.log(ev);
    try {

      if (text.length > 4) {
        var dat = {
          IDCLOUD: this.api.userprofile.IDCLOUD,
          TEXT: text
        }

        this.api.get(this.api.endpoint + "/StoreItems/searchitemsST/" + btoa(JSON.stringify(dat))).subscribe(data => {
          console.log(data);
          this.results = data;
        }, error => {
          this.results = [];
        });

      }
    } catch (e) { }
  }

  Selected(UPC: string) {
    var query = "";
    this.results = [];
    console.log(UPC);
    var dataout = {
      UPC: UPC,
      STORE: this.api.userprofile.IDCLOUD
    }
    this.api.get(this.api.endpoint + '/StoreItems/Singletitems/' + btoa(JSON.stringify(dataout))).subscribe((data: any) => {
      console.log(data);
      this.Items = [];
      if (data.length > 0) {

        this.Items = data;


      }
      else {


      }
    }, error => {

    });


  }
  Logout() {
    this.api.istitles = true;
    this.api.userprofile = "";
    this.router.navigateByUrl("/home");
  }
  loadDefault(event: any) {

    //   (event.target as HTMLImageElement).style.display = 'none';
    //this.image= "assets/images/icon1.png";

  }
  getImgContent(item: any): SafeUrl {
    // console.log(imgFile);
    if (item.ISPHOTO == 'False') return "assets/images/icon1.png";
    else
      return this.sanitizer.bypassSecurityTrustUrl(this.api.endointdata + '/data/Images/' + item.ID + '.jpg');
  }

  pageChanged(event: any) {

    this.config.currentPage = event;
  }

}

<div class="breadcrumb mb-0 p-0">
    <div class="row">
        <div class="col-sm-11">
            <h2> <a>
                    <mat-icon>transfer_within_a_station</mat-icon>
                </a>Outgoing Orders</h2>
        </div>
        <div class="col-sm-1">
            <a href="javascript:;" (click)="api.CloseW()"> <img src="assets/images/Close.png" style="width: 40px; "></a>
        </div>
    </div>
</div>
<!--end Header-->

<div class="panel-box mainlist">
    <mat-list>
        <mat-list-item *ngFor="let items of orders;">
            <div style="height: 65px;">
                <label style="margin-left: 5px;font-weight: bold; width: 200px;font-size: 12px;">
                    <mat-icon mat-list-icon>description</mat-icon> Order: {{items.IDTR}}
                </label>
                <label style=" width: 200px;font-size: 12px;"> {{items.DATETR|date: 'MM/dd/yy'}} {{items.TIME}}</label>
                <label style="margin-right:20px ;font-size: 12px;">Status:</label>
                <mat-form-field appearance="outline">
                    <mat-select [(ngModel)]="items.STATUSPROCC" (selectionChange)="changestatsel(items.IDTR,items.STATUSPROCC)">
                        <mat-option *ngFor="let st of status;" [value]="st">
                            {{st}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-list-item>
    </mat-list>

</div>
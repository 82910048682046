<!-- Header-->
<div class="breadcrumb mb-0 p-0">
    <div class="row">
        <div class="col-sm-11">
            <h3> <a>
                    <i class="material-icons">groups</i>
                </a> Users</h3>
        </div>
        <div class="col-sm-1">
            <a href="javascript:;" (click)="api.CloseW()"> <img src="assets/images/Close.png" style="width: 40px; "></a>
        </div>
    </div>
</div>
<!--end Header-->
<div class="panel-box" style="height:auto;width: 90%;margin-left: 20px;">
    <div class="card">
        <div class="card-body">
            <div>
                <div class="table-responsive">
                    <table class="table table-striped"  style="background-color:white;font-size: 12px;">
                        <thead >
                            <tr style="background-color:rgb(37, 61, 88);color: darkorange;">
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">User Disabled</th>
                                <th scope="col">Edit/Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of user">
                                <th scope="row">   <img class="rounded-circle z-depth-2" style="height: 40px;"
                                        [src]="getImgContent(item)" default="assets/images/icon1.png"
                                        (error)="loadDefault($event)"> {{item.NAME}}</th>
                                <td>{{item.EMAIL}}</td>
                                <td>
                                    <section>
                                        <mat-checkbox class="Smargin" [disabled]="true"
                                            [ngModel]="setflag(item.USER_BLOCKED)">
                                        </mat-checkbox>
                                    </section>
                                </td>
                                <td>
                                    <a href="javascript:'" style="font-size: 20px;" (click)="edit(item)">
                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                    </a>
                                    <a href="javascript:'" style="font-size: 20px;margin-left: 20px;"
                                        (click)="delete(item.UID)">
                                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
             
            </div>
          
        </div>
       
    </div>
    <button mat-raised-button style="background-color: rgb(37, 61, 88); color:white;" (click)="Adduser()">
        <mat-icon>person_pin</mat-icon>New User
      </button>

   
</div>
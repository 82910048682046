<div class="container">
    <div class="row">

        <div class="col-sm-11">
            <h1 small>MY DASHBOARD</h1>
        </div>
        <div class="col-sm-1">
            <a href="javascript:;" (click)="api.CloseW()"> <img src="assets/images/Close.png" style="width: 40px; "></a>
        </div>
    </div>
    <!--end page title-->

    <div class="widget-row">
        <div class="row">
            <div class="col-md-6">
                <div class="widget-box clearfix">
                    <div>
                        <h3>New Order</h3>
                        <mat-list role="list">
                            <div class="row">
                                <mat-list-item role="listitem" *ngFor="let item of dalydales">

                                    <div class="col-md-7">
                                        {{item.COMPANY_NAME}}
                                    </div>
                                    <div class="col-md-5">
                                        <span style="font-size: 20px;font-weight: bold; "> {{item.NINV}} </span><i
                                            class="fa fa-plus pull-right"></i>
                                    </div>

                                </mat-list-item>
                            </div>
                        </mat-list>

                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="widget-box clearfix">
                    <div>
                        <h3>Daily Sales</h3>
                        <mat-list role="list">
                            <div class="row">
                                <mat-list-item role="listitem" *ngFor="let item of dalydales">

                                    <div class="col-md-7">
                                        {{item.COMPANY_NAME}}
                                    </div>
                                    <div class="col-md-5">
                                        <span style="font-size: 20px;font-weight: bold; ">
                                            {{item.TOTAL|currency:'USD'}}</span> <i
                                            class="fa fa-shopping-cart pull-right"></i>
                                    </div>

                                </mat-list-item>
                            </div>
                        </mat-list>

                    </div>
                </div>
            </div>

        </div>


        <div class="row">
            <div class="col-md-6">
                <div class="widget-box clearfix">
                    <div>
                        <h3>Pending Orders</h3>
                        <mat-list role="list">
                            <div class="row">
                                <mat-list-item role="listitem" *ngFor="let item1 of pending">

                                    <div class="col-md-7">
                                        {{item1.COMPANY_NAME}}
                                    </div>
                                    <div class="col-md-5">
                                        <span style="font-size: 20px;font-weight: bold; ">{{item1.NINV}} </span> <i
                                            class="fa fa-tasks pull-right"></i>
                                    </div>

                                </mat-list-item>
                            </div>
                        </mat-list>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="widget-box clearfix">
                    <div>
                        <h3>Total Sales / Month</h3>
                        <h1>{{this.totalsales|currency:'USD'}} </h1>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!--end widget-->
    <div class="panel-box">
        <h4>Total Sales (Thousand)</h4>
        <div>
            <canvas id="#lineChart" height="50"></canvas>
        </div>
    </div>
    <div class="col-md-12">
        <div class="ms-panel ms-panel-fh">
          <div class="ms-panel-header">
            <h2>Favourite Products</h2>
          </div>
          <div class="ms-panel-body order-circle">

            <div class="row">
                <div *ngFor="let item of favoritos">
                    <div class="col-md-2" >
                        <h2 class="text-center">{{item.DESCRIPTION}}</h2>
                      <div class="progress-rounded progress-round-tiny">
      
                        <div class="progress-value">{{item.TOT|currency}}</div>
                        <svg>
                          <circle class="progress-cicle bg-success" cx="65" cy="65" r="57" stroke-width="4" fill="none" aria-valuenow="12" aria-orientation="vertical" aria-valuemin="0" aria-valuemax="100" role="slider">
                          </circle>
                        </svg>
                      </div>
                    </div>
                </div>
             
              <!--
              <div class="col-md-3">
                    <h2 class="text-center">Mexican Noodels</h2>
                <div class="progress-rounded progress-round-tiny">
                  <div class="progress-value">38.8%</div>
                  <svg>
                    <circle class="progress-cicle bg-primary" cx="65" cy="65" r="57" stroke-width="4" fill="none" aria-valuenow="38.8" aria-orientation="vertical" aria-valuemin="0" aria-valuemax="100" role="slider">
                    </circle>
                  </svg>
                </div>
              </div>
              <div class="col-md-3">
                  <h2 class="text-center">Spicy Salad</h2>
                <div class="progress-rounded progress-round-tiny">
                  <div class="progress-value">78.8%</div>
                  <svg>
                    <circle class="progress-cicle bg-secondary" cx="65" cy="65" r="57" stroke-width="4" fill="none" aria-valuenow="78.8" aria-orientation="vertical" aria-valuemin="0" aria-valuemax="100" role="slider">
                    </circle>
                  </svg>
                </div>
              </div>
              <div class="col-md-3">
                  <h2 class="text-center">French Fries</h2>
                <div class="progress-rounded progress-round-tiny">
                  <div class="progress-value">100%</div>
                  <svg>
                    <circle class="progress-cicle bg-dark" cx="65" cy="65" r="57" stroke-width="4" fill="none" aria-valuenow="100" aria-orientation="vertical" aria-valuemin="0" aria-valuemax="100" role="slider">
                    </circle>
                  </svg>
                </div>
              </div>
-->
            </div>
          </div>
        </div>
      </div>
</div>
<body>
	<!-- wrapper -->
	<div class="wrapper">

			<div class="row">
				<div class="col-sm-12">
					<div class="card radius-15" style="margin-top:15px">
						<div class="row no-gutters">
							<div class="col-sm-5">
								<div class="card-body p-md-5">
									<div class="text-left">
										<img src="assets/images/logo-img.png" width="180" alt="">
									</div>
									<h4 class="mt-5 font-weight-bold">Generate New Password</h4>
									<div class="form-group mt-5">
										<label>New Password</label>
										<input type="password" class="form-control" [(ngModel)]="password" placeholder="Enter new password" />
									</div>
									<div class="form-group">
										<label>Confirm Password</label>
										<input type="password" class="form-control" [(ngModel)]="confpassword" placeholder="Confirm password" />
									</div>
									<button type="button" class="btn btn-primary btn-block" (click)="Change()">Change Password</button> 
									<a href="javascript:;" class="btn btn-link btn-block" (click)="Close()"><i class='bx bx-arrow-back mr-1'></i>Back to Login</a>
								</div>
							</div>
							<div class="col-sm-7">
								<img src="assets/images/login-images/forgot-password-frent-img.jpg" class="imgclass" alt="...">
							</div>
						</div>
					</div>
				</div>
			</div>
	
	</div>
	<!-- end wrapper -->
</body>

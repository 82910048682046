import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, timeout } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DataService } from './data.service';
import * as CryptoJS from 'crypto-js';
import { throwError } from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDlgComponent } from '../component/confirm-dlg/confirm-dlg.component';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { isNumeric } from 'rxjs/util/isNumeric';
import { ConnectService } from './connect.service';

@Injectable()
export class ApiProvider {
  public userprofile: any = {};
  public userWEBprofile: any = {};
  public menusel: number = -10;
  //==========================================
  public VERSION: string = "1.0.1.82";
  public istest: boolean = false;
  //===========================================
  public departments: any = [];
  public Subdepartments: any = [];
  public compheader: any = [];
  private credentials = "CherryValleyapprbrlink:#&*%B!2019rbr1";
  loaderToShow: any;
  public productCount: number = 0;
  public orders: any = []
  public latitude: string = "";
  public longitude: string = "";
  public ismessIn: boolean = false;
  public menu: any = [];
  public iniIndex: Number = 0;
  public exitapp: boolean = true;
  public GENDER = ['Male', 'Female', 'Unknown'];
  public Language: any = [];
  public endpoint: string = "https://www.cherryvalleymarketplace1.com/api";  //"https://localhost:44398";//"https://www.cherryvalleymarketplace1.com/api";   
  public endointdata: string = "https://www.updatapos.com/data/images/upc/";
  public Profile: any = {};
  public DeviceInfo: any = {};
  public IDCLOUD: string = "1";
  public USERConf: any = [];
  public IsREload: boolean = false;
  public Nnotifications: number = 0;
  public NnotificationsBY: number = 0;
  public fcmid: string = "";
  public isandroid: boolean = true;
  public codeLang: string = "en";
  private labelpipe: any;
  public pathfile: any;
  public miles: string = "";
  public purchaseamount: number = 0;
  public istitles: boolean = true;
  public userimagen: string = "assets/images/icon1.png";
  public points = '0';
  public datepoints: any;
  public status: any = { "bk": "background-color: rgb(253, 7, 7);", "text": "OFFLINE" };
  public Family: any = [{ GRP: '1', NAME: 'FOOD' }, { GRP: '2', NAME: 'FRUITS' }, { GRP: '3', NAME: 'VEGETABLES' }, { GRP: '4', NAME: 'HEALTHY EATING' }, { GRP: '5', NAME: 'DRINKS' }];
  constructor(public http: HttpClient, private storage: StorageMap,
    private sanitizer: DomSanitizer, private _snackBar: MatSnackBar,
    public dialog: MatDialog, private datePipe: DatePipe, public connect: ConnectService,
    private dataService: DataService) {



  }
  CloseW() {
    this.menusel = -10;
  }
  IsLoged() {
    var resp: boolean = true;
    try {

    }
    catch
    {
      resp = false;
    }

    if (this.userprofile == undefined) resp = false;
    if (!this.userprofile.login) resp = false;
    return resp;
  }
  getpoints() {
    return new Promise((resolve, reject) => {
      this.get(this.endpoint + '/loginapp/getpoints/' + this.userWEBprofile.UID).subscribe((data: any) => {
        if (data.length > 0) {
          const resp = JSON.parse(data);
          this.points = resp[0].POINTS;
          this.datepoints = this.datePipe.transform(resp[0].DATEPOINTS, 'MM/dd/yyyy');
          resolve('');
        }
        else
          reject('error');
      });
    });

  }
  getStore() {
    return new Promise((resolve, reject) => {
      this.get(this.endpoint + '/Stores/GetStore/' + this.IDCLOUD).subscribe((data: any) => {
        if (data.length > 0) {
          resolve(data);
        }
        else
          reject('error');
      });
    });

  }
  calcTax(tx: number, price: number) {

    if (tx > 0) {
      var txcalc = 0;
      switch (tx) {
        case 1: {
          txcalc = Number((price * this.compheader.TAX1 / 100).toFixed(3));
          break;
        }
        case 2: {
          txcalc = Number((price * this.compheader.TAX2 / 100).toFixed(3));
          break;
        }
        case 3: {
          txcalc = Number((price * this.compheader.TAX3 / 100).toFixed(3));
          break;
        }
      }
      // console.log(txcalc);
      return txcalc;
    }
    else return 0;

  }
  getYear() {
    return new Date().getFullYear();
  }

  getImgContent(imgFile: any): SafeUrl {

    //  if ( imgFile == null) return 'assets/images/imageNotFound.png';
    imgFile = 'https://www.updatapos.com/data/images/upc/' + imgFile + ".jpg";
    return this.sanitizer.bypassSecurityTrustUrl(imgFile);
  }
  toBoolean(data: string) {
    if (data == undefined) return false;
    if (typeof data == 'boolean') return data;
    var ret: boolean = false;
    switch (data.toUpperCase()) {
      case "1":
        ret = true;
        break;
      case "YES":
        ret = true;
        break;
      case "TRUE":
        ret = true;
        break;
      case "T":
        ret = true;
        break;

    }
    return ret;
  }
  ConfirmationDlg(Message: string, Title: string, Button1: string, Button2: string) {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(ConfirmDlgComponent, {
        width: '500px',
        data: {
          "MESSAGE": Message,
          "TITLE": Title,
          "BUTTON1": Button1,
          "BUTTON2": Button2,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result == undefined) resolve(false);
        else
          resolve(result);


      });


    });

  }
  private Checksum(upc: string) {
    var ret: number = 0;
    var i, x, y, check, m: number;
    var barcode: string;
    if (upc.length == 11) {
      return 0;

    }
    barcode = upc.substring(0, upc.length - 1);
    x = 0;
    y = 0;


    for (i = barcode.length - 1; i > -1; i -= 2) {
      y = y + parseInt(barcode.substring(i, i + 1));
    }
    for (i = barcode.length - 2; i > -1; i -= 2) {
      x = x + parseInt(barcode.substring(i, i + 1));
    }

    check = x + y * 3;

    m = (10 - check % 10) % 10;
    // console.log(barcode);
    // console.log(m);
    //console.log(upc.substring(upc.length-1,upc.length));
    if (m == parseInt(upc.substring(upc.length - 1, upc.length))) {
      ret = 1;
    }
    else {
      ret = 0;
    }

    return ret;
  }
  BooltoNumber(value: Boolean) {

    var ret: number = 0;
    if (value) ret = 1;

    return ret;

  }
  UPCCK(upc: string) {
    var str: string = "";
    if (upc.length >= 12) {
      str = upc.substring(0, upc.length - this.Checksum(upc));
    }
    else {
      if (upc.length >= 7) {

        if (upc.length >= 7 && upc.length <= 9) {
          str = this.UPCE2A(upc).substring(0, 11);

        }
        else {
          str = upc;
        }

      }
    }

    return str;
  }
  private UPCE2A(UPCE: string) {
    var UPCEString: string = "";
    var check: number;
    var Digit1, Digit2, Digit3, Digit4, Digit5, Digit6: string;
    var ManufacturerNumber, ItemNumber: string;
    var Msg, test: string;
    var X: number;

    // check the validity of the input data
    if (!this.isNumber(UPCE)) {
      return UPCE;
    }

    switch (UPCE.length) {
      case 6:
        UPCEString = UPCE;
        break;
      case 7:
        UPCEString = UPCE.substring(0, 5);
        break;
      case 8:

        UPCEString = UPCE.substring(1, 7);
        break;
      default:
        return UPCE;


    }

    //  ' break up the string into its 6 individual digits
    Digit1 = UPCEString.substring(0, 1);
    Digit2 = UPCEString.substring(1, 2);
    Digit3 = UPCEString.substring(2, 3);
    Digit4 = UPCEString.substring(3, 4);
    Digit5 = UPCEString.substring(4, 5);
    Digit6 = UPCEString.substring(5, 6);

    switch (Digit6) {
      case "0":
      case "1":
      case "2":
        ManufacturerNumber = Digit1 + Digit2 + Digit6 + "00";
        ItemNumber = "00" + Digit3 + Digit4 + Digit5;
        break;
      case "3":
        ManufacturerNumber = Digit1 + Digit2 + Digit3 + "00";
        ItemNumber = "000" + Digit4 + Digit5; //            ' original code was in error
        break;
      case "4":
        ManufacturerNumber = Digit1 + Digit2 + Digit3 + Digit4 + "0";
        ItemNumber = "0000" + Digit5; //            ' original code was in error
        break;
      default:
        ManufacturerNumber = Digit1 + Digit2 + Digit3 + Digit4 + Digit5;
        ItemNumber = "0000" + Digit6;
        break;

    }

    //        ' put the number system digit "0" together with the manufacturer code and Item number
    Msg = "0" + ManufacturerNumber + ItemNumber;

    //' calculate the check digit - note UPCE and UPCA check digits are the same
    check = 0; //           ' initialize the check digit value
    var x: number;
    for (x = 0; x < 11; x++) {
      test = Msg.substring(x, x + 1);
      switch (x) {
        case 0:
        case 2:
        case 4:
        case 6:
        case 8:
        case 10:
          check = check + parseInt(test) * 7;     //  ' odd position digits multiplied by 7
          break;
        case 1:
        case 3:
        case 5:
        case 7:
        case 9:
          check = check + parseInt(test) * 9;      //' even position digits multiplied by 9
          break;

      }
    }

    check = (check % 10) + 48;   //' convert value to ASCII character value
    UPCEString = Msg + String.fromCharCode(check);

    return UPCEString;   // ' put the pieces together and return

  }


  doAsyncTask() {
    return new Promise((resolve, reject) => {
      var error = true;
      if (error) {
        reject('error'); // pass values
      } else {
        resolve('listo'); // pass values
      }

    });
  }
  UpdateProfile() {
    return new Promise(async (resolve, reject) => {
      this.storage.set('PROFILE', this.userWEBprofile).subscribe(() => {
        resolve('OK'); // pass values
      });

    });

  }

  async GetOrders() {
    return new Promise(async (resolve, reject) => {
      this.storage.get('ORDERS' + this.IDCLOUD).subscribe((val: any) => {
        //    console.log(val);
        if (val != null) {
          this.orders = val;
          this.productCount = 0;
          this.purchaseamount = 0;
          for (var i = 0; i < this.orders.length; i++) {

            this.productCount += this.orders[i].QTY;
            this.purchaseamount += this.orders[i].QTY * this.orders[i].PRICE;
          }
        }
        resolve('OK');
      });
    });
  }
  UpdateOders() {
    return new Promise(async (resolve, reject) => {
      this.storage.set('ORDERS' + this.IDCLOUD, this.orders).subscribe(() => {
        resolve('OK'); // pass values
      });

    });
  }
  DeleteOrder() {

    return new Promise(async (resolve, reject) => {
      this.orders = [];
      this.storage.delete('ORDERS' + this.IDCLOUD).subscribe(() => {
        resolve('OK'); // pass values
      });

    });

  }


  OpenUserprofile() {

    this.userWEBprofile.login = false;
    console.log("open Profile")

    return new Promise((resolve, reject) => {

      this.storage.get('PROFILE').subscribe(async (val: any) => {
        // console.log(val);
        //  val=null;
        if (val == null) {
          this.userWEBprofile.UID = "";
          this.userWEBprofile.LANGUAGE = "1";
          this.userWEBprofile.NAME = "";
          this.userWEBprofile.EMAIL = "";
          this.userWEBprofile.login = false;
          this.storage.set('PROFILE', this.userWEBprofile).subscribe(() => {

          });
        }
        else {
          this.userWEBprofile = val;
        }
        await this.OpenConfiguration();
        resolve('OK'); // pass values
      });

    });

  }


  OpenConfiguration() {
    console.log("Open Configuration");
    return new Promise(async (resolve, reject) => {

      await this.get(this.endpoint + '/configuration').subscribe(async datalng => {
        //     console.log(datalng);
        this.Language = datalng;

        if (this.userWEBprofile.LANGUAGE == undefined) this.userWEBprofile.LANGUAGE = this.Language.filter((x: { CODELNG: string; }) => x.CODELNG === this.codeLang)[0].ID;
        if (this.userWEBprofile.LANGUAGE == "0") this.userWEBprofile.LANGUAGE = this.Language.filter((x: { CODELNG: string; }) => x.CODELNG === this.codeLang)[0].ID;
        // if (!this.userWEBprofile.login) this.userWEBprofile.LANGUAGE = this.userWEBprofile.language = this.Language.filter(x => x.codelng === this.codeLang)[0].id;
        await this.get(this.endpoint + '/configuration/' + this.userWEBprofile.LANGUAGE.toString()).subscribe(data => {
          //   console.log(data);
          this.USERConf = data;
          if (data != '{}') {

            this.USERConf = data;
            this.storage.set('USERCNF', this.USERConf);
          }
          else {
            this.storage.get('USERCNF').subscribe((val: any) => {

              this.USERConf = val;
            });
          }
          resolve('OK'); // pass values
        })
      });
    });

  }

  getLabel(filter: any) {

    return this.labelpipe.transform(this.USERConf, filter);
  }

  isupc(upc: any) {
    for (var i = 0; i < this.orders.length; i += 1) {
      if (this.orders[i]["UPC"] === upc) {
        return i;
      }
    }
    return -1;
  }


  totalCar() {
    var total = 0;
    for (var i = 0; i < this.orders.length; i += 1) {
      total += this.orders[i]["QTY"]
    }
    return total;
  }

  getbadge(upc: any) {
    var dat = this.orders.filter((x: { UPC: any; }) => x.UPC === upc)[0];
    if (dat != undefined)
      return dat.QTY;
    else
      return 0;
  }

  getIDdep(GRP: string) {
    return this.departments.filter((x: { GRP: string; }) => x.GRP == GRP)[0].ID;
  }
  getDepatName(id: string) {
    return this.departments.filter((x: { ID: string; }) => x.ID == id)[0].DESCRIPTION;
  }
  getIDDepatName(name: string) {
    return this.departments.filter((x: { DESCRIPTION: string; }) => x.DESCRIPTION == name)[0].ID;
  }
  getDepatgroup(GP: string) {
    return this.departments.filter((x: { GRP: string; }) => x.GRP == GP);
  }
  getsubDepat(ID: string) {
    var ret: any = [];
    for (let i = 0; i < this.Subdepartments.length; i++) {
      if (this.Subdepartments[i].ID == ID) {
        var dat = {
          INDEX: i,
          ID: this.Subdepartments[i].ID,
          SID: this.Subdepartments[i].SID,
          DESCRIPTION: this.Subdepartments[i].DESCRIPTION
        }
        ret.push(dat);
      }
    }
    return ret;  //this.Subdepartments.filter((x: { ID: string; }) => x.ID == ID);
  }
  getLanguage(id: any) {

    return this.Language.filter((x: { ID: any; }) => x.ID == id)[0].LANGUAGE;
  }
  getIDLanguage(id: any) {
    return this.Language.filter((x: { LANGUAGE: any; }) => x.LANGUAGE == id)[0].ID;
  }
  getfamily(id: any) {
    try {
      return this.Family.filter((x: { GRP: any; }) => x.GRP == id)[0].NAME;
    }
    catch {
      return "";
    }

  }
  getfamilyID(name: any) {
    return this.Family.filter((x: { NAME: any; }) => x.NAME == name)[0].GRP;
  }
  AddSpace(spaces: number) {
    var ret = "";
    var i: number;
    for (i = 0; i < spaces; i++) {
      ret += ' ';
    }

    return ret;
  }

  getcardname(cc: String) {
    switch (cc.substring(0, 1)) {
      case "3":
        return "American";
        break;
      case "4":
        return "Visa";
        break;
      case "5":
        return "MC";
        break;
      case "6":
        return "Discover";
        break;
      default:
        return "Unknown"

    }
  }
  Encrypreq(datastr: string) {
    let str: string = "";
    let i: number;
    let cadena: string;
    for (i = 0; i < datastr.length; i++) {
      cadena = datastr.substr(i, 1);
      if (cadena != "-") {
        str += String.fromCharCode(cadena.charCodeAt(0) + 49);
      }
      else
        str += "Z";
    }
    return str;
  }



  getendpoint() {
    return this.endpoint;
  }

  get(endpoint: string) {
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', `Basic ${btoa(this.credentials)}`)
    }
    return this.http.get(endpoint, header);
  }

  post(endpoint: string, Data: any) {
    let hdr = {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${btoa(this.credentials)}`
    };
    let options = { headers: hdr };
    return this.http.post(endpoint, Data, options);
  }

  postDatacapToken(endpoint: string, Data: any, apikey: string, time = 30000) {
    let hdr = {
      'Content-Type': 'application/json',
      'Authorization': apikey
    };

    let options = { headers: hdr };
    return this.http.post(endpoint, Data, options)
      .pipe(
        timeout(time),
        catchError(err => throwError(err))
      );
  }

  postDatacapSale(endpoint: string, Data: any) {
    let hdr = {
      //  'Content-Type': 'application/json',
      'Authorization': 'RBRLIBAYS8ED',
      // 'User-Agent': 'FOOD123',
      'Accept': 'application/json'
    };

    let options = { headers: hdr };
    return this.http.post(endpoint, Data, options);
  }
  putDatacapSale(endpoint: string, Data: any) {
    let hdr = {
      //  'Content-Type': 'application/json',
      'Authorization': 'RBRLIBAYS8ED',
      // 'User-Agent': 'FOOD123',
      'Accept': 'application/json'
    };

    let options = { headers: hdr };
    return this.http.put(endpoint, Data, options);
  }
  put(endpoint: string, Data: any) {
    let hdr = {
      'Content-Type': 'application/json',
      'Accept': '*.*',
      'Authorization': `Basic ${btoa(this.credentials)}`
    };
    let options = { headers: hdr };


    return this.http.put(endpoint, Data, options)
      .pipe(
        timeout(30000),
        catchError(err => throwError(this.handleError(err)))
      );
  }
  handleError(error: HttpErrorResponse) {
    console.log(error);
    return error;

  }
  delete(endpoint: string) {
    let hdr = {
      'Content-Type': 'application/json',
      'Authorization': 'RBRLIBAYS8ED',
      'Accept': 'application/json'
    };

    let options = { headers: hdr };
    return this.http.delete(endpoint, options);
  }

  isNumber(str: string) {
    return isNumeric(str);
  }

  IntToBin(IntegerNumber: number) {
    var intnum: number;
    var BinValue: string = "";
    var TempValue: number;
    var mask: string = "00000000";
    intnum = IntegerNumber;
    do {
      TempValue = intnum % 2;
      BinValue = TempValue.toString() + BinValue
      intnum = Math.floor(intnum / 2);
    } while (intnum > 0);
    return mask.substring(0, 8 - BinValue.length) + BinValue;
  }

  presentToast(message: string, action: string, time: number = 2000) {

    this._snackBar.open(message, action, {
      duration: time,
    });

  }


  ConvertDate(data: string) {
    if (data == undefined) return "";
    if (data.length == 0) return "";
    data = data.replace(",", "");
    let datestr: string[] = data.split(" ");
    let cleandate: string[] = datestr[0].split("/");
    var mask: string = "00";
    var maskyear: string = "2000";
    var newdate: string = maskyear.substring(0, 4 - cleandate[2].length) + cleandate[2] + "-" + mask.substring(0, 2 - cleandate[0].length) + cleandate[0] + "-" + mask.substring(0, 2 - cleandate[1].length) + cleandate[1];
    if (newdate == "01/01/1900") newdate = "";
    return newdate
  }

  SaveCreditcard(data: any) {
    this.storage.set('NOTES', this.EncrypAES(JSON.stringify(data)));
  }
  deleteCreditcard() {
    this.storage.set('NOTES', "");
  }
  GetCreditcard() {
    return new Promise((resolve, reject) => {
      var ret;
      this.storage.get('NOTES').subscribe((val: any) => {
        if (val == null)
          reject("{}");
        else
          resolve(this.DecrypAES(val)); // pass values
      });

    })

  }

  EncrypAES(plainText: string) {
    var conversionEncryptOutput: string;

    conversionEncryptOutput = CryptoJS.AES.encrypt(plainText, "Rbrlink" + this.DeviceInfo.UUID + "2020!").toString();

    return btoa(conversionEncryptOutput);

  }

  EncrypAESCC(plainText: string) {
    var tokenFromUI = "RbrlinkSvr#2020!";
    var key = CryptoJS.enc.Hex.parse('000102030405060708090a0b0c0d0e0f');
    var iv = CryptoJS.enc.Hex.parse('101112131415161718191a1b1c1d1e1f');
    let encrypted = CryptoJS.AES.encrypt(
      plainText, key, {
      keySize: 16,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }

  DecrypAES(decrystr: string) {

    var conversionDecryptOutput: string;
    conversionDecryptOutput = CryptoJS.AES.decrypt(atob(decrystr), "Rbrlink" + this.DeviceInfo.UUID + "2020!").toString(CryptoJS.enc.Utf8);

    return conversionDecryptOutput;
  }
  loadImage(URL: any) {
    return new Promise(resolve => {
      var img = new Image();
      img.crossOrigin = "anonymous";
      img.src = URL;
      img.onload = function () {
        var c = document.createElement('canvas');
        c.width = img.naturalWidth;
        c.height = img.naturalHeight;
        var ctx: any = c.getContext("2d");
        //  console.log(img.naturalWidth);
        //  console.log(img.naturalHeight);
        ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
        // var imageData = canvas.toDataURL('image/jpeg').replace(/^data:image\/(png|jpg|jpeg);base64,/, "");
        var imgdata = c.toDataURL("image/jpg");
        //    var imgback = <HTMLImageElement>document.getElementById("imgprod");
        //  imgback.src = imgdata;
        resolve(imgdata);
      }
    });
  }


}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public data:any={
    route:"",
    data:""
  };
  private dataSource = new BehaviorSubject(this.data);
  currentData = this.dataSource.asObservable();
  constructor() { }

  changeData(data: any) {
    this.dataSource.next(data);
  }
}

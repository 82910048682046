<div style="background-color: rgb(0, 0, 0);;padding-left: 4%;">
  <h3 style="color:rgb(243, 155, 23)">Agencias</h3>
</div>
<br>

<mat-card class="info">
  <mat-card-content style="margin-top: 5px;">
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <mat-form-field class="full-width" appearance="outline">
          <input matInput type="text" placeholder="Name" [(ngModel)]="this.agencydata.AGENCY">
        </mat-form-field>
      </div>
      <div class="col-md-3 mb-3">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Zone</mat-label>
          <mat-select [(ngModel)]="this.zonesel">
            <mat-option *ngFor="let ct of zone" [value]="ct.ID" (onSelectionChange)="updatezone(ct.ID)">{{ct.ZONE}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-5 mb-3">
        <mat-form-field class="full-width" appearance="outline">
          <input matInput type="text" placeholder="Address" [(ngModel)]="this.agencydata.ADDRESS">
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <mat-form-field class="full-width" appearance="outline">
          <input matInput type="text" placeholder="City" [(ngModel)]="this.agencydata.CITY">
        </mat-form-field>
      </div>
      <div class="col-md-3 mb-3">
        <mat-form-field class="full-width" appearance="outline">
          <input matInput type="text" placeholder="State" [(ngModel)]="this.agencydata.STATE">
        </mat-form-field>
      </div>
      <div class="col-md-5 mb-3">
        <mat-form-field class="full-width" appearance="outline">
          <input matInput type="text" placeholder="ZipCode" [(ngModel)]="this.agencydata.ZIPCODE">
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <mat-form-field class="full-width" appearance="outline">
          <input matInput type="email" placeholder="Email" [(ngModel)]="this.agencydata.EMAIL">
        </mat-form-field>
      </div>
      <div class="col-md-3 mb-3">
        <mat-form-field class="full-width" appearance="outline">
          <input matInput type="tel" placeholder="Phone" [(ngModel)]="this.agencydata.MOBILE">
        </mat-form-field>
      </div>
      <div class="col-md-5 mb-3">
        <mat-form-field class="full-width" appearance="outline">
          <input matInput type="text" placeholder="WebSite" [(ngModel)]="this.agencydata.WEBSITE">
        </mat-form-field>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<br>
<br>
<div style="text-align: center;">
  <button mat-raised-button color="accent"  style="margin-left: 20px;" (click)="Updatedata()">
    <mat-icon>check</mat-icon>Grabar</button>
  <button mat-raised-button color="light" style="margin-left: 20px;" (click)="Close()">
    <mat-icon>close</mat-icon>Close</button>
</div>
import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';
import { DatePipe } from '@angular/common';
import { ApiProvider } from '../Services/api';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
public favoritos:any=[];
  constructor(public api: ApiProvider, private datePipe: DatePipe) { }
  public dalydales: any = [];
  public pending: any = [];
  public chartdata: any = [];
  public totalsales: string = "0";
  public mixedChart: any;
  public dataset: any = [];
  ngOnInit(): void {
    this.getdata();



  }
  getMonth(data: string) {
    var ret = ""
    var d1 = data.split("-");
    var year = d1[0];
    var month = d1[1];
    switch (month) {
      case "1": {
        ret = "Jan/";
        break;
      }
      case "2": {
        ret = "Feb/";
        break;
      }
      case "3": {
        ret = "Mar/";
        break;
      }
      case "4": {
        ret = "Apr/";
        break;
      }
      case "5": {
        ret = "May/";
        break;
      }
      case "6": {
        ret = "Jun/";
        break;
      }
      case "7": {
        ret = "Jul/";
        break;
      }
      case "8": {
        ret = "Aug/";
        break;
      }
      case "9": {
        ret = "Sep/";
        break;
      }
      case "10": {
        ret = "Oct/";
        break;
      }
      case "11": {
        ret = "Nov/";
        break;
      }
      case "12": {
        ret = "Dec/";
        break;
      }
      default: {
        ret = "";
        break;
      }
    }
    return ret + year.substring(2, 4);
  }
  getdata() {
    this.api.get(this.api.endpoint + '/dashboard').subscribe((data: any) => {
      //    console.log(data);
      this.dalydales = JSON.parse(data.DAILYSALES);
      this.pending = JSON.parse(data.PENDING);
      this.chartdata = JSON.parse(data.CHARTDATA);
      this.totalsales = data.TOTALSALES;
      if (this.chartdata.length > 0) {
        var daterange: Date[] = [];
        var daterangesort: Date[] = [];
        var lbs: any = [];
        for (let i = 0; i < this.chartdata.length; i++) {
          if (daterange.filter((x: Date) => x.toDateString() === new Date(this.chartdata[i].DATEIN).toDateString()).length == 0) {
            daterange.push(new Date(this.chartdata[i].DATEIN));
          }
        }
        daterangesort = daterange.sort((date1, date2) => new Date(date1).setHours(0, 0, 0, 0) - new Date(date2).setHours(0, 0, 0, 0));
        var j: number;
        var t: any = -1;
        var agency: string = "";

        for (let i = 0; i < daterangesort.length; i++) {
          lbs.push(this.datePipe.transform(daterangesort[i], 'MMM/yy'));
        }



        for (let i = 0; i < this.chartdata.length; i++) {
          if (this.chartdata[i].COMPANY_NAME !== agency) {
            var dt: any = {};
            t = t + 1;
            agency = this.chartdata[i].COMPANY_NAME;
            dt.type='line';
            dt.label = this.chartdata[i].COMPANY_NAME;
            dt.fill = true;
            dt.tension = 0.3;
            dt.borderColor = 'rgb(235, ' + String(162 - t * 30) + ', 59,1)';
            dt.backgroundColor = 'rgb(235, ' + String(162 - t * 30) + ', 59,.4)';
            dt.order = t;
            dt.data = [];
            this.dataset.push(dt);
            for (let x = 0; x < daterangesort.length; x++)  dt.data.push(0);

          }
          j = daterangesort.findIndex((x: Date) => x.toDateString() === new Date(this.chartdata[i].DATEIN).toDateString());
          if (j > -1) {
            this.dataset[t].data[j] = Number(this.chartdata[i].TOTAL).toFixed(2);
          }

        }
        console.log(this.dataset);
        var canvas = <HTMLCanvasElement>document.getElementById("#lineChart");
        var ctx: any = canvas.getContext("2d");
        if (this.mixedChart != undefined) this.mixedChart.destroy();
            this.mixedChart = new Chart(ctx, {
              type: 'line',
              data: {
                datasets: this.dataset,
                labels: lbs
              },
              options: {
    
              }
            });
    
   
      }
    }, error => {
    });
    this.api.get(this.api.endpoint + '/Orders/GetFavourites/' + this.api.IDCLOUD).subscribe((data: any) => {
      console.log(data);
      this.favoritos=data;
    });
  }

}

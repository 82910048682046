import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { QtyselectorComponent } from '../component/qtyselector/qtyselector.component';
import { ApiProvider } from '../Services/api';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AmplPhotoComponent } from '../ampl-photo/ampl-photo.component';

@Component({
  selector: 'app-shop-grid',
  templateUrl: './shop-grid.component.html',
  styleUrls: ['./shop-grid.component.css']
})
export class ShopGridComponent implements OnInit {
  private endlist: boolean = false;
  public department: string = "";
  private lastdepsel: string = "";
  public listitems: any = [];
  public Showspinner: boolean = false;
  public isreading: boolean = false;
  public items1: any = [];
  public subdesp: any = [];
  public subsel: string = "";
  public inputlimit: Number = 15;
  public searchin: string = '';
  public compare: any = [];

  @ViewChild('scrooll') private scroll!: ElementRef;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, public dialog: MatDialog,
    public api: ApiProvider, private sanitizer: DomSanitizer) {
    this.activatedRoute.params.subscribe(params => {
      this.subsel = '';
      this.api.iniIndex = 0;
      this.listitems = [];
      var listparam = params['ID'].split(',');
      //  console.log(listparam);
      this.lastdepsel = listparam[0];
      if (listparam[1] != '*') this.subsel = listparam[1];
      this.searchin = listparam[2];
      if (this.searchin.length > 0) {
        this.GetItems(this.searchin);
      }
      else {
        this.getinfo();
      }


    });



  }
  ScrollTotop() {
    /*
    setTimeout(() => {
   //   console.log("abajo");
      this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;
  }, 1000);
   */
    window.scroll(0, 0);
  }
  async getinfo(): Promise<void> {
    if (this.api.IDCLOUD != undefined) {
      this.listitems = [];
      this.api.iniIndex = 0;

      if (this.api.departments.length == 0) await this.GetDepart();
      else {

        this.department = this.api.getDepatName(this.lastdepsel);
        this.updatedep();
        this.GetItems(this.lastdepsel);
      }

    }

  }
  async GetDepart() {
    await this.api.get(this.api.endpoint + '/StoreItems/GetDepartment/' + btoa(this.api.IDCLOUD)).subscribe((data: any) => {
      // console.log(data);
      this.api.departments = JSON.parse(data.DEP.toString());
      this.api.Subdepartments = JSON.parse(data.SUB.toString());
      for (let i = 0; i < this.api.departments.length; i++) {
        if (i == 0) this.api.departments[i].selected = "1";
        else this.api.departments[i].selected = "0";
      }
      this.department = this.api.getDepatName(this.lastdepsel);
      this.updatedep();
      this.GetItems(this.lastdepsel);
    });

  }

  selectdep(id: string) {
    this.searchin = "";
    this.lastdepsel = id;
    this.department = this.api.getDepatName(this.lastdepsel);
    this.listitems = [];
    this.updatedep();
    this.GetItems(this.lastdepsel);
  }

  ngOnInit(): void {

    if (this.api.IDCLOUD == undefined) {
      this.router.navigateByUrl("\home");
    }


  }

  createRange(number: any) {

    this.items1 = [];
    for (var i = 1; i <= Number(number); i++) {
      this.items1.push(i);
    }
    return this.items1;
  }
  updatedep() {
    this.subdesp = this.api.getsubDepat(this.lastdepsel);
    if (this.subsel == '') this.subsel = this.subdesp[0].SID;
  }
  loadDefault(event: any) {
    (event.target as HTMLImageElement).style.display = 'none';

  }
  getImg(item: any): SafeUrl {
    if (item.ISPHOTO == 'False') return this.sanitizer.bypassSecurityTrustUrl("assets/images/notItems.png");
    else
      return this.sanitizer.bypassSecurityTrustUrl(this.api.endointdata + item.UPC + '.jpg');
  }
  GetItems(descp: any) {
    var dist = this.api.miles;
    //if (dist.indexOf("undefined") > -1) dist = dist.replace("undefined", "");

    //dist = dist.replace("miles", "");
    var url = '/StoreItems/Getitems/';
    if (this.searchin.length > 0) url = '/StoreItems/Getsearchitems/';
    var dataout = {
      DEP: descp,
      SUB: this.subsel,
      STORE: this.api.IDCLOUD,
      DISTANCE: "1", //dist.replace("millas", ""),
      "LOCATIONLAT": "1", //  this.api.latitude,
      "LOCATIONLON": "1", // this.api.longitude,
      NITEMS: this.inputlimit,
      INDEX: this.api.iniIndex
    }
    this.Showspinner = true;
    //   console.log(dataout);
    this.api.get(this.api.endpoint + url + btoa(JSON.stringify(dataout))).subscribe(data => {
      //   console.log(data);
      this.Showspinner = false;
      if (data == null) {

        return;
      }

      if (data.toString().length > 0) {

        this.listitems = this.listitems.concat(data);
        //   console.log(this.listitems);
        for (var i = 0; i < this.listitems.length; i += 1) {
          this.listitems[i]["BADGE"] = this.api.getbadge(this.listitems[i]["UPC"]);
        }

        this.api.iniIndex = this.listitems[this.listitems.length - 1].ID;
        this.isreading = false;

        if (data.toString().length < 159) {
          this.endlist = true;

          // this.api.presentToast("No more records...", "", "bottom");
          this.isreading = false;
          this.Showspinner = false;

        }
      }
      else {

        this.endlist = true;
        this.api.presentToast("No more records...", "", 2000);
        this.isreading = false;
        this.Showspinner = false;

      }
    }, error => {
      console.log("error");
      this.isreading = false;
      this.Showspinner = false;

    });


  }
  amplifphoto(upc: string) {

    const dialogRef = this.dialog.open(AmplPhotoComponent, {
      width: '500px', height: '500px', data: {
        "IMAGE": upc
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  wishlist(upc: string) {
    if (this.api.userWEBprofile.login) {
      var dataout: any = {
        UID: this.api.userWEBprofile.UID,
        UPC: upc,
        QTY: 1
      };
    //  console.log(dataout);
      this.api.post(this.api.endpoint + '/wishlist', dataout).subscribe(async data => {
      //  console.log(data);

      });
    }
    else {
      this.router.navigateByUrl("\AccountPage");
    }
  }

  recall() {
    this.listitems = [];
    this.api.iniIndex = 0;
    this.GetItems(this.lastdepsel);
    this.ScrollTotop();
  }
  selecsubdep() {
    this.searchin = "";
    this.listitems = [];
    this.api.iniIndex = 0;
    this.GetItems(this.lastdepsel);
  }
  async selectItem(item: any) {
    if (this.listitems.length == 0) return;
    if (item == undefined) return;
    var badge = this.listitems[item].BADGE;
    if (badge == 0) badge = 1;
    const dialogRef = this.dialog.open(QtyselectorComponent, {
      data: {
        productCount: badge
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //  console.log(result);
      this.setnewval(result, item);
    });
  }
  setnewval(count: any, item: any) {
    var index = this.api.isupc(this.listitems[item].UPC);
    var price = this.calculateprice(item);
    var oldprice = this.Oldprice(item);

    if (index != -1) {
      if (count == 0)
        this.api.orders.splice(index, 1);
      else {
        this.api.orders[index].QTY = count;
        this.api.orders[index].PRICE = price;
        this.api.orders[index].OLDPRICE = oldprice;

      }
      this.api.UpdateOders();
      this.api.productCount += count;
      this.api.purchaseamount += count * price;
    }
    else {
      if (count > 0) {
        var dat = {
          UPC: this.listitems[item].UPC,
          DESCRIPTION: this.listitems[item].DESCRIPTION,
          SIZE: this.listitems[item].SIZE_IT + " " + this.listitems[item].CASE_SIZE,
          QTY: count,
          PRICE: price,
          OLDPRICE: oldprice
        }
        this.api.orders.push(dat);
        this.api.UpdateOders();
        this.api.productCount += count;
        this.api.purchaseamount += count * price;
      }
    }
    this.listitems[item].BADGE = count;
    //if (this.api.orders.length > 0) this.shoppincar.calculation();
    // console.log(this.api.orders);
  }
  calculateprice(item: any) {
    var units;
    if (Number(this.listitems[item].SPECIAL_PRICE) > 0) {
      if (Number(this.listitems[item].NP_SPECIAL) == 0) units = 1;
      else
        units = Number(this.listitems[item].NP_SPECIAL)
      return Number(this.listitems[item].SPECIAL_PRICE) / units;

    }
    else {
      if (Number(this.listitems[item].NP_PRICE) == 0) units = 1;
      else
        units = Number(this.listitems[item].NP_PRICE)
      return Number(this.listitems[item].PRICE) / units;
    }

  }
  rewindfirst() {
    this.listitems = [];
    this.api.iniIndex = 0;
    this.GetItems(this.lastdepsel);
    this.ScrollTotop();
  }
  rewindnext() {
    this.listitems = [];
    this.api.iniIndex = -this.inputlimit;
    this.GetItems(this.lastdepsel);
    this.ScrollTotop();
  }

  fordwardnext() {
    this.listitems = [];
    this.GetItems(this.lastdepsel);
    this.ScrollTotop();
  }
  Oldprice(item: any) {
    var units;
    if (Number(this.listitems[item].SPECIAL_PRICE) > 0) {
      if (Number(this.listitems[item].NP_PRICE) == 0) units = 1;
      else
        units = Number(this.listitems[item].NP_PRICE)
      return Number(this.listitems[item].PRICE) / units;

    }
    else {
      return 0;
    }

  }
  compareitem(item: any) {
    console.log(this.listitems[item]);
    var dat = {
      ID: this.listitems[item].ID,
      DESCRIPTION: this.listitems[item].DESCRIPTION
    }
    this.compare.push(dat);
  }
  deletecompareall() {
    this.compare = [];
  }
  deletecompare(item: any) {
    this.compare.splice(item, 1);

  }
  showcompare() {
    var listi: string = "";
    for (let i = 0; i < this.compare.length; i++) {
      listi = listi + this.compare[i].ID;
      if (i < this.compare.length - 1) listi = listi + ",";
    }
    var dataout = {
      LISTITEMS: listi,
      STORE: this.api.IDCLOUD,
    }
  //  console.log(dataout);
    this.Showspinner = true;
    //   console.log(dataout);
    this.api.get(this.api.endpoint + "/StoreItems/compareitems/" + btoa(JSON.stringify(dataout))).subscribe(data => {
      //   console.log(data);
      this.Showspinner = false;
      if (data == null) {

        return;
      }

      if (data.toString().length > 0) {

        this.listitems = data;
        //   console.log(this.listitems);
        for (var i = 0; i < this.listitems.length; i += 1) {
          this.listitems[i]["BADGE"] = this.api.getbadge(this.listitems[i]["UPC"]);
        }

        this.api.iniIndex = this.listitems[this.listitems.length - 1].ID;
        this.isreading = false;

        if (data.toString().length < 159) {
          this.endlist = true;

          // this.api.presentToast("No more records...", "", "bottom");
          this.isreading = false;
          this.Showspinner = false;

        }
      }
      else {

        this.endlist = true;
        this.api.presentToast("No more records...", "", 2000);
        this.isreading = false;
        this.Showspinner = false;

      }
    }, error => {
      console.log("error");
      this.isreading = false;
      this.Showspinner = false;

    });

  }
}

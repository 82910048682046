import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-single-product',
  templateUrl: './single-product.component.html',
  styleUrls: ['./single-product.component.css']
})
export class SingleProductComponent implements OnInit {
private UPC:string="";
  constructor(private activatedRoute: ActivatedRoute,) {
    this.activatedRoute.params.subscribe(params => {
      this.UPC = params['ID'];
    console.log(this.UPC);
      });
   }

  ngOnInit(): void {
  }

}

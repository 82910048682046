import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiProvider } from '../Services/api';
import { ResetPassComponent } from '../component/reset-pass/reset-pass.component';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(public api: ApiProvider, private router: Router, public errorStateMatcher: ErrorStateMatcher,
    private datePipe: DatePipe, public dialog: MatDialog) { }
  EMAILPATTERN = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
  matcher = new MyErrorStateMatcher();
  public signupform!: FormGroup;
  public starlog: boolean = false;
  ngOnInit(): void {
    this.api.getStore().then((data:any)=>{
      this.api.compheader = data[0];
    })
    this.api.userprofile = null;
    // this.api.Getconfig();
    this.setForm();

  };
  ngAfterViewInit(): void {

    this.api.istitles = false;


  }
  setcookie(uid: string) {
    var expired = new Date();
    var name = this.datePipe.transform(expired, "MM/dd/yyyy h:mm:ss a");

  }
  equalto(field_name: string): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } => {
      let input = control.value;
      let isValid = control.root.value[field_name] == input;
      if (!isValid)
        return { "equalTo": { isValid } }
      else
        return {};
    };
  }



  setForm() {


    this.signupform = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(this.EMAILPATTERN)]),
      password: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(12)]),
    })
    // ============== T E S T I N G ====================
    if (this.api.istest) {
      this.signupform.controls['email'].setValue("eba@rbrsupport.net");
      this.signupform.controls['password'].setValue("123");
    }
    //==================================================
  }

  login() {
    this.starlog = true;
    //  this.router.navigateByUrl("/home");

    //  console.log(this.signupform.get('email')?.value.toLowerCase());
    if (this.api.endpoint.length > 0) {
      var dat;
      if (this.api.userprofile != null) {
        dat = {
          "EMAIL": this.api.userprofile.email.toLowerCase(),
          "PASSWORD": this.signupform.get('password')?.value,
          "NAME": this.api.userprofile.name,
          "FCMID": "",
          "LANG": this.api.userprofile.language,
          "PICTURE": this.api.userprofile.picture,
          "ID": this.api.userprofile.ID
        }
      }
      else {
        dat = {
          "EMAIL": this.signupform.get('email')?.value.toLowerCase(),
          "PASSWORD": this.signupform.get('password')?.value,
          "LANG": "1",
          "NAME": "",
          "PICTURE": "",
          "FCMID": "",
          "ID": ""
        }
      };
     // console.log(dat);

      var dataout = {
        ID: "1",
        DATAFORM: btoa(JSON.stringify(dat))
      }
      //    console.log(this.api.endpoint);
      this.api.post(this.api.endpoint + '/login', JSON.stringify(dataout)).subscribe(async data => {
        //  console.log(data);
        if (data.toString().length > 10) {
          var data1: any = data;
          if (data1.StatusCode == 200) {
            if (data1.ERROR == '1') {
              this.Resetpass(data1.UID);
            }
            else {

              var prof = JSON.parse(atob(data1.MESSAGE));

              //    console.log(prof);
              this.api.userprofile = prof;
              this.starlog = false;
              this.api.userprofile.login = true;

              this.setcookie(this.api.userprofile.ID);
              this.router.navigateByUrl("/mainpage");
            }
          }
          else {
            this.starlog = false;
            this.api.presentToast(data1.MESSAGE, "", 2000);
          }
        }
        else {
          this.starlog = false;
          this.api.presentToast("Invalid credentials", "", 2000);
          this.api.userprofile.Username = "";
        }
      }, error => {
        this.starlog = false;
        this.api.presentToast("Server is not listening!!" + this.api.endpoint, "", 2000);
        this.api.userprofile.Username = "";
      });


    }

  }
  Resetpass(id: string) {
    const dialogRef = this.dialog.open(ResetPassComponent, {
      width: '80%',
      data: {
        "ID": id
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result.length > 0) {
        var dat = {
          "EMAIL": this.signupform.get('email')?.value.toLowerCase(),
          "PASSWORD": result,
          "NAME": "",
          "LANG": "",
          "PICTURE": "",
          "ID": id,
        }

        var dataout = {
          ID: "2",
          DATAFORM: btoa(JSON.stringify(dat))
        }

        this.api.post(this.api.endpoint + '/login', JSON.stringify(dataout)).subscribe(async data => {

          if (data.toString().length > 10) {
            var data1: any = data;
            this.starlog = false;
            if (data1.StatusCode == 200) {
              this.api.presentToast(data1.MESSAGE, "", 2000);
            }
            else {
              this.api.presentToast(data1.MESSAGE, "", 2000);
            }
          }
        }, error => {

          this.api.presentToast("Server is not listening!!" + this.api.endpoint, "", 2000);
          this.api.userprofile.Username = "";
        });

      }
      else {
        this.starlog = false;
      }

    });
  }
}
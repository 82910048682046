import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ShopGridComponent } from './shop-grid/shop-grid.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { AccountPageComponent } from './account-page/account-page.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { SingleProductComponent } from './single-product/single-product.component';
import { LoginComponent } from './login/login.component';
import { ProductlistComponent } from './productlist/productlist.component';
import { MainpageComponent } from './mainpage/mainpage.component';
import { LegalComponent } from './component/legal/legal.component';
import { PrivacyComponent } from './component/privacy/privacy.component';
import { CustordersComponent } from './component/custorders/custorders.component';
import { PrintReportComponent } from './component/print-report/print-report.component';
import { AmplPhotoComponent } from './ampl-photo/ampl-photo.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'products', component: ProductlistComponent },
  { path: 'checkout', component: CheckoutComponent },
  { path: 'shop_grid/:ID', component: ShopGridComponent},
  { path: 'ShoppingCart', component: ShoppingCartComponent },
  { path: 'AccountPage', component: AccountPageComponent },
  { path: 'Dashboard', component: DashboardComponent },
  { path: 'Wishlist', component: WishlistComponent },
  { path: 'SingleProduct/:ID', component: SingleProductComponent },
  { path: 'mainpage', component: MainpageComponent },
  { path: 'legalpage', component: LegalComponent },
  { path: 'privacypage', component: PrivacyComponent },
  { path: 'printreport/:DATA', component: PrintReportComponent },
  { path: 'ampl-photo', component: AmplPhotoComponent },
  { path: 'orders', component: CustordersComponent },
  { path: '',   redirectTo: '/home', pathMatch: 'full' }, // redirect to `first-component`
  { path: '**', component: HomeComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

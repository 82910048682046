<div style="background-color: rgb(243, 116, 32); margin-left: -20px;margin-top: -20px; width: 490px;padding-left: 3%;">
    <h3 md-dialog-title >{{title}}</h3>
    
  </div>
  <br>
  <div md-dialog-content>{{confirmMessage}}</div>
  <br>
  <br>
  <div md-dialog-actions style="text-align: center;">
    <button mat-raised-button color="warn" (click)="dialogRef.close(true)">{{this.butt1}}</button>
    <button *ngIf="this.butt2.length>0" mat-raised-button color="light" style="margin-left:3%"
       (click)="dialogRef.close(false)">{{this.butt2}}</button>
  </div>
  

<div class="breadcrumb mb-0 p-0">
    <div class="row">
        <div class="col-sm-11">
            <h2> <a>
                    <mat-icon>view_compact</mat-icon>
                </a>Store Catalog</h2>
        </div>
        <div class="col-sm-1">
            <a href="javascript:;" (click)="api.CloseW()"> <img src="assets/images/Close.png" style="width: 40px; "></a>
        </div>
    </div>
</div>
<!--end Header-->
<div class="panel-box" style="height:auto;width: 90%;margin-left: 20px;background-color: rgb(224, 223, 223);">
    <div class="row" style="background-color: rgb(94, 30, 69);color:white">
        <div class="col-sm-6">
            <div class="row">
                <div class="col-sm-9">
                    <h2>Department Name</h2>
                </div>
                <div class="col-sm-3">
                    <button style="background-color: rgb(250, 187, 15);color: black;margin-top: 5px;" mat-button
                        (click)="Adddepart()">
                        <mat-icon>note_add</mat-icon>Add
                    </button>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="row">
                <div class="col-sm-9">
                    <h2>Sub-Department</h2>
                </div>
                <div class="col-sm-3">
                    <button style="background-color: rgb(70, 14, 40);color: rgb(255, 255, 255);margin-top: 5px;"
                        mat-button (click)="Addsubdepart()">
                        <mat-icon>note_add</mat-icon>Add
                    </button>
                </div>
            </div>

        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-sm-6">
            <div class="panel">
                <mat-selection-list [multiple]="false">
                    <mat-list-option *ngFor="let dept of api.departments" [value]="dept.DESCRIPTION"
                        (click)="selectdept(dept.ID)">
                        <div class="row">
                            <div class="col-sm-10">
                                {{dept.DESCRIPTION}}
                            </div>
                            <div class="col-sm-2">
                                <button mat-button color="primary" (click)="edititem(dept)">
                                    <mat-icon>create</mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-list-option>
                </mat-selection-list>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="panel1">
                <mat-selection-list [multiple]="false">
                    <mat-list-option *ngFor="let sdept of subdepart" [value]="sdept.DESCRIPTION">
                        <div class="row">
                            <div class="col-sm-10">
                                {{sdept.DESCRIPTION}}
                            </div>
                            <div class="col-sm-2">
                                <button mat-button color="primary" (click)="edititemsub(sdept)">
                                    <mat-icon>create</mat-icon>
                                </button>

                            </div>
                        </div>
                    </mat-list-option>
                </mat-selection-list>
            </div>
        </div>
    </div>


</div>

<ng-template #dialogRef let-mydata>
    <h2>Name:</h2>
    <div style=" display: flex;">
        <input #ref1 class="input form-control" type="text" style="width:'300px';" />
        <button mat-button mat-dialog-close="{{ ref1.value | titlecase }}"
            style=" background-color: rgb(250, 187, 15);color: black;">Add</button>
    </div>
</ng-template>

<ng-template #EditDLG let-data>
    <input [(ngModel)]="data.DESCRIPTION" class="input form-control" type="text" style="width:'300px';" />
    <div style=" display: flex;">
        <mat-form-field appearance="fill">
            <mat-label>Family</mat-label>
            <mat-select [(ngModel)]="data.MAIN">
                <mat-option style="font-size: 12px;" *ngFor="let resul of api.Family " [value]="resul.NAME">
                    {{resul.NAME}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-button mat-dialog-close="{{ data.MAIN }}|{{data.DESCRIPTION | titlecase }}"
            style=" background-color: rgb(250, 187, 15);color: black;height: 40px;margin-left: 20px;">Save</button>
        <button mat-button mat-dialog-close="~Delete"
            style=" background-color: rgb(250, 15, 15);color: rgb(241, 240, 240);height: 40px;margin-left: 20px;">Delete</button>
    </div>
</ng-template>

<ng-template #ADDDLG let-data>

    <mat-form-field appearance="fill">
        <mat-label>Family</mat-label>
        <mat-select [(ngModel)]="data.DEP">
            <mat-option style="font-size: 12px;" *ngFor="let resul of api.Family " [value]="resul.NAME">
                {{resul.NAME}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <h2>Name:</h2>
    <div style=" display: flex;">
        <input [(ngModel)]="data.NAME" class="input form-control" type="text" style="width:'300px';" />
    </div>
    <button mat-button mat-dialog-close="{{data.DEP}}|{{data.NAME | titlecase }}"
        style=" background-color: rgb(250, 187, 15);color: black;height: 40px;">Add</button>
</ng-template>

<ng-template #EditSubDLG let-data>
    <input [(ngModel)]="data.DESCRIPTION" class="input form-control" type="text" style="width:'300px';" />
    <div style=" display: flex;">
            <mat-form-field appearance="fill">
                <mat-label>DEPARTMENT</mat-label>
                <mat-select [(ngModel)]="data.MAIN">
                    <mat-option style="font-size: 12px;" *ngFor="let resul of api.departments "
                        [value]="resul.DESCRIPTION">
                        {{resul.DESCRIPTION}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        <button mat-button mat-dialog-close="{{ data.MAIN }}|{{data.DESCRIPTION | titlecase }}"
            style=" background-color: rgb(250, 187, 15);color: black;height: 40px;margin-left: 20px;">Save</button>
        <button mat-button mat-dialog-close="~Delete"
            style=" background-color: rgb(250, 15, 15);color: rgb(241, 240, 240);height: 40px;margin-left: 20px;">Delete</button>
    </div>
</ng-template>
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiProvider } from 'src/app/Services/api';


@Component({
  selector: 'app-addagency',
  templateUrl: './addagency.component.html',
  styleUrls: ['./addagency.component.css']
})
export class AddagencyComponent implements OnInit {
  public agencydata: any = {
    AGENCY: "",
    COUNTRY: "",
    STATE: "",
    CITY: "",
    ZIPCODE: "",
    ADDRESS: "",
    MOBILE: "",
    ZONE: "",
    WEBSITE:""
  };
  public zone: any = [];
  public zonesel: string = "";
  private id:string="*";
  constructor(public api: ApiProvider, private router: Router,
    private datePipe: DatePipe, public dialogRef: MatDialogRef<AddagencyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    if(data.AGENCY.toString().length>0){
      this.agencydata=data.AGENCY;
    }
    this.zone=data.ZONES
    this.id=this.agencydata.ID;
    this.zonesel=this.agencydata.ZONE;
    delete this.agencydata.ID;
    console.log(this.zone);
    if(this.id==undefined) this.id="*";
  }

  ngOnInit(): void {
    if (this.api.userprofile.login == undefined)
      this.router.navigateByUrl("/home");
    else {
      if (!this.api.userprofile.login)
        this.router.navigateByUrl("/home");
      else {

      }
    }

  }




  Updatedata() {
    this.agencydata.ZONE=this.zonesel;
    var dataout = {
      ID: "",
      DATAFORM: btoa(JSON.stringify(this.agencydata))
    }
    this.api.put(this.api.endpoint + '/Agencies/'+ this.id, dataout).subscribe(async data => {
      //  console.log(data);
      var dat1 = data;
      if (dat1.toString().length > 10) {
        var data1: any = data;
        if (data1.StatusCode == 200) {
          this.dialogRef.close("1");
          this.api.presentToast("DATA UPTATED .....", "", 2000);
        }
        else {
          this.api.presentToast(data1.MESSAGE, "", 2000);
        }
      }
      else {


      }


    });

  }

  Close() {
    this.dialogRef.close("0");
  }

  updatezone(item: string) {
    this.zonesel=item;
  }
}

<div class="content1">
    <div class="breadcrumbs">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <ul>
                        <li class="home"> <a routerLink="/home" title="Go to Home Page">Home</a> <span>/</span> </li>
                        <li> <span style="color: black;">My Wish List</span> </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- Breadcrumbs End -->

    <!-- Main Container -->
    <section class="main-container col2-left-layout">
       
        <div class="container" #scrooll>
            <div class="row">
                <div class="col-sm-9 col-sm-push-3">
                    <h2>My Wish List</h2>
                    <article class="col-main">
                     
                        <div class="category-products">
                            <ul class="products-grid">
                                <li class="item col-lg-4 col-md-4 col-sm-4 col-xs-6"
                                    *ngFor="let item of wishlist ;let i=index">
                                    <div class="item-inner">
                                        <div class="item-img">
                                            <div class="item-img-info"> <a class="product-image"
                                                    title="{{item.DESCRIPTION}}"  [routerLink]="['/SingleProduct/',item.UPC]" >
                                                    <img alt="{{item.DESCRIPTION}}" [src]="getImg(item)"
                                                        style="height: 200px; object-fit: contain;"
                                                        (error)="loadDefault($event)">
                                                </a>
                                                <div class="new-label new-top-left" *ngIf="!item.ISNEW">new</div>
                                            </div>
                                        </div>
                                        <div class="item-info">
                                            <div class="info-inner">
                                                <div class="item-title">
                                                    <h5> <a [routerLink]="['/SingleProduct/',item.UPC]">
                                                            <div>
                                                                <mat-icon matBadge="{{item.BADGE}}" *ngIf="item.BADGE>0"
                                                                    matBadgeOverlap="false" matBadgeColor="warn"
                                                                    matBadgeSize="small"
                                                                    style="position:absolute;margin-left: -40px;margin-top: 10px;">
                                                                </mat-icon>{{item.DESCRIPTION}}
                                                            </div>

                                                        </a></h5>
                                                </div>
                                                <div class="item-content">
                                                    <div class="rating">
                                                        <ng-template ngFor let-item
                                                            [ngForOf]="createRange(item.RATING)">
                                                            <span class="material-icons">star</span>
                                                        </ng-template>
                                                        <label></label>
                                                    </div>
                                                    <div class="item-price">
                                                        <div *ngIf="item.SPECIAL_PRICE>0">
                                                            <img src="assets/images/golder_offer2.png"
                                                                style="width:30px;position: absolute;margin-left: -80px;">
                                                            <div class="price-box"><span class="regular-price"
                                                                    style="color:red"
                                                                    *ngIf="item.NP_SPECIAL>1">{{item.NP_SPECIAL}}/</span><span
                                                                    class="regular-price"
                                                                    style="color:red;font-weight:bolder ;">{{item.SPECIAL_PRICE|currency}}
                                                                    <span style="font-weight: normal;"
                                                                        *ngIf="item.WEIGHT=='True'"> Per pound</span>
                                                                </span>
                                                                <span
                                                                    style="text-decoration:line-through;color: gray; margin-left: 10px;"><span
                                                                        *ngIf="item.NP_PRICE>1">{{item.NP_PRICE}}/</span>{{item.PRICE|currency}}
                                                                    <span style="font-weight: normal;"
                                                                        *ngIf="item.WEIGHT=='True'"> Per pound</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="item.SPECIAL_PRICE===0">
                                                            <div class="price-box"> <span class="regular-price1">
                                                                    <span
                                                                        class="price">{{item.PRICE|currency:'USD'}}</span>
                                                                    <span style="font-weight: normal;"
                                                                        *ngIf="item.WEIGHT=='True'"> Per pound</span>
                                                                </span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="action">
                                                        <button class="button btn-cart" type="button" title=""
                                                            data-original-title="Add to Cart"
                                                            (click)="selectItem(i)"><span>Add to Cart</span>
                                                        </button>
                                                        <button type="submit" title="Submit"
                                                        class="button button-clear"  (click)="Delete(i)"><span>Delete</span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    
                    </article>
                    <!--	///*///======    End article  ========= //*/// -->
                </div>
                <div class="sidebar col-sm-3 col-xs-12 col-sm-pull-9">
                    <aside class="sidebar">
                           <div class="block block-cart">
                            <div class="block-title ">
                                <h3>My Cart</h3>
                            </div>
                            <div class="block-content">
                                <div class="summary">
                                    <p class="amount">There are <a routerLink="/ShoppingCart">{{api.productCount}}
                                            items</a>
                                        in your cart.</p>
                                    <p class="subtotal"> <span class="label">Cart Subtotal:</span> <span class="price">
                                            {{api.purchaseamount|currency}}</span> </p>
                                </div>
                                <div class="ajax-checkout">
                                    <button class="button button-checkout" title="Submit"
                                        type="submit"><span>Checkout</span></button>
                                </div>
                                <p class="block-subtitle">Recently added item(s) </p>
                                <ul>
                                    <li class="item" *ngFor="let order of api.orders">
                                        <a routerLink="/ShoppingCart" title="{{order.DESCRIPTION}}"
                                            class="product-image"><img [src]="api.getImgContent(order.UPC)"
                                                alt="{{order.DESCRIPTION}}"></a>
                                        <div class="product-details">
                                            <div class="access"> <a routerLink="/ShoppingCart" title="Remove This Item"
                                                    class="jtv-btn-remove"> <span class="icon"></span> Remove </a>
                                            </div>
                                            <strong>1</strong> x <span class="price">{{order.PRICE|currency}}</span>
                                            <p class="product-name"> <a
                                                    routerLink="/ShoppingCart">{{order.DESCRIPTION}}</a>
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                     </aside>
                </div>
            </div>
        </div>
    </section>
</div>

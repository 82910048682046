<!-- Header-->
<div class="breadcrumb mb-0 p-0">
    <div class="row">
        <div class="col-sm-11">
            <h2> <a>
                    <mat-icon>store</mat-icon>
                </a>Rewards Products</h2>
        </div>
        <div class="col-sm-1">
            <a href="javascript:;" (click)="api.CloseW()"> <img src="assets/images/Close.png" style="width: 40px; "></a>
        </div>
    </div>
</div>
<div class="panel-box" style="height:auto;width: 96%;">
    <div class="card">
        <div class="card-body">
            <div style="height: 400px;background-color: rgb(228, 228, 228);">
                <div class="table-responsive">
                    <div class="loader" *ngIf="Showspinner"></div>
                    <table class="table table-striped" style="background-color:white;font-size: 12px;">
                        <thead>
                            <tr style="background-color:rgb(37, 61, 88);color: darkorange;">
                                <th scope="col">UPC</th>
                                <th scope="col">DESCRIPTION</th>
                                <th scope="col">Type</th>
                                <th scope="col">Points</th>
                                <th scope="col">Edit/Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of Items | paginate: config">
                                <th scope="row">{{item.UPC}}</th>
                                <td> {{item.DESCRIPTION}}</td>
                                <td style="text-align:center;">{{item.TYPE}}</td>
                                <td style="text-align:center;"> {{item.AMOUNTAC}}</td>
                                <td>
                                    <a href="javascript:'" style="font-size: 20px;" (click)="edit(item)">
                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                    </a>
                                    <a href="javascript:'" style="font-size: 20px;margin-left: 20px;"
                                        (click)="delete(item.ID)">
                                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                </div>
            </div>
            <div style="margin-top:20px ;height: 40px;margin-left: 30px;">
                <button mat-flat-button color="accent" (click)="AddProduct()">
                    <mat-icon> person_pin</mat-icon>Add Product
                </button>
            </div>
            <br>
        </div>
    </div>
</div>
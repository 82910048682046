    <div class="ms-content-wrapper">
      <div class="row">

        <div class="col-md-12">
          <div class="col-12">
            <div class="ms-panel">
              <div class="ms-panel-header">
                <h1 style="font-weight: bold;"> Order List</h1>
              </div>
              <div class="ms-panel-body">

                <div class="table-responsive">
                  <table class="table table-hover thead-primary">
                    <thead>
                      <tr>
                        <th scope="col">Order ID</th>
                        <th scope="col">Merchant</th>
                        <th scope="col">Instructions</th>
                          <th scope="col">Order Status</th>
                            <th scope="col">Order Time</th>
                            <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr  *ngFor="let items of orders | paginate: config">
                        <th scope="row">{{items.IDTR}}</th>
                        <td>{{items.COMPANY_NAME}}</td>
                          <td> {{items.SPECIALINSTR}}</td>
                        <td><span [ngClass]="setclass(items.STATUSPROCC)" >{{items.STATUSPROCC}}</span></td>
                          <td>{{items.DATETR|date:'MM-dd-yy'}} - {{items.TIME}}</td>
                          <td>{{items.TOTAL|currency:'USD'}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                </div>
              </div>
            </div>
          </div>


        </div>

      </div>
    </div>


 


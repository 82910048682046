<div style="background-color: rgb(0, 0, 0);;padding-left: 4%;">
  <h3 style="color:rgb(243, 155, 23)">Account Security Level</h3>
</div>
<br>

<mat-card class="info">
  <mat-card-content style="margin-top: 5px;">
    <br>
    <div class="form-row">
      <div class="col-md-5 mb-3">
        <mat-icon>person</mat-icon>
        <mat-form-field>
          <input matInput type="text" placeholder="Name" [(ngModel)]="this.name">
        </mat-form-field>
      </div>
      <div class="col-md-4 mb-3">
        <mat-form-field>
          <input matInput type="email" placeholder="Email" [(ngModel)]="this.emailadd">
        </mat-form-field>
      </div>
      <div class="col-md-3 mb-3">
        <section class="sectionLS">
          <mat-checkbox class="Smargin" [(ngModel)]="isadm">Admin</mat-checkbox>
        </section>
        <section class="sectionL">
          <mat-checkbox class="Smargin" [(ngModel)]="isdisabled">Disable</mat-checkbox>
        </section>
        <section class="sectionL">
          <mat-checkbox class="Smargin" [(ngModel)]="resetp">Reset Password</mat-checkbox>
        </section>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<div class="form-row">
  <div class="col-md-6 mb-3">
    <mat-card class="details">
      <mat-card-header>
        <div class="row">
          <div class="col-lg-5">
            <label style="font-weight: bolder;font-size: 16px;">Services</label>
          </div>
        </div>
      </mat-card-header>
      <mat-card-content style="padding-left: 10%;margin-top: 5px;">
        <section class="sectionL">
          <span>
            <mat-checkbox class="Smargin" [checked]="allServices" [color]="task.color" [indeterminate]="someComplete(0)"
              (change)="setAll($event.checked,0)">
              {{task.name}}
            </mat-checkbox>
          </span>
          <span>
            <ul>
              <li *ngFor="let subtask of task.subtasks">
                <mat-checkbox [(ngModel)]="subtask.completed" [color]="subtask.color"
                  (ngModelChange)="updateAllComplete(0)">
                  {{subtask.name}}
                </mat-checkbox>
              </li>
            </ul>
          </span>
        </section>

      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-md-6 mb-3">
    <mat-card class="details1">
      <mat-card-header>
        <div class="row">
          <div class="col-lg-5">
            <label style="font-weight: bolder;font-size: 16px;">Reports</label>
          </div>
        </div>
      </mat-card-header>
      <mat-card-content style="padding-left: 10%;margin-top: 5px;">
        <section class="sectionL">
          <span>
            <mat-checkbox class="Smargin" [checked]="allReports" [color]="task.color" [indeterminate]="someComplete(1)"
              (change)="setAll($event.checked,1)">
              {{task.name}}
            </mat-checkbox>
          </span>
          <span>
            <ul>
              <li *ngFor="let subtask1 of taskReports.subtasks">
                <mat-checkbox [(ngModel)]="subtask1.completed" [color]="subtask1.color"
                  (ngModelChange)="updateAllComplete(1)">
                  {{subtask1.name}}
                </mat-checkbox>
              </li>
            </ul>
          </span>
        </section>
      </mat-card-content>
    </mat-card>
    <br>
    <br>
    <div style="text-align: center;">
      <button mat-raised-button color="accent" style="margin-left: 20px;" (click)="Updatedata()">
        <mat-icon>check</mat-icon>{{Title}}
      </button>
      <button mat-raised-button color="light" style="margin-left: 20px;" (click)="Close()">
        <mat-icon>close</mat-icon>Cerrar
      </button>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CustcreateComponent } from '../component/custcreate/custcreate.component';
import { ApiProvider } from '../Services/api';

@Component({
  selector: 'app-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.css']
})
export class AccountPageComponent implements OnInit {
  public email: string = "";
  public password: string = "";
  constructor(public api: ApiProvider, private router: Router, public dialog: MatDialog) { }

  ngOnInit(): void {
  }
  login() {

    if (this.api.userWEBprofile.language == undefined) this.api.userWEBprofile.language = "1";
    if (this.api.userWEBprofile.name == undefined) this.api.userWEBprofile.name = "";

    if (this.api.endpoint.length > 0) {
      var dat;
      if (this.api.userWEBprofile != null) {
        dat = {
          "EMAIL": this.email.toLowerCase(),
          "PASSWORD": this.password,
          "NAME": this.api.userWEBprofile.name,
          "FCMID": "",
          "LANG": this.api.userWEBprofile.language,
          "PICTURE": this.api.userWEBprofile.picture
        }
      }
      else {
        dat = {
          "EMAIL": this.email.toLowerCase(),
          "PASSWORD": this.password,
          "LANG": "1",
          "NAME": "",
          "PICTURE": "",
          "FCMID": ""
        }
      };
      //   console.log(dat);

      var dataout = {
        ID: "2",
        DATAFORM: btoa(JSON.stringify(dat))
      }

      this.api.post(this.api.endpoint + '/loginapp', JSON.stringify(dataout)).subscribe(async data => {
        //     console.log(data);
        if (data.toString().length > 10) {
          var data1: any = data;
          if (data1.StatusCode == 200) {
            if (data1.ERROR == '1') {
              this.api.presentToast("Invalid Username", "", 2000);
            }
            else {
              var prof = JSON.parse(atob(data1.MESSAGE));
              this.api.userWEBprofile = prof;
              console.log(this.api.userWEBprofile);
              this.api.userWEBprofile.SHIPPING = JSON.parse(prof.SHIPPING);
              this.api.userWEBprofile.BILLING = JSON.parse(prof.BILLING);
              this.api.userWEBprofile.login = true;
              var name = this.api.userWEBprofile.NAME.split(' ');
              this.api.UpdateProfile();
              this.router.navigateByUrl("/home");
            }
          }
          else {
            this.api.presentToast(data1.MESSAGE, "", 2000);
          }
        }
        else {
          this.api.presentToast("Invalid credentials", "", 2000);
          this.api.userWEBprofile.Username = "";
        }
      }, error => {
        this.api.presentToast("Server is not listening!!" + this.api.endpoint, "", 2000);
        this.api.userWEBprofile.Username = "";
      });


    }

  }
  createaccount() {
    const dialogRef = this.dialog.open(CustcreateComponent, {
      width: '90%'
    });
    dialogRef.afterClosed().subscribe(result => {
    
      if(result!==undefined){
        this.email=result;
      }

    });
  }
}

<div style="background-color: rgb(0, 0, 0);;padding-left: 4%;width: 800px;">
  <h3 style="color:rgb(243, 155, 23)">Items</h3>
</div>
<br>

<mat-card class="info">
  <mat-card-content style="margin-top: 5px;">
    <div class="form-row" style="width: 780px;">
      <div class="col-sm-6">
        
        <div *ngIf="this.productdata.ISNEW">
         
          <input class="form-control" #idupc  type="text" placeholder="UPC"
            [(ngModel)]="this.productdata.UPC" (input)="handleInput($event)" (keypress)="handkey($event)" >
          </div>
        <div *ngIf="!this.productdata.ISNEW">
         
          <label class="upclabel">{{this.productdata.UPC}}</label>
        </div>
        <div class="search" *ngIf="isShow">
          <mat-selection-list class="search" [multiple]="false">
            <mat-list-option style="font-size: 12px;" *ngFor="let resul of results " (click)="Selected(resul.ID)">
              {{resul.DT}}
            </mat-list-option>
          </mat-selection-list>

        </div>
      </div>
      <div class="col-sm-6">
          <input class="form-control" #descript (focus)="checkupc()" style="width: 350px;" type="text" placeholder="Description"
            [(ngModel)]="this.productdata.DESCRIPTION">
      </div>
    </div>
    <div class="form-row" style="width: 780px;">
      <div class="col-sm-6">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Department</mat-label>
          <mat-select [(ngModel)]="this.depsel" (selectionChange)="updatedep()" >
            <mat-option *ngFor="let ct of deparments" [value]="ct.ID" >
              {{ct.DESCRIPTION | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Sub-Deparments</mat-label>
          <mat-select [(ngModel)]="this.subdepsel">
            <mat-option *ngFor="let ct1 of subdeparments" [value]="ct1.SID" >
              {{ct1.DESCRIPTION | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>  
    <div class="form-row" style="width: 780px;">
      <div class="col-sm-4" style="margin-top: 10px;">
        <mat-label class="label">Price</mat-label>
        <div class="container1">
          <input class="form-control" (focus)="checkupc()" style="width: 40px;" type="text" placeholder="#"
            [(ngModel)]="this.productdata.NP_PRICE">
          <input class="form-control" #price (focus)="checkupc()" style="width: 100px;" type="text" placeholder="Price"
            [(ngModel)]="this.productdata.PRICE">
        </div>
      </div>
      <div class="col-sm-4" style="margin-top: 10px;">
        <mat-label class="label">SIZE</mat-label>
        <div class="container1">

          <input class="form-control" type="text" style="width: 70px;" placeholder="Size"
            [(ngModel)]="this.productdata.SIZE_IT ">
          <input class="form-control" type="text" style="width: 100px;" placeholder="Unit"
            [(ngModel)]="this.productdata.CASE_SIZE">
        </div>
      </div>
      <div class="col-sm-4" >
        <div
          style="position: relative; height: 200px;width: 150px; border-color: rgb(194, 192, 192);
          border-width: thin; border-style: solid;margin-top:30px;margin-left:60px;" >
          <img id="imgprod" style="height: 100%; width: 100%; object-fit: contain;" src="{{image}}"
            (error)="loadDefault($event)" >
          <button mat-flat-button (click)="fileUpload.click()" style="margin-top:5%;width:70px;background-color:gainsboro;font-size: 10px;">
            <mat-icon> attach_file</mat-icon>File
          </button>
          <button mat-flat-button (click)="paste()" style="margin-top:5%;margin-left: 5px;width:70px;background-color:rgb(231, 169, 53);font-size: 10px;">
            <mat-icon> file_copy</mat-icon>Paste
          </button>
          <div hidden>
            <input type="file" class="file-input" [accept]="requiredFileType" (change)="onFileSelected($event)"
              #fileUpload>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row example-section">
      <div class="col-sm-6" style="margin-left: 15px; background-color:rgb(151, 117, 137);color:white;margin-top: 50px;">
        <mat-checkbox class="example-margin" [(ngModel)]="this.productdata.COUNTED">COUNTED</mat-checkbox>
        <mat-checkbox class="example-margin" [(ngModel)]="this.productdata.EBT">EBT</mat-checkbox>
        <mat-checkbox class="example-margin" [(ngModel)]="this.productdata.WEIGHT">WEIGHT</mat-checkbox>
        <mat-checkbox class="example-margin" [(ngModel)]="this.productdata.WIC">WIC</mat-checkbox>
        <mat-checkbox class="example-margin" [(ngModel)]="this.productdata.FEATURED">FEATURED</mat-checkbox>
      </div>
      <div class="col-sm-5">
        <mat-form-field style="width:100px;margin-top: 50px;" appearance="outline">
          <mat-label>Tax</mat-label>
          <mat-select [(value)]="this.productdata.TAX">
            <mat-option [value]=0>0</mat-option>
            <mat-option [value]=1>1</mat-option>
            <mat-option [value]=2>2</mat-option>
            <mat-option [value]=3>3</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
     
    </div>
    <div class="form-row" style="margin-top:230px;position: absolute;">
      <div class="col-sm-4" style="margin-top: 40px;">
        <mat-label class="label">Special Prices</mat-label>
        <div class="container1">
          <input class="form-control" style="width: 40px;" type="text" placeholder="#"
            [(ngModel)]="this.productdata.NP_SPECIAL">
          <input class="form-control" style="width: 80px;" type="text" placeholder="Price"
            [(ngModel)]="this.productdata.SPECIAL_PRICE">
        </div>
      </div>
      <div class="col-sm-8" style="margin-top: 40px;">
        <mat-label class="label">Date: From - To</mat-label>
        <div class="container2">
          <input class="form-control" type="date" style="width: 150px;" [(ngModel)]="this.productdata.STARTDATE ">
          <input class="form-control" type="date" style="width: 150px;" [(ngModel)]="this.productdata.ENDDATE">
        </div>
      </div>
    </div>
    <div class="form-row" style="margin-top:330px;position: absolute;">
      <div class="col-sm-4" style="margin-top: 40px;">
        <mat-label class="label">Max Items</mat-label>
          <input class="form-control"  type="number" 
            [(ngModel)]="this.productdata.MAX_ITEMS">
      </div>
      <div class="col-sm-4" style="margin-top: 40px;">
        <mat-label class="label">Min Purchase</mat-label>
          <input class="form-control" type="currency" style="width: 150px;" [(ngModel)]="this.productdata.MIN_PURCH">
      </div>
      <div class="col-sm-4" style="margin-top: 40px;">
        <mat-label class="label">Inventory</mat-label>
        <input class="form-control" type="number" style="width: 150px;" placeholder="Size"
          [(ngModel)]="this.productdata.INVENTORY ">
    </div>
    </div>
    <div class="form-row" style="margin-top:410px;position: absolute;">
      <div class="col-sm-6" style="margin-top: 40px;">
        <mat-label class="label">AISLE</mat-label>
          <input class="form-control"  type="text" 
            [(ngModel)]="this.productdata.AISLE">
      </div>
      <div class="col-sm-6" style="margin-top: 40px;">
        <mat-label class="label">LOCATION</mat-label>
          <input class="form-control" type="text" style="width: 150px;" [(ngModel)]="this.productdata.LOCATION">
      </div>
    </div>
  </mat-card-content>
</mat-card>
<br>
<br>
<div >
  <button mat-flat-button (click)="NewUpc()" style="margin-right: 150px">
    <mat-icon>gamepad</mat-icon>New UPC
  </button>
  <button mat-raised-button color="accent" (click)="Updatedata()">
    <mat-icon>check</mat-icon>{{commnad}}
  </button>
  <button mat-raised-button color="light" style="margin-left: 20px;" (click)="Close()">
    <mat-icon>close</mat-icon>Close
  </button>
</div>
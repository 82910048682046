import { Component, OnInit } from '@angular/core';
import { ApiProvider } from '../Services/api';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css']
})
export class ShoppingCartComponent implements OnInit {

  constructor(public api: ApiProvider) { }

  ngOnInit(): void {
    console.log( this.api.orders);
  }
  add(index: any) {
    this.api.orders[index].QTY +=1;
    this.api.UpdateOders();
    this.api.productCount = 0;
    this.api.purchaseamount = 0;
    for (var i = 0; i < this.api.orders.length; i++) {

      this.api.productCount += this.api.orders[i].QTY;
      this.api.purchaseamount += this.api.orders[i].QTY * this.api.orders[i].PRICE;
    }
  }
  minus(index: any) {
      if(this.api.orders[index].QTY==1) return;
    this.api.orders[index].QTY -=1;
    this.api.UpdateOders();
    this.api.productCount = 0;
    this.api.purchaseamount = 0;
    for (var i = 0; i < this.api.orders.length; i++) {

      this.api.productCount += this.api.orders[i].QTY;
      this.api.purchaseamount += this.api.orders[i].QTY * this.api.orders[i].PRICE;
    }

  }

  deleteitem(index: any){
    this.api.orders = this.api.orders.filter((item: any) => item !== index);
    this.api.UpdateOders();
    this.api.productCount = 0;
    this.api.purchaseamount = 0;
    for (var i = 0; i < this.api.orders.length; i++) {

      this.api.productCount += this.api.orders[i].QTY;
      this.api.purchaseamount += this.api.orders[i].QTY * this.api.orders[i].PRICE;
    }

  }
}

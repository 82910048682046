<body class="bg-login">
    <div class="wrap">
        <div class="card radius-15" >
            <div class="card-body" >
                <div class="row">
                    <div class="col-sm-6" >
                        <div style="background-color:#881f66;color:rgb(255, 255, 255);
                        text-align: center;width: 110%; padding: 5px;">
                            <label style="font-size: 12px; font-weight: bolder;">
                                Management Control System (MCS)
                            </label>
                        </div>
                        <br>
                        <div class="text-center">
                            <h1 style="font-weight: bolder;">Welcome Back</h1>
                        </div>
                        <div style="margin-left: 30px; width: 100%;">
                            <form [formGroup]="signupform" #form="ngForm">
                                <div class="form-group">
                                    <label>Email Address</label>
                                    <input type="email" formControlName="email" class="form-control" style="width:90%"
                                        placeholder="Enter your email address" />
                                </div>
                                <div class="form-group">
                                    <label>Password</label>
                                    <input type="password" formControlName="password" class="form-control" style="width:90%"
                                        placeholder="Enter your password" />
                                </div>
                            </form>
                        </div>
                       
                        <div class="loader" *ngIf="starlog"></div>
                        <div style="text-align: center;">
                            <button type="button" class="btn btn-primary "  style="width: 200px;" (click)="login()">Log
                                In</button>
                        </div>
                           
                        <hr>

                        <div style="background-color:#881f66;color:rgb(255, 255, 255);
                        text-align: center;margin-top:73px;width: 110%;">
                            <label style="font-size: 10px; ">
                                RBR-LINK Copyright @2022 Version :<span
                                    style="font-weight: bolder;">({{api.VERSION}})</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div>
                            <img src="assets/images/login-images/login-frent-img.jpg" class="imgclass">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>
<form novalidate (ngSubmit)="post()" [formGroup]="payment_form" #form="ngForm">
  <mat-card class="example-card">
    <mat-card-header>
      <img src="/assets/images/cclogos.jpg" style="width: 150px;">
    </mat-card-header>
    <mat-card-content>
      <label class="label" position="stacked">{{leyends[10]}}Card Number:</label>
      <input type='text' class="input" [(ngModel)]="userData.Account" formControlName="card_number"
        placeholder='Card Number'
        [class.error1]="!payment_form.controls.card_number.valid && payment_form.controls.card_number.dirty">
    <div class="row"> 
     
      <div class="col-sm-6">
        <mat-form-field >
          <mat-label >{{leyends[13]}}Expiration Month:</mat-label>
          <mat-select [(ngModel)]="userData.ExpirationMonth" formControlName="exp_month"
            okText="Okay" cancelText="Dismiss"
            [class.error1]="!payment_form.controls.exp_month.valid && payment_form.controls.exp_month.dirty">
            <mat-option *ngFor="let ln of month" [value]="ln">{{ln}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-sm-6">
        <mat-form-field >
          <mat-label >{{leyends[14]}}Expiration Year:</mat-label>
          <mat-select [(ngModel)]="userData.ExpirationYear" formControlName="exp_year" 
            okText="Okay" cancelText="Dismiss"
            [class.error1]="!payment_form.controls.exp_year.valid && payment_form.controls.exp_year.dirty">
            <mat-option *ngFor="let lex of year" [value]="lex">{{lex}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="loader" *ngIf="loader"></div>
    <div class="row"> 
      <div class="col-sm-6">
        <label class="label">CVV:</label>
      <input  class="input" [(ngModel)]="userData.CVV" formControlName="cvv" type="text"
        [class.error1]="!payment_form.controls.cvv.valid && payment_form.controls.cvv.dirty">
      </div>
      <div class="col-sm-6">
        <label class="label">{{leyends[15]}}Zip Code:</label>
        <input  class="input" type="text" [(ngModel)]="userData.ZIP" formControlName="zip"
          [class.error1]="!payment_form.controls.zip.valid && payment_form.controls.zip.dirty">
  
      </div>
    </div>
     
     
    </mat-card-content>
    <button mat-raised-button color="primary" type="submit" [disabled]="payment_form.invalid"  expand="full" >
      {{leyends[12]}}PAY</button>
  </mat-card>
 

</form>
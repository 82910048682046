import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiProvider } from 'src/app/Services/api';

@Component({
  selector: 'app-custcreate',
  templateUrl: './custcreate.component.html',
  styleUrls: ['./custcreate.component.css']
})
export class CustcreateComponent implements OnInit {
  public checkFormbilling!: FormGroup;
  EMAILPATTERN = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
  Showspinner = false;
  constructor(public api: ApiProvider,public dialogRef: MatDialogRef<CustcreateComponent>) {
    this.createForm();
  }

  ngOnInit(): void {
  }
  createForm() {
    
         this.checkFormbilling = new FormGroup({
           Name: new FormControl('', [Validators.required, Validators.minLength(2)]),
           Lastname: new FormControl('', [Validators.required, Validators.minLength(3)]),
           Company: new FormControl(''),
           Email: new FormControl('', [Validators.required, Validators.pattern(this.EMAILPATTERN)]),
           Address: new FormControl('', [Validators.required]),
           City: new FormControl('', [Validators.required]),
           State: new FormControl('CT', [Validators.required]),
           Zip: new FormControl('', [Validators.required]),
           Country: new FormControl('USA', [Validators.required]),
           Phone: new FormControl('', [Validators.minLength(10)]),
           Fax: new FormControl('', []),
           password: new FormControl('', [Validators.minLength(4)]),
           compassword: new FormControl(''),
         });
         
 /*   this.checkFormbilling = new FormGroup({
      Name: new FormControl('Eduardo', [Validators.required, Validators.minLength(2)]),
      Lastname: new FormControl('Briceno', [Validators.required, Validators.minLength(3)]),
      Company: new FormControl(''),
      Email: new FormControl('eba@rbrsupport.net', [Validators.required, Validators.pattern(this.EMAILPATTERN)]),
      Address: new FormControl('1552 Lincoln', [Validators.required]),
      City: new FormControl('Bay Shore', [Validators.required]),
      State: new FormControl('CT', [Validators.required]),
      Zip: new FormControl('11706', [Validators.required]),
      Country: new FormControl('USA', [Validators.required]),
      Phone: new FormControl('6319432806', [Validators.minLength(10)]),
      Fax: new FormControl('', []),
      password: new FormControl('1234', [Validators.minLength(4)]),
      compassword: new FormControl('1234'),
    });*/

  }
  onPasswordChange() {
    if (this.confirm_password.value == this.passwordin.value) {
      this.confirm_password.setErrors(null);
    } else {
      this.confirm_password.setErrors({ mismatch: true });
    }
  }
  get passwordin(): AbstractControl {
    return this.checkFormbilling.controls['password'];
  }

  get confirm_password(): AbstractControl {
    return this.checkFormbilling.controls['compassword'];
  }
  checkvalidation() {
    if (this.checkFormbilling.status == 'VALID') return false;
    else return true;

  }
  Create() {
    if (this.api.userWEBprofile.language == undefined) this.api.userWEBprofile.language = "1";
    if (this.api.userWEBprofile.name == undefined) this.api.userWEBprofile.name = "";

    if (this.api.endpoint.length > 0) {
      var dat;
      this.Showspinner = true;
      dat = {
        "Email": this.checkFormbilling.controls['Email'].value.toLowerCase(),
        "password": this.checkFormbilling.controls['password'].value,
        "Name": this.checkFormbilling.controls['Name'].value,
        "Lastname": this.checkFormbilling.controls['Lastname'].value,
        "Zip": this.checkFormbilling.controls['Zip'].value,
        "Phone": this.checkFormbilling.controls['Phone'].value,
        "Billing": JSON.stringify(this.checkFormbilling.value)
      }

      var dataout = {
        ID: "4",
        DATAFORM: btoa(JSON.stringify(dat))
      }

      this.api.post(this.api.endpoint + '/loginapp', JSON.stringify(dataout)).subscribe(async data => {
        //  console.log(data);
        this.Showspinner = false;
        if (data.toString().length > 10) {
          var data1: any = data;
          if (data1.StatusCode == '200') {
            if (data1.ERROR == '1') {
              this.api.presentToast("Invalid Username", "", 2000);
            }
            else {
              this.api.userWEBprofile={};
              this.api.userWEBprofile.login=false;
              this.api.presentToast(data1.MESSAGE, "", 2000);
              this.dialogRef.close(this.checkFormbilling.controls['Email'].value.toLowerCase());
            }
          }
          else {
            this.api.presentToast(data1.MESSAGE, "", 2000);
          }
        }
        else {
          this.api.presentToast("Invalid credentials", "", 2000);
          this.api.userWEBprofile.Username = "";
        }
      }, error => {
        this.Showspinner = false;
        this.api.presentToast("Server is not listening!!" + this.api.endpoint, "", 2000);
        this.api.userWEBprofile.Username = "";
      });


    }
  }
}

import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { HomeComponent } from './home/home.component';
import { ApiProvider } from './Services/api';
import { ShopGridComponent } from './shop-grid/shop-grid.component';
import { QtyselectorComponent } from './component/qtyselector/qtyselector.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatBadgeModule} from '@angular/material/badge';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { AccountPageComponent } from './account-page/account-page.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { SingleProductComponent } from './single-product/single-product.component';
import { LoginComponent } from './login/login.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import { ResetPassComponent } from './component/reset-pass/reset-pass.component';
import { ProductlistComponent } from './productlist/productlist.component';
import { MantenanceComponent } from './component/mantenance/mantenance.component';
import { ConfirmDlgComponent } from './component/confirm-dlg/confirm-dlg.component';
import {MatListModule} from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxPaginationModule } from 'ngx-pagination';
import {MatInputModule} from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatStepperModule} from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MainpageComponent } from './mainpage/mainpage.component';
import { IncomingOrderComponent } from './incoming-order/incoming-order.component';
import { OutgoingOrderComponent } from './outgoing-order/outgoing-order.component';
import {MatChipsModule} from '@angular/material/chips';
import { OrderDetailComponent } from './component/order-detail/order-detail.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import { PromotionalComponent } from './promotional/promotional.component';
import { PromotionalItemsComponent } from './component/promotional-items/promotional-items.component';
import {MatMenuModule} from '@angular/material/menu';
import { UsersComponent } from './component/users/users.component';
import { UsersAddComponent } from './component/users-add/users-add.component';
import { AddagencyComponent } from './component/addagency/addagency.component';
import { AgenciesComponent } from './component/agencies/agencies.component';
import { DepartmetsComponent } from './component/departmets/departmets.component';
import { LegalComponent } from './component/legal/legal.component';
import { PrivacyComponent } from './component/privacy/privacy.component';
import { CustordersComponent } from './component/custorders/custorders.component';
import { CustcreateComponent } from './component/custcreate/custcreate.component';
import { PrintReportComponent } from './component/print-report/print-report.component';
import { CreditcardComponent } from './component/creditcard/creditcard.component';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { AmplPhotoComponent } from './ampl-photo/ampl-photo.component';
const config: SocketIoConfig  = { url: 'http://', 
options: {autoConnect:false,reconnection:true,
  reconnectionDelay: 1000,
  reconnectionDelayMax : 1000,
  reconnectionAttempts: Infinity,

    } };
    
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CheckoutComponent,
    ShopGridComponent,
    QtyselectorComponent,
    ShoppingCartComponent,
    AccountPageComponent,
    DashboardComponent,
    WishlistComponent,
    SingleProductComponent,
    LoginComponent,
    ResetPassComponent,
    ProductlistComponent,
    MantenanceComponent,
    ConfirmDlgComponent,
    MainpageComponent,
    IncomingOrderComponent,
    OutgoingOrderComponent,
    OrderDetailComponent,
    PromotionalComponent,
    PromotionalItemsComponent,
    UsersComponent,
    UsersAddComponent,
    AddagencyComponent,
    AgenciesComponent,
    DepartmetsComponent,
    LegalComponent,
    PrivacyComponent,
    CustordersComponent,
    CustcreateComponent,
    PrintReportComponent,
    CreditcardComponent,
    AmplPhotoComponent
  ],
  imports: [
    FormsModule,ReactiveFormsModule,MatListModule,
    BrowserModule,HttpClientModule,MatToolbarModule,
    AppRoutingModule, BrowserAnimationsModule,
    NgxPaginationModule,MatChipsModule,MatBadgeModule,
    MatInputModule,MatStepperModule,MatListModule,
    MatDialogModule, MatFormFieldModule, MatRadioModule,
    MatButtonModule, MatIconModule, MatSelectModule,
    MatSnackBarModule, MatCardModule, MatCheckboxModule,
    MatCheckboxModule, NgxMatSelectSearchModule,MatMenuModule,
    SocketIoModule.forRoot(config)
     
  ],
  providers: [ApiProvider,DatePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }

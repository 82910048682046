<!-- Header-->
<div class="breadcrumb mb-0 p-0">
    <div class="row">
        <div class="col-sm-11">
            <h3> <a>
                <mat-icon> store_mall_directory</mat-icon>
                </a> Branches</h3>
        </div>
        <div class="col-sm-1">
            <a href="javascript:;" (click)="api.CloseW()"> <img src="assets/images/Close.png" style="width: 40px; "></a>
        </div>
    </div>
</div>
<!--end Header-->
<div class="panel-box" style="height:auto;width: 90%;margin-left: 20px;">
    <div class="card">
        <div class="card-body">
            <div>
                <div class="table-responsive">
                    <table class="table table-striped"  style="background-color:white;font-size: 12px;">
                        <thead >
                            <tr style="background-color:rgb(37, 61, 88);color: darkorange;">
                                <th scope="col">ID</th>
                                <th scope="col">Name</th>
                                <th scope="col">Address</th>
                                <th scope="col">Active</th>
                                <th scope="col">Edit/Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of agencies">
                                <th scope="row">{{item.ID}}</th>
                                <td> {{item.AGENCY}}</td>
                                <td>{{item.ADDRESS}}&nbsp;{{item.CITY}}&nbsp;{{item.STATE}},{{item.ZIPCODE}}</td>
                                <td>
                                    <section>
                                        <mat-checkbox class="Smargin" [disabled]="true"
                                            [ngModel]="setflag(item.STATUS)">
                                        </mat-checkbox>
                                    </section>
                                </td>
                                <td>
                                    <a href="javascript:'" style="font-size: 20px;" (click)="edit(item)">
                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                    </a>
                                    <a href="javascript:'" style="font-size: 20px;margin-left: 20px;"
                                        (click)="delete(item.ID)">
                                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
             
            </div>
            <button mat-raised-button style="background-color: rgb(37, 61, 88); color:white;" (click)="Addagency()">
                <mat-icon>person_pin</mat-icon>New Branch
              </button>
        </div>
    </div>
</div>
<body class="cms-index-index cms-home-page">
    <div id="page">
        <nav *ngIf="api.istitles">
            <header>
                <div class="header-container">
                    <div class="header-top">
                        <div class="container">
                            <div class="row">
                                <!-- Header Language -->
                                <div class="col-sm-4">
                                    <div class="welcome-msg">Welcome</div>
                                    <div class="dropdown jtv-language-box"> <a role="button" data-toggle="dropdown"
                                            data-target="#" class="block-language dropdown-toggle" href="#"> <img
                                                src="assets/images/flag-english.jpg" alt="language"> English <span
                                                class="caret"></span> </a>
                                        <ul class="dropdown-menu" role="menu">
                                            <li> <a class="selected" href="#"> <img src="assets/images/flag-english.jpg"
                                                        alt="flag">
                                                    <span>English</span> </a> </li>
                                            <li> <a href="#"> <img src="assets/images/flag-spain.jpg" alt="flag">
                                                    <span>Espanol</span> </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- End Header Language -->

                                    <!-- Header Currency -->
                                    <div class="dropdown jtv-currency-box"> <a role="button" data-toggle="dropdown"
                                            data-target="#" class="block-currency dropdown-toggle" href="#"> USD <span
                                                class="caret"></span></a>
                                        <ul class="dropdown-menu" role="menu">
                                            <li><a href="#"> $ - Dollar </a> </li>

                                        </ul>
                                    </div>
                                    <!-- End Header Currency -->

                                </div>
                                <div class="col-sm-8">
                                    <!-- Header Top Links -->
                                    <div class="jtv-top-links">
                                        <div class="links">
                                            <ul>
                                                <li> <a title="My Account" routerLink="/orders"><span
                                                            class="hidden-xs1">My Account</span></a>
                                                </li>
                                                <li> <a title="Wishlist" routerLink="/Wishlist">Wishlist</a></li>
                                                <li style="width:70px;"> <a title="Checkout"
                                                        routerLink="/checkout"><span
                                                            class="hidden-xs">Checkout</span></a>
                                                    <div matBadge="{{api.productCount}}" matBadgeOverlap="false"
                                                        *ngIf="api.productCount>0" class="demo-section"></div>
                                                </li>
                                                <li style="width:100px;">
                                                    <div class="dropdown block-company-wrapper hidden-xs"> <a
                                                            role="button" data-toggle="dropdown" data-target="#"
                                                            class="block-company dropdown-toggle" href="#"> More <span
                                                                class="caret"></span></a>
                                                        <ul class="dropdown-menu">
                                                            <li><a href="about_us.html"> About Us </a> </li>
                                                            <li><a href="#"> Customer Service </a> </li>
                                                            <li><a routerLink="/login">Support</a> </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                                <li style="width:240px;">
                                                    <div class="dropdown block-company-wrapper hidden-xs"
                                                        *ngIf="api.userWEBprofile.login">
                                                        <a role="button" data-toggle="dropdown" data-target="#"
                                                            class="block-company dropdown-toggle" href="#">
                                                            <mat-icon>account_circle</mat-icon>Hello
                                                            {{this.api.userWEBprofile.NAME}}
                                                            <span class="caret"></span>
                                                        </a>
                                                        <ul class="dropdown-menu">
                                                            <li><a href="javascript;:" (click)="logout()"> LogOut </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div *ngIf="!api.userWEBprofile.login"> <a
                                                            routerLink="/AccountPage"><span class="hidden-xs">Log
                                                                In</span></a></div>

                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <!-- End Header Top Links -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                                <div class="jtv-top-cart-box">
                                    <!-- Top Cart -->
                                    <div class="mini-cart">
                                        <div data-toggle="dropdown" data-hover="dropdown"
                                            class="basket dropdown-toggle"> <a routerLink="/ShoppingCart"> <span
                                                    class="cart_count">{{api.productCount}}</span><span class="price">My
                                                    Bag
                                                    /
                                                    {{api.purchaseamount|currency}}</span> </a> </div>
                                        <div>
                                            <div class="jtv-top-cart-content" *ngIf="api.productCount>0">

                                                <!--block-subtitle-->
                                                <ul class="mini-products-list" id="cart-sidebar">
                                                    <li class="item first" *ngFor="let order of api.orders">
                                                        <div class="item-inner"> <a class="product-image"
                                                                title="{{order.DESCRIPTION}}"
                                                                routerLink="/SingleProduct"><img
                                                                    alt="{{order.DESCRIPTION}}"
                                                                    [src]="api.getImgContent(order.UPC)"> </a>
                                                            <div class="product-details">
                                                                <div class="access"><a class="jtv-btn-remove"
                                                                        title="Remove This Item" href="#">Remove</a>
                                                                    <a class="btn-edit" title="Edit item" href="#"><i
                                                                            class="icon-pencil"></i><span
                                                                            class="hidden">Edit item</span></a>
                                                                </div>
                                                                <p class="product-name"><a
                                                                        href="#">{{order.DESCRIPTION}}</a>
                                                                </p>
                                                                <strong>1</strong> x <span
                                                                    class="price">{{order.PRICE|currency}}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>

                                                <!--actions-->
                                                <div class="actions">
                                                    <button class="btn-checkout" title="Checkout" type="button"
                                                        routerLink="/checkout"><span>Checkout</span> </button>
                                                    <a routerLink="/ShoppingCart" class="view-cart"><span>View
                                                            Cart</span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-4 col-sm-4 col-xs-12 jtv-logo-box">
                                <!-- Header Logo -->
                                <div class="logo">
                                    <a title="eCommerce" routerLink="/home"><img alt="eCommerce"
                                            src="assets/images/logo.png" style="width: 300px;"> </a>
                                </div>
                                <!-- End Header Logo -->
                            </div>
                            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12 hidden-xs">
                                <div class="search-box">
                                    <input type="text" placeholder="Search here..." value="" maxlength="70"
                                        name="search" id="search" [(ngModel)]="searchkey">
                                    <button type="button" class="search-btn-bg" (click)="Search()"><span class="glyphicon glyphicon-search"
                                            style="width: 20px; "></span></button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <!-- end header -->
            <div id="navigator" class="nav-inner">
                <!-- BEGIN NAV -->
                <ul id="nav" class="hidden-xs">
                    <li class="drop-menu"><a href="index.html" class="level-top active"><span>Home</span></a>
                    </li>
                    <li class="mega-menu" *ngFor="let family of api.Family"> <a class="level-top"
                            href="javascript:;"><span>{{family.NAME}}</span></a>
                        <div class="jtv-menu-block-wrapper">
                            <div class="jtv-menu-block-wrapper2">
                                <div class="nav-block jtv-nav-block-center">
                                    <div class="col-1">
                                        <ul class="level0">
                                            <li class="parent item" *ngFor="let listd of api.getDepatgroup(family.GRP)">
                                                <a
                                                    [routerLink]="['/shop_grid/',listd.ID+',*,']"><span>{{listd.DESCRIPTION}}</span></a>
                                                <ul class="level1">
                                                    <li> <a [routerLink]="['/shop_grid/',listd.ID+','+ sublistd.SID+',']"
                                                            *ngFor="let sublistd of api.getsubDepat(listd.ID)"><span>{{sublistd.DESCRIPTION}}</span></a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-2">
                                        <div class="jtv-nav-image1"> <a title="" href="#"><img alt="menu_image"
                                                    src="assets/images/menu-img{{family.GRP}}.jpg"> </a> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

            </div>
        </nav>
        <!-- end nav -->
        <router-outlet></router-outlet>

        <!-- Footer -->
        <footer *ngIf="api.istitles">
            <div class="legal" >
                <br>
                <div class="row">
                    <div class="col-sm-3">
                        <h2 style="font-weight: bolder;">Legal Information:</h2>
                    </div>
                    <div class="col-sm-3">
                        <a style="text-decoration:none;color:#343538;font-weight: bolder;margin-top: 10px;"
                            href="javascript:'" (click)="terms()">Terms of Use</a>
                    </div>
                    <div class="col-sm-3">
                        <a style="text-decoration:none;color:#343538;font-weight: bolder;margin-top: 10px;"
                            href="javascript:'" (click)="policy()">Privacy Policy</a>
                    </div>
                    <div class="col-sm-3">
                        <a style="text-decoration:none;color:#343538;font-weight: bolder;margin-top: 10px;"
                            href="javascript:'" (click)="iarc()"><img src="assets/images/IARC.png" style="width:100px"
                                alt="IARC"></a>
                    </div>

                </div>
            </div>
            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <div style="text-align:center"> <a routerLink="/home"><img src="assets/images/logo.png"
                                    style="width: 200px;" alt="logo"> </a> </div>
                        <address>
                            <p style="color:#ffff"> <i class="fa fa-map-marker"></i> 155 Thomaston Ave, Waterbury, CT
                                06702, USA </p>
                            <p style="color:#ffff"><i class="fa fa-mobile"></i><span>+ (203) 754 5700</span> </p>
                            <p style="color:#ffff"> <i class="fa fa-envelope"></i><span><a
                                        href="mailto:email@domain.com">support@cherryvalley.com</a></span>
                            </p>
                        </address>
                    </div>
                    <div class="apps">
                        <h1 mat-dialog-title>Download App</h1>
                        <img src="assets/images/applogo.png" style="width: 500px;">
                        <div class="row" style="height: 180px ;margin-left: 20px;" >
                            <div class="col-sm-6">
                                <img src="assets/images/GoogleApp.png" style="height: 180px ;">
                            </div>
                            <div class="col-sm-6">
                                <img src="assets/images/IphoneApp.png" style="height: 180px ;">
                            </div>
                        </div>
                        <!--
                        <div *ngIf="api.Ismobile">
                                <img src="assets/Images/GoogleApp.png" style="height: 180px ;margin-left: 5%;">
                                  <img src="assets/Images/IphoneApp.png" style="height: 180px ;margin-left: 55%;margin-top: -40%;">
                        </div>
                -->
                    </div>
                    <br>
                    <br>
                </div>
            </div>
            <div class="footer-inner">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-4 col-xs-12 col-md-3">
                            <div class="footer-links">
                                <h5>Useful links</h5>
                                <ul class="links">
                                    <li><a href="#" title="Product Recall">Product Recall</a></li>
                                    <li><a href="#" title="Gift Vouchers">Gift Vouchers</a></li>
                                    <li><a href="#" title="Returns &amp; Exchange">Returns &amp; Exchange</a></li>
                                    <li><a href="#" title="Shipping Options">Shipping Options</a></li>
                                    <li><a href="#" title="Help &amp; FAQs">Help &amp; FAQs</a></li>
                                    <li><a href="#" title="Order history">Order history</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-sm-4 col-xs-12 col-md-3">
                            <div class="footer-links">
                                <h5>Service</h5>
                                <ul class="links">
                                    <li><a routerLink="/AccountPage">Account</a></li>
                                    <li><a routerLink="/Wishlist">Wishlist</a></li>
                                    <li><a routerLink="/ShoppingCart">Shopping Cart</a></li>
                                    <li><a href="#">Return Policy</a></li>
                                    <li><a href="#">Special</a></li>
                                    <li><a href="#">Lookbook</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-sm-4 col-xs-12 col-md-2">
                            <div class="footer-links">
                                <h5>Information</h5>
                                <ul class="links">
                                    <li><a href="sitemap.html">Sites Map </a></li>
                                    <li><a href="#">News</a></li>
                                    <li><a href="#">Trends</a></li>
                                    <li><a href="about_us.html">About Us</a></li>
                                    <li><a href="contact_us.html">Contact Us</a></li>
                                    <li><a href="#">My Orders</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-4">
                            <div class="footer-links">
                                <div class="footer-newsletter">
                                    <h5>Subscribe to our news</h5>
                                    <form id="newsletter-validate-detail" method="post" action="#">
                                        <div class="newsletter-inner">
                                            <p>Subscribe to be the first to know about Sales, Events, and Exclusive
                                                Offers!</p>
                                            <input class="newsletter-email" name='Email'
                                                placeholder='Enter Your Email' />
                                            <button class="button subscribe" type="submit"
                                                title="Subscribe">Subscribe</button>
                                        </div>
                                    </form>
                                </div>
                                <div class="social">
                                    <h5>Follow Us</h5>
                                    <ul class="inline-mode">
                                        <li class="social-network fb"><a title="Connect us on Facebook" href="#"><i
                                                    class="fa fa-facebook"></i></a></li>
                                        <li class="social-network googleplus"><a title="Connect us on Google+"
                                                href="#"><i class="fa fa-google-plus"></i></a></li>
                                        <li class="social-network tw"><a title="Connect us on Twitter" href="#"><i
                                                    class="icon-social-twitter icons"></i></a></li>
                                        <li class="social-network linkedin"><a title="Connect us on Linkedin"
                                                href="#"><i class="fa fa-linkedin"></i></a></li>
                                        <li class="social-network rss"><a title="Connect us on rss" href="#"><i
                                                    class="fa fa-rss"></i></a>
                                        </li>
                                        <li class="social-network instagram"><a title="Connect us on Instagram"
                                                href="#"><i class="fa fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">

                <div class="container">
                    <div class="row">
                        <div class="col-sm-5 col-xs-12 coppyright">Copyright © {{api.getYear()}} <a href="#"> Cherry
                                Valley Marketplace </a>. All
                            Rights Reserved.
                        </div>
                        <div class="col-sm-7 col-xs-12 payment-accept">
                            <ul>
                                <li> <a href="#"><img src="assets/images/payment-1.png" alt="Payment Card"></a>
                                </li>
                                <li> <a href="#"><img src="assets/images/payment-2.png" alt="Payment Card"></a>
                                </li>
                                <li> <a href="#"><img src="assets/images/payment-3.png" alt="Payment Card"></a>
                                </li>
                                <li> <a href="#"><img src="assets/images/payment-4.png" alt="Payment Card"></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</body>
import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiProvider } from 'src/app/Services/api';

@Component({
  selector: 'app-promotional-items',
  templateUrl: './promotional-items.component.html',
  styleUrls: ['./promotional-items.component.css']
})
export class PromotionalItemsComponent implements OnInit {
  public productdata: any = {};
  private id: string = "*";
  public commnad: string = "Add";
  public image: any;
  public updatephoto = false;
  public isShow: boolean = false;
  public results: any = [];
  clip: any;
  constructor(public api: ApiProvider, private router: Router,
    private datePipe: DatePipe, public dialogRef: MatDialogRef<PromotionalItemsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.productdata = {};
    if (data.PRODUCTS.toString().length > 0) {
      this.productdata = data.PRODUCTS;
      if (this.productdata.UPC == undefined) {

        this.productdata.ISNEW = true;
        this.productdata.IDCLOUD = this.api.userprofile.IDCLOUD;
      }
      else {
        this.commnad = "Save";
        this.productdata.ISNEW = this.api.toBoolean(this.productdata.ISNEW);
        this.productdata.DTSTART = this.datePipe.transform(this.productdata.DTSTART, "yyyy-MM-dd");
        this.productdata.DTEND = this.datePipe.transform(this.productdata.DTEND, "yyyy-MM-dd");
        this.image = this.api.endointdata + this.productdata.UPC + ".jpg";
      }
    }
    else {
      this.productdata.ISNEW = true;
      this.productdata.IDCLOUD = this.api.userprofile.IDCLOUD;
      this.productdata.EBT = false;
    }
 
    console.log(this.productdata);
  }
  @Input() requiredFileType: string = "";
  loadDefault(event: any) {
    event.target.src = "assets/images/notItems.png";
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    this.updatephoto = false;
    if (file) {

      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        this.image = reader.result;
        this.updatephoto = true;
      }

    }
  }
  ngOnInit(): void {
    if (this.api.userprofile.login == undefined)
      this.router.navigateByUrl("/home");
    else {
      if (!this.api.userprofile.login)
        this.router.navigateByUrl("/home");
      else {

      }
    }

  }
  Updatedata() {
  
    if (this.productdata.UPC.length>0 && this.updatephoto) {
      var imgdata = this.image;
      if (String(imgdata).length > 0) {
        if (String(imgdata).indexOf("assets/Images/notfound.png") > -1) return;
        imgdata = imgdata.toString().replace("data:image/jpg;base64,", "");
        imgdata = imgdata.toString().replace("data:image/jpeg;base64,", "");
      }

      var dataout1 = {
        ID: this.productdata.UPC,
        IMAGE: imgdata,
      }

      this.api.put('https://www.updatapos.com/sc/POSMAINTENANCE/2', JSON.stringify(dataout1)).subscribe(data => {
        console.log("Photo Updated");


      });
    }

    console.log(this.productdata);

    var dataout = {
      ID: "2",
      DATAFORM: btoa(JSON.stringify(this.productdata))
    }
    this.api.post(this.api.endpoint + '/storeitems', dataout).subscribe(async data => {
      //  console.log(data);
      var dat1 = data;
      if (dat1.toString().length == 0) {
        this.dialogRef.close("1");
        this.api.presentToast("DATA UPTATED .....", "", 2000);
      }
      else {
        this.api.presentToast(dat1.toString(), "", 2000);
      }



    });

  }

  Close() {
    this.dialogRef.close("0");
  }

 
  getsearch() {
    try {
      if (this.productdata.UPC.length > 5) {
        this.results = [];
        this.api.get(this.api.endpoint + "/storeitems/searchitemsMT/" + this.productdata.UPC).subscribe(data => {
          this.isShow = true;
          //          console.log(data);
          this.results = data;
        }, error => {
          this.isShow = false;
        });

      }
      else
        this.isShow = false;
    } catch (e) { }
  }
  Selected(id: string) {
    this.results = [];
    this.isShow = false;
    this.api.get(this.api.endpoint + "/storeitems/SingletitemsMaster/" + id).subscribe((data: any) => {
      console.log(data);
      this.productdata.UPC = data[0].UPC;
      this.productdata.DESCRIPTION = data[0].DESCRIPTION;

      this.image = this.api.endointdata + this.productdata.UPC + ".jpg";
      //  console.log(this.productdata);
    }, error => {

    });

  }
  handleInput(value: any) {
    this.getsearch();

  }
  paste() {
  
    (navigator as any).clipboard.readText().then((clipboard: any) => {
   
      this.updatephoto = false;
      this.api.loadImage(clipboard).then(result => {
      //  console.log(result);
        this.image=result;
   
        this.updatephoto = true;
      });

    
    })

  }
}

<div style="text-align: center;">
    <h1 class="title">Terms of Service</h1>
</div>
<div class="section-wrap even">
    <div id="terms_v4" style="max-width: 954px; margin: auto;">
        <div class="otnotice-content">
            <div class="otnotice-sections">
                <section class="otnotice-section" id="otnotice-section-08497303-7fbb-4737-b2b1-b44bc16c0cd7">
                    <div class="otnotice-section-content">
                        <div id="isPasted"
                            style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px">
                            <div style="font-family:'open sans' , 'salesforce sans' , sans-serif">
                                <h2
                                    style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;text-align:center">
                                    Last updated: November 17, 2022</h2>
                            </div>
                        </div>
                        <p><br></p>
                        <p id="isPasted"
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif">
                            Thank you for using Cherry Valley! These Terms of Service (“Terms”) govern your use
                            of the Cherry Valley services, including any websites, mobile applications, devices,
                            or APIs that are operated by Cherry Valley or its subsidiaries and affiliates
                            (collectively, the “Services”), and are entered into by you and Maplebear Inc.
                            (d/b/a Cherry Valley), a Delaware corporation, and its subsidiaries and affiliates
                            (“Cherry Valley”). This Agreement applies to all visitors, users, and other parties
                            who access the Services (each, hereafter, a “user”).&nbsp;</p>
                        <p style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif">
                            <br style="font-family:'open sans' , 'salesforce sans' , sans-serif">
                        </p>
                        <p style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif">
                            By using the Services, you agree to be bound by these Terms and acknowledge and
                            agree to the collection, use, and disclosure of your personal information in
                            accordance with Cherry Valley’s <a href="/privacypage" target="_blank"
                                rel="nofollow noopener noreferrer">Privacy Policy</a>.&nbsp;</p>
                        <p style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif">
                            <br style="font-family:'open sans' , 'salesforce sans' , sans-serif">
                        </p>
                        <p style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif">
                            SECTION 15 (“DISPUTES &amp; ARBITRATION”) OF THESE TERMS (THE “ARBITRATION
                            AGREEMENT”) PROVIDES THAT ANY CLAIMS THAT YOU AND Cherry Valley HAVE AGAINST EACH
                            OTHER, INCLUDING, WITHOUT LIMITATION, ANY CLAIMS THAT AROSE OR WERE ASSERTED
                            BEFORE THE EFFECTIVE DATE OF THESE TERMS, WILL, WITH LIMITED EXCEPTIONS, BE
                            SUBMITTED TO BINDING AND FINAL ARBITRATION. UNLESS YOU OPT OUT OF THE
                            ARBITRATION AGREEMENT, YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AND SEEK
                            RELIEF AGAINST Cherry Valley ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS
                            MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING. TO THE FULLEST
                            EXTENT PERMITTED BY LAW, YOU ALSO WAIVE YOUR RIGHT TO SEEK RELIEF IN A COURT OF
                            LAW AND TO HAVE A JURY TRIAL ON YOUR CLAIMS. PLEASE SEE SECTION 15 FOR MORE
                            INFORMATION REGARDING THIS ARBITRATION AGREEMENT, THE POSSIBLE EFFECTS OF THIS
                            ARBITRATION AGREEMENT, AND HOW TO OPT OUT OF THE ARBITRATION AGREEMENT.&nbsp;
                        </p>
                        <p style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif">
                            <br style="font-family:'open sans' , 'salesforce sans' , sans-serif">
                        </p>
                        <p style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif">
                            The Services include a technology platform that presents you with a set of one
                            or more retailer (each a “Retailer”) virtual storefronts from which you can
                            select goods for picking and packing by one or more personal shoppers and
                            delivery to your location or, if available, for you to pick up in-store.
                            Depending on the Retailer from whom you purchase goods through the Services,
                            picking and packing, and delivery services may be performed by third parties,
                            which may include Retailer personnel, independent contractors, and third-party
                            logistics providers (collectively, “Third Party Providers”).&nbsp;</p>
                        <p style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif">
                            <br style="font-family:'open sans' , 'salesforce sans' , sans-serif">
                        </p>
                        <p style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif">
                            You acknowledge that Cherry Valley does not supervise, direct, or control the
                            performance of services provided by Third Party Providers, and that Third Party
                            Providers are neither employed by, nor in any partnership or joint venture or
                            agency relationship with, Cherry Valley. &nbsp;</p>
                        <p style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif">
                            <br style="font-family:'open sans' , 'salesforce sans' , sans-serif">
                        </p>
                        <p style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif">
                            When you use the Services to place an order for goods, you authorize the
                            purchase of those goods from the Retailers you select and, if you have selected
                            delivery services, the delivery of those goods by Third Party Providers. Unless
                            otherwise specified, you acknowledge and agree that Cherry Valley and the Third
                            Party Provider are collectively acting as your agents in the ordering, picking,
                            packing, and/or delivery of goods purchased by you and that the Retailer—not the
                            Third Party Provider and not Cherry Valley—is the seller of the goods to you. You
                            agree that your purchase is being made from the Retailer you have selected, that
                            Retailer is the merchant of record, and that title to any goods passes to you
                            when they are purchased at the applicable Retailer’s store. You agree that
                            Cherry Valley or the applicable Retailer will obtain an authorization for your
                            credit card, debit card, or other payment method on file with Cherry Valley to cover
                            the cost of the goods you have purchased from the Retailer and any separate
                            Cherry Valley fees and optional tips, and your payment method &nbsp;will be charged
                            for the goods purchased by you and any applicable fees, taxes and/or tips.&nbsp;
                        </p>
                        <p style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif">
                            <br style="font-family:'open sans' , 'salesforce sans' , sans-serif">
                        </p>
                        <p style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif">
                            Cherry Valley may change the fees it charges for the Services, including but not
                            limited to delivery fees, priority fees, service fees (including additional
                            service fees and/or surcharges to help offset specific costs), alcohol service
                            fees, heavy order fees, direct-to-consumer shipping fees, long distance fees
                            (for deliveries outside the delivery area for a Retailer), and special handling
                            fees. Cherry Valley may vary certain fees based on demand, order attributes, and/or
                            other factors. &nbsp;Your payment instrument will be temporarily authorized for
                            an amount greater than the total amount of the purchase appearing in the
                            original check out. This higher authorized amount will be disclosed during the
                            purchase process and is a temporary authorization charge on your order, to deal
                            with situations where your total purchase amount turns out to be higher than the
                            original amount due to special requests, added items, replacement items, weight
                            adjustments, or tips that you may elect to add after delivery. Retailers set the
                            prices of the goods on the Services, and some Retailers may set prices for goods
                            on the Services that differ from in-store prices, differ between storefronts, or
                            differ from the prices available on other online platforms or services. The
                            prices displayed on the Services may not be the lowest prices at which the same
                            goods or items are sold. Individual Retailers may operate multiple storefronts
                            with different pricing, selection, and order fulfillment. You can view each
                            Retailer’s pricing policies (which may change from time to time) on their
                            storefront(s) on both the website and in the Cherry Valley app.&nbsp;</p>
                        <p style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif">
                            <br style="font-family:'open sans' , 'salesforce sans' , sans-serif">
                        </p>
                        <p style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif">
                            You also acknowledge and agree that, except as expressly provided for otherwise
                            in these Terms or a separate agreement between you and Cherry Valley, Cherry Valley does
                            not form any employment or agency relationship with you and does not hold title
                            to any goods that you order through the Services.&nbsp;</p>
                        <p style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif">
                            <br style="font-family:'open sans' , 'salesforce sans' , sans-serif">
                        </p>
                        <p style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif">
                            Unless otherwise indicated, all prices and other amounts are in the currency of
                            the jurisdiction where the delivery takes place.&nbsp;</p>
                        <p style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif">
                            <br style="font-family:'open sans' , 'salesforce sans' , sans-serif">
                        </p>
                        <p style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif">
                            Occasionally there may be information on the Services that contains
                            typographical errors, inaccuracies, or omissions that may relate to pricing,
                            product descriptions, promotional offers, and product availability. Cherry Valley
                            reserves the right to correct any errors, inaccuracies or omissions and to
                            change or update information or refuse or cancel orders if any information on
                            the Services is inaccurate at any time without prior notice (including after you
                            have submitted your order and/or your payment method has been charged).&nbsp;
                        </p>
                        <p style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif">
                            <br
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                        </p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-5e7e56a0-0ab2-4b99-ac66-4a6cfd10168d">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            1. Your Use of the Services</h2>
                        <p><br></p>
                        <p id="isPasted">Cherry Valley grants you a limited, non-exclusive, non-transferable,
                            and revocable license to use the Services for their intended purposes subject to
                            your compliance with these Terms and any other Cherry Valley policies. You may not
                            copy, modify, distribute, sell, or lease any part of the Services. Unless such
                            restriction is prohibited by law or you have Cherry Valley’s written permission, you
                            may not reverse engineer or attempt to extract the source code of the Services.
                            You may only access the Services through the interfaces that Cherry Valley provides
                            for that purpose (for example, you may not “scrape” the Services through
                            automated means or “frame” any part of the Services), and you may not interfere
                            or attempt to disrupt the Services.&nbsp;</p>
                        <p>Some parts of the Services may allow you to upload or submit content (such as
                            text, images, video, recipes, lists, links, and other materials). As between you
                            and Cherry Valley, you retain all rights in any content that you upload or submit,
                            and are solely responsible for that content. By uploading content, you represent
                            and warrant that you either own it or are authorized to grant Cherry Valley the
                            rights described in these Terms. You are responsible and liable if any of your
                            content violates or infringes the intellectual property or privacy rights of any
                            third party. &nbsp;You grant Cherry Valley a non-exclusive, royalty-free, worldwide,
                            transferable, sub licenseable, perpetual license to use, store, publicly
                            display, publicly perform, reproduce, modify, create derivative works from, and
                            distribute any such content for the purposes of operating, providing, and
                            improving the Services. Cherry Valley may, in its sole discretion, remove or take
                            down any content that you upload or submit to the Services for any reason,
                            including violation of these Terms,  or any other policies.&nbsp;</p>
                        <p>You may have the option of accessing the Services through downloadable software
                            and this software may update itself automatically on your device. Some software,
                            or portions of software, in the Services may be governed by open source
                            licenses. In that case, Cherry Valley will make such licenses available to you and,
                            in the case of conflict between such a license and these Terms, the open source
                            license will control but only with respect to the software, or portion of the
                            software, to which it applies.</p>
                        <p>By using the Services, you represent and warrant that you are of legal age in the
                            jurisdiction in which you reside to form a binding contract with
                            Cherry Valley.&nbsp; If you are using Cherry Valley Services for its intended purposes
                            on behalf of a business or other entity, you represent and warrant that you have
                            the necessary authority to bind that business or entity to these Terms and that
                            you are agreeing to these Terms on behalf of that business or entity.&nbsp;</p>
                        <p>In order to use the Services, you may need to create a user account. You agree
                            that you are responsible for all conduct and transactions that take place on or
                            using your account and that you will take precautions to keep your password and
                            other account information secure. You also agree that you will comply with all
                            applicable laws when accessing or using the Services, you will respect those who you
                            encounter in your use of the Services, including Third Party Providers,
                            Cherry Valley personnel, and individuals who support Cherry Valley’s Help Center.
                            Cherry Valley reserves the right to decline orders, refuse partial or full delivery,
                            terminate or restrict access to accounts or Services, and/or cancel orders at
                            any time in its sole discretion. Cherry Valley may periodically offer you certain
                            incentives or benefits (e.g. trial Cherry Valley+ membership, credit, coupon,
                            promotion code, and/or discount code). &nbsp;If Cherry Valley suspects or
                            determines, in its sole discretion, any fraud, tampering, abuse, or violation of
                            our Terms of Service in connection with any such offer, Cherry Valley reserves the
                            right to withhold, revoke, cancel, and/or terminate the incentive or benefit and
                            to take other remedial measures. &nbsp;</p>
                        <p>We’re constantly modifying and improving the Services. Cherry Valley may introduce
                            new features, change existing features, or remove features from the Services at
                            any time and without notice. If you provide Cherry Valley with any feedback on or
                            comments regarding the Services, you grant Cherry Valley the right to use such
                            feedback or comments for any purpose without restriction or payment to
                            you.&nbsp;</p>
                        <p>If you have any requests for order cancellations, refunds, or returns, please
                            visit your account to initiate such requests or review our Help Center articles
                            for our policies regarding the same. Please note that once a shopper has begun
                            shopping your order and/or delivery has begun, you may no longer have the option
                            to reschedule or cancel the order. If we are able to reschedule or cancel your
                            order, you may be charged a fee and/or may not be refunded for items that have
                            been purchased on your behalf.</p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-059b7030-7241-45cf-8d5d-748c24abf2a7">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            2. Cherry Valley Communications</h2>
                        <p><br></p>
                        <p id="isPasted">By creating an Cherry Valley user account, you agree to accept and
                            receive communications from Cherry Valley or Third Party Providers, including via
                            email, text message, calls, and push notifications to the cellular telephone
                            number you provided to Cherry Valley. You understand and agree that you may receive
                            communications generated by automatic telephone dialing systems and/or which
                            will deliver prerecorded messages sent by or on behalf of Cherry Valley, its
                            affiliated companies, and/or Third Party Providers, including but not limited to
                            communications concerning orders placed through your account on the Services.
                            Message and data rates may apply. If you do not wish to receive promotional
                            emails, text messages, or other communications, you may opt out of such
                            communications at any time in Your Account Settings or by using the unsubscribe
                            mechanism included in the message, where applicable. You may also opt-out of
                            receiving promotional text messages from Cherry Valley by replying “STOP” from the
                            mobile device receiving the messages.&nbsp;</p>
                        <p id="terms-express">If you use the Services to order a prescription drug product
                            (where available), you understand and agree that user inquiries must be
                            conducted via telephone. You also understand and agree that a third party
                            pharmacy and/or Cherry Valley may send you unencrypted SMS messages, push
                            notifications or other electronic notifications relating to prescription drug
                            products that you order through the Services. There is some level of risk that
                            information in such communications could be read by an unintended third party.
                            By using the Services to order a prescription drug product, you explicitly
                            disclaim any liability, and waive and release any claim (to the maximum extent
                            permitted by law), against Cherry Valley, its affiliated companies, and Third Party
                            Providers for any harm or damage arising out of or in connection with any SMS
                            messages, push notifications and/or other electronic notifications.&nbsp;</p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-46904fb2-2740-4cf2-9f4d-d0f79812eca0">
                    <div class="otnotice-section-content">
                        <h2 id="terms-Cherry Valleyplus"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            3. Cherry Valley+</h2>
                        <p><br></p>
                        <p>Cherry Valley+ (formerly Cherry Valley Express) is a membership program that waives the
                            delivery fee and reduces certain service fees for scheduled deliveries on orders
                            $35 or over (the "Cherry Valley+ Benefits") with each Retailer placed through the
                            Services for a monthly or yearly subscription fee. Cherry Valley+ Benefits may
                            differ on certain Retailers’ white label sites that are powered by Cherry Valley.
                            You acknowledge that your Cherry Valley+ membership does not entitle you to faster
                            delivery times or guaranteed or priority time slots. Service fees (including
                            additional fees and surcharges to help offset specific costs), priority fees,
                            direct-to-consumer shipping fees, long distance fees, alcohol service fees,
                            heavy order fees, special handling fees, taxes and/or tips may still apply. For
                            combinations with other offers, restrictions may apply.</p>
                        <p>UNLESS YOU CANCEL, BY DEFAULT (AND WITH PRIOR NOTICE TO THE EXTENT REQUIRED BY
                            APPLICABLE LAW), YOUR Cherry Valley+ MEMBERSHIP WILL AUTOMATICALLY RENEW FOR THE
                            AGREED UPON MONTHLY OR ANNUAL TERM, AND THE APPLICABLE MEMBERSHIP FEE WILL BE
                            AUTOMATICALLY CHARGED TO YOU AT THE TIME OF RENEWAL WITH AN ACTIVE &nbsp;PAYMENT
                            METHOD ON FILE IN YOUR ACCOUNT. The billing date will depend on the type of
                            subscription that you choose when you sign-up for an Cherry Valley+ membership
                            (monthly or yearly) and will be charged on the billing date indicated on your
                            “Your Cherry Valley+ Membership” page. In certain circumstances, your billing date
                            may change. For instance, if we are unable to successfully charge your card for
                            the Cherry Valley+ membership fee on a given day (e.g., a Federal holiday or
                            technical failures). Cherry Valley+ membership fees are fully earned upon
                            payment.&nbsp;</p>
                        <p>If your payment details change, your card or other payment method provider may
                            provide us with updated payment details. We may use these new details or details
                            from other payment methods on file in order to help prevent any interruption to
                            your Cherry Valley+ membership. If you do not want to have your card or other
                            payment method automatically updated, you can opt out of these services by
                            contacting your financial institution. If you would like to use a different
                            payment method or if there is a change in payment method, please update your
                            billing information on your “Your Cherry Valley+ Membership” page under the Payment
                            section. All billing information you provide to us must be truthful and accurate
                            and you represent that you are authorized to use the payment method in the
                            manner contemplated here. If the payment method you provided to us for payment
                            is declined or if a payment was not successfully made by you, you remain
                            responsible for any uncollected amounts. If a payment is not successfully made
                            and you do not cancel your account, we may suspend your access to the Services,
                            including without limitation your Cherry Valley+ membership, until we have
                            successfully charged a valid payment method.&nbsp;</p>
                        <p>From time to time, Cherry Valley offers some users trial or other promotional
                            memberships to Cherry Valley+. Such trial or promotional memberships are subject to
                            these Terms except as otherwise stated in the promotional offer, including which
                            users are eligible for the promotional memberships. Only one trial or
                            promotional membership is available per household and may not be combined with
                            any other promotion. &nbsp;Prior to the end of your free trial period or
                            promotional membership period, Cherry Valley will notify you that your free trial
                            period is coming to an end and will automatically charge you the applicable
                            Cherry Valley+ subscription fee to the payment method on file with Cherry Valley. If you
                            cancel your Cherry Valley+ membership before the free trial or promotional
                            membership period has expired, Cherry Valley will not charge you for the subsequent
                            Cherry Valley+ membership term. Cherry Valley may change the monthly or annual fee
                            charged for membership at any time, but any such fee change will not apply to
                            current Cherry Valley+ members until such time as their current memberships expire
                            and their memberships are renewed for another term. Where the fee has increased,
                            Cherry Valley will notify you prior to the renewal of your membership of the
                            relevant fee increase.</p>
                     
                        <p>Your Cherry Valley+ membership cannot be transferred or assigned. Cherry Valley reserves
                            the right to accept, refuse, or cancel your Cherry Valley+ membership at any time in
                            its sole discretion.&nbsp;</p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-49572c69-8610-4ae9-93b1-c1aa9037deef">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            4. Cherry Valley Coupons</h2>
                        <p><br></p>
                        <p id="isPasted">Cherry Valley Coupons are coupons that are applied to qualifying
                            products upon purchase to help users save money on certain products. Coupons are
                            available for a limited time only and may be subject to certain restrictions and
                            subject to related manufacturers’ or retailers’ terms. Coupons are subject to
                            change, cancellation, or expiration at any time, and to the terms and conditions
                            of the relevant coupon offeror. If you do not purchase the qualifying items
                            added to your cart while the coupon is still in effect, the coupon’s offer will
                            not apply. Coupons are only valid when applied to qualifying items displaying
                            the offer and may not be combined with other promotional offers or mail-in
                            rebates. Cherry Valley is not a retailer or seller. You are responsible for, and you
                            are required to pay any applicable tax or levy of any kind related to your use
                            of the coupon and you acknowledge that Cherry Valley has no obligation for payment
                            of any such tax or levy of any kind in conjunction with the distribution or use
                            of such coupons. When coupons are redeemed, sales tax may be charged on the
                            undiscounted original price of the product(s). If you return any of the products
                            purchased with a coupon, the coupon discount or value will be subtracted from
                            the return credit. Coupons may not be sold, copied, modified, or transferred.
                            Coupons have no cash value and may be limited to one per user unless otherwise
                            disclosed.&nbsp; Coupons are only good while supplies last and are void where
                            restricted or prohibited by law.&nbsp;</p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-3c73a96c-9051-4ced-aa32-a43197bbab43">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            5. Cherry Valley Gift Cards</h2>
                        <p><br></p>
                        <p>Cherry Valley offers currency-denominated digital and physical gift cards that are
                            redeemable for eligible goods and services on Cherry Valley’s website and mobile
                            applications, and on certain retailers’ white label sites that are powered by
                            Cherry Valley (“Gift Cards”). When you purchase or redeem a Gift Card, you agree to
                            the Terms &amp; Conditions for Cherry Valley Gift Cards as well as these Terms of Service. Generally,
                            Gift Cards and/or credits cannot be used to pay for the Cherry Valley+ subscription
                            fee, unless you purchase an Cherry Valley+ or Cherry Valley Express Gift Card, as agreed
                            to by you. &nbsp;</p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-c0ec132d-53ad-47b1-8d9a-de6740e3cf35">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            6. Retailer Memberships</h2>
                        <p><br></p>
                        <p>Certain Retailers may allow you to purchase memberships through the Services. If
                            you purchase a Retailer membership through the Services, you understand that you
                            will be charged separately by the Retailer and that you are purchasing the
                            membership directly from the Retailer and not Cherry Valley and separate terms and
                            conditions provided by the Retailer apply, which you should read carefully.
                            Please note that you will need to contact the Retailer if you have any questions
                            regarding your membership or the management of your account.&nbsp;</p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-65495e4c-c002-4238-b7aa-331367c7807b">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            7. Transactions Involving Alcohol</h2>
                        <p><br></p>
                        <p id="isPasted">You may have the option to order alcohol products in some locations
                            and from certain Retailers. You agree that you will comply with all applicable
                            laws and not cause Cherry Valley or any Third Party Provider (including any
                            Retailer) to contravene any applicable laws. If you order alcohol products from
                            a Retailer through the Services, you agree that you are of legal drinking age
                            for purchasing, possessing, and consuming alcohol (i.e., 21 years of age or
                            older in the United States, 18 years of age or older in Alberta, Manitoba, and
                            Quebec, and 19 years of age or older in all other Canadian provinces). You also
                            agree that, upon delivery of alcohol products by the Third Party Provider, the
                            recipient will provide to the Third Party Provider valid government-issued
                            identification proving their age, and that Third Party Provider may scan this
                            identification and may require a signature from the recipient. &nbsp;You also
                            agree that the recipient will not be intoxicated when receiving delivery of
                            alcohol products, and that you will not purchase alcohol with the intent to
                            resell or provide it to someone who is not of legal drinking age. If the
                            recipient is unable to present valid photo identification upon delivery, your
                            order will be cancelled. You agree that if any applicable legal requirements for
                            the delivery of alcohol are not met, Cherry Valley reserves the right to cancel the
                            alcohol-related portion of your order. Special requests or substitutions for the
                            purchase of alcohol products will not be honored; all requests for the purchase
                            of alcohol products must be made through the catalog available through the
                            Services at the time of submitting the order.&nbsp;</p>
                        <p>Users who purchase alcohol through the Services for delivery by Third Party
                            Providers within the State of Hawaii acknowledge that IT IS ILLEGAL: (1) FOR A
                            PERSON UNDER 21 YEARS OLD TO PURCHASE OR CONSUME LIQUOR, (2) TO USE FALSE
                            IDENTIFICATION TO OBTAIN LIQUOR, (3) TO USE ANOTHER PERSON’S IDENTIFICATION TO
                            OBTAIN LIQUOR, OR (4) TO PURCHASE LIQUOR FOR A PERSON UNDER 21 YEARS OLD.
                            (Sections 281-78 &amp; 101.5, Hawaii Revised Statutes).&nbsp;</p>
                        <p>All alcohol products ordered through the Services for delivery by Third Party
                            Providers within the provinces of Ontario and Alberta will be delivered by
                            Maplebear Delivery Canada Inc.</p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-efddaec2-6c96-4b78-9b6c-01e31c6abdf1">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            8. Delivery of Prescription Drug Products (Cherry Valley Rx)</h2>
                        <p><br></p>
                        <p id="isPasted">In certain geographic areas, you may order prescription drug
                            products through Cherry Valley Rx. This service is not eligible for Cherry Valley+
                            Benefits. You agree that Cherry Valley is not a pharmacy and that we make no
                            referrals or recommendations as to which pharmacies you should use for
                            prescription processing. You also agree that you are solely responsible for the
                            selection of the pharmacy dispensing the prescription. Cherry Valley makes no
                            warranties regarding the quality of the prescription drug products or the
                            services provided by the pharmacy. If you have any issues with the processing of
                            your prescription, you should contact the pharmacy directly.&nbsp;</p>
                        <p>You agree that, upon delivery of prescription drug products by a Third Party
                            Provider, the recipient will provide to the Third Party Provider valid
                            government-issued identification proving their age, and that the Third Party
                            Provider may scan this identification. &nbsp;You also agree that the recipient
                            will indicate their relationship to you (if the recipient is not you), and that
                            any prescription drug product ordered through the Services has not been
                            purchased with the intent to resell the prescription drug product or provide the
                            prescription drug product to someone other than to whom it is prescribed.&nbsp;
                        </p>
                        <p>You also agree that Cherry Valley does not provide any medical advice, diagnosis or
                            treatment, and that neither a pharmacy-patient relationship nor a
                            physician-patient relationship exists between Cherry Valley and you as a result of
                            your use of the Services. We encourage you to consult with your healthcare
                            professional or pharmacist regarding all health-related issues.&nbsp;</p>
                        <p>By using the Services to order a prescription drug product for a child or other
                            member of your household, you represent and warrant that it is your intention
                            not to consume or use the medication but instead to transfer the medication to
                            the ultimate consumer or user for their consumption.&nbsp;</p>
                        <p>IF YOU ARE HAVING A MEDICAL EMERGENCY, CALL 911 OR CONTACT YOUR LOCAL EMERGENCY
                            ASSISTANCE SERVICE IMMEDIATELY.&nbsp;</p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-2f18581a-8beb-404d-862d-9837feed89c5">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            9. Transactions Involving Other Restricted Products</h2>
                        <p><br></p>
                        <p>You may have the option to order other restricted products in some locations and
                            from certain Retailers. You agree that you will comply with all applicable laws
                            and not cause Cherry Valley or any Third Party Provider (including any Retailer) to
                            contravene any applicable laws. If you order an item that requires delivery
                            certification, you agree the recipient will provide valid government-issued
                            identification which may be scanned by the Third Party Provider proving their
                            identity to the Third Party Provider delivering such products. If you order
                            age-restricted products from a Retailer through the Services, you agree that you
                            are of legal age for purchasing, possessing, and/or consuming such
                            age-restricted products and agree that, upon delivery of age-restricted products
                            by the Third Party Provider, the recipient will provide to the Third Party
                            Provider valid government-issued identification proving their age, and that the
                            Third Party Provider may scan this identification. &nbsp;You also agree that the
                            age-restricted products have not been purchased with the intent to resell or
                            provide to someone who is not of legal age. You agree that if any applicable
                            legal requirements for the delivery of age-restricted products are not met,
                            Cherry Valley reserves the right to cancel the age-restricted portion of your order.
                            Special requests or substitutions for the purchase of age-restricted products
                            will not be honored; all requests for the purchase of age-restricted products
                            must be made through the catalog available through the Services at the time of
                            submitting the order.</p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-d351e5c3-aa21-4e8c-a549-a6f43498a840">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            10. SNAP/EBT</h2>
                        <p><br></p>
                        <p>Cherry Valley allows customers in the U.S. to pay for online orders of Supplemental
                            Nutrition Assistance Program (SNAP) eligible items from select retailers with a
                            valid EBT card.&nbsp;</p>
                        <p>By providing your EBT information, you represent and warrant that you are
                            authorized to legally use the designated payment information and that you
                            authorize us to use that EBT information for the Services. If the payment
                            information cannot be verified, is invalid or is otherwise not acceptable, the
                            Services may be suspended or canceled.&nbsp;</p>
                        <h3><span style="font-size:18px;">SNAP Eligible Items&nbsp;</span></h3>
                        <p>You may purchase SNAP eligible items with your EBT card. SNAP eligible items are
                            determined by the U.S. Department of Agriculture. You can learn more about
                            shopping for EBT eligible items <a href="/help/article/ebt-snap-overview"
                                rel="nofollow noopener noreferrer" target="_blank">here</a>.&nbsp;</p>
                        <h3><span style="font-size:18px;">Payment&nbsp;</span></h3>
                        <p>By associating your SNAP EBT card to your Cherry Valley account, you confirm that
                            your card information is current and valid. In addition, a credit or debit card
                            must also be linked to your Cherry Valley account to cover fees, taxes, delivery
                            tips, and any other non-EBT eligible items you may want to purchase.
                            &nbsp;Currently Cherry Valley cannot accept EBT cash. To pay for eligible food items
                            on Cherry Valley you can use SNAP EBT funds.&nbsp;</p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-9c77f093-bf32-4137-878f-dca194df2410">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            11. Third-party Products and Content</h2>
                        <p><br></p>
                        <p>You agree that Cherry Valley does not assume responsibility for any products,
                            content, services, websites, advertisements, offers, or information that is
                            provided by third parties and made available through the Services, nor does
                            Cherry Valley assume responsibility for your interactions with any Third Party
                            Provider (including a Retailer). You further agree that Cherry Valley does not
                            warrant or guarantee that any product information contained on the Services is
                            accurate, complete, reliable, current, or error-free. All health and wellness
                            information, nutritional content, and nutritional information is provided for
                            informational purposes only and is not a substitute for the diagnosis, treatment
                            and advice of a qualified health-care provider. Cherry Valley does not warrant or
                            guarantee that any such health, wellness, or nutritional information is
                            accurate, complete, reliable, current, or error-free. If you purchase, use, or
                            access any such products, content, services, advertisements, offers, or
                            information through the Services or you engage with any Third Party Provider,
                            you agree that you do so at your own risk and that Cherry Valley will have no
                            liability based on such purchase, use, access, or engagement.&nbsp;</p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-b5ca7386-42a2-4abb-a020-bd4d92cba62b">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            12. SERVICE PROVIDED AS-IS AND RELEASE OF CLAIMS</h2>
                        <p><br></p>
                        <p id="isPasted">THE SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE." Cherry Valley
                            DISCLAIMS ALL REPRESENTATIONS, CONDITIONS, AND WARRANTIES, EXPRESS, LEGAL,
                            IMPLIED, OR STATUTORY, INCLUDING THE IMPLIED WARRANTIES OR CONDITIONS OF
                            MERCHANTABILITY, QUALITY, FITNESS FOR A PARTICULAR PURPOSE, DURABILITY, TITLE,
                            AND NON INFRINGEMENT. IN ADDITION, Cherry Valley MAKES NO REPRESENTATION, WARRANTY,
                            CONDITIONS, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY,
                            SUITABILITY, OR AVAILABILITY OF THE SERVICES, ANY SERVICES PROVIDED BY THIRD
                            PARTY PROVIDERS, OR GOODS REQUESTED THROUGH THE USE OF THE SERVICES FROM
                            RETAILERS, OR THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. Cherry Valley
                            DOES NOT GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR ABILITY OF THIRD PARTY
                            PROVIDERS, OR RETAILERS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE
                            OF THE SERVICES, ANY SERVICES PROVIDED BY THIRD PARTY PROVIDERS, OR ANY PRODUCTS
                            REQUESTED BY YOU OR DELIVERED TO YOU, REMAINS SOLELY WITH YOU. THE SERVICES,
                            WEBSITE, AND SOFTWARE ARE SUBJECT TO PERIODIC CHANGES, WHICH MAY BE MADE AT ANY
                            TIME AND WITHOUT NOTICE TO YOU.&nbsp;</p>
                        <p>Cherry Valley DOES NOT GUARANTEE THAT THE SERVICES, WEBSITE, AND SOFTWARE WILL
                            OPERATE WITHOUT ERRORS OR THAT THE SERVICES, WEBSITE, AND SOFTWARE ARE FREE OF
                            COMPUTER VIRUSES OR OTHER MALWARE. YOU AGREE THAT Cherry Valley WILL NOT BE
                            RESPONSIBLE FOR ANY ECONOMIC COSTS RELATING TO YOUR USE OF THE SERVICES,
                            WEBSITE, OR SOFTWARE.&nbsp;</p>
                        <p>YOU AGREE THAT NEITHER Cherry Valley NOR ITS SUBSIDIARIES, AFFILIATES, RETAILERS,
                            LICENSORS, OR SUPPLIERS IS RESPONSIBLE FOR THE FITNESS OR CONDUCT OF ANY THIRD
                            PARTY PROVIDER OR FOR ANY SERVICES PROVIDED BY ANY THIRD PARTY PROVIDER. NEITHER
                            Cherry Valley NOR ITS SUBSIDIARIES, AFFILIATES, RETAILERS, LICENSORS, OR SUPPLIERS
                            WILL BE LIABLE FOR ANY CLAIM, INJURY OR DAMAGE ARISING IN CONNECTION WITH THE
                            ACTS OR OMISSIONS OF ANY THIRD PARTY PROVIDER.&nbsp;</p>
                        <p>If you have a dispute with one or more Third Party Providers, you agree to
                            release Cherry Valley (including Cherry Valley’s subsidiaries and affiliates, and each
                            of their respective officers, directors, employees, agents, shareholders, retail
                            partners, licensors, and suppliers) from any claims, demands and damages of
                            every kind and nature, known and unknown, suspected and unsuspected, disclosed
                            and undisclosed, arising out of or in any way connected to such disputes.&nbsp;
                        </p>
                        <p>Furthermore, you expressly waive any rights you may have under California Civil
                            Code Section 1542 (or analogous laws of other jurisdictions), which states: “A
                            general release does not extend to claims that the creditor or releasing party
                            does not know or suspect to exist in his or her favor at the time of executing
                            the release, and that, if known by him or her must, would have materially
                            affected his or her settlement with the debtor or released party.”&nbsp;</p>
                        <p>U.S. FEDERAL LAW, SOME STATES, PROVINCES AND OTHER JURISDICTIONS DO NOT ALLOW THE
                            EXCLUSION AND LIMITATION OF CERTAIN IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS
                            MAY NOT APPLY TO YOU. THESE TERMS GIVE YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY
                            ALSO HAVE OTHER RIGHTS WHICH VARY FROM JURISDICTION TO JURISDICTION. THE
                            DISCLAIMERS AND EXCLUSIONS UNDER THESE TERMS WILL NOT APPLY TO THE EXTENT
                            PROHIBITED BY APPLICABLE LAW.&nbsp;</p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-aa82b297-1fef-4da9-a7cf-2fca4b59cfa6">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            13. LIMITATION OF LIABILITY</h2>
                        <p><br></p>
                        <p id="isPasted">IN NO EVENT SHALL Cherry Valley (INCLUDING ITS SUBSIDIARIES AND
                            AFFILIATES, AND EACH OF THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
                            SHAREHOLDERS, RETAIL PARTNERS, LICENSORS, AND SUPPLIERS) BE LIABLE TO YOU FOR
                            ANY INCIDENTAL, SPECIAL, PUNITIVE, CONSEQUENTIAL, OR INDIRECT DAMAGES
                            (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR DELETION, CORRUPTION, LOSS OF DATA,
                            LOSS OF PROGRAMS, FAILURE TO STORE ANY INFORMATION OR OTHER CONTENT MAINTAINED
                            OR TRANSMITTED BY THE SERVICES, SERVICE INTERRUPTIONS, OR FOR THE COST OF
                            PROCUREMENT OF SUBSTITUTE SERVICES) ARISING OUT OF OR IN CONNECTION WITH THE
                            SERVICES, OR THESE TERMS, HOWEVER ARISING INCLUDING NEGLIGENCE, EVEN IF
                            Cherry Valley OR Cherry Valley’S AGENTS OR REPRESENTATIVES KNOW OR HAVE BEEN ADVISED OF
                            THE POSSIBILITY OF SUCH DAMAGES.&nbsp;</p>
                        <p>IN NO EVENT SHALL Cherry Valley (INCLUDING ITS SUBSIDIARIES AND AFFILIATES, AND EACH
                            OF THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SHAREHOLDERS, RETAIL
                            PARTNERS, LICENSORS, AND SUPPLIERS) BE LIABLE FOR ANY INDIRECT, SPECIAL,
                            PUNITIVE, INCIDENTAL, EXEMPLARY AND/OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT
                            LIMITED TO PHYSICAL DAMAGES, BODILY INJURY, DEATH AND/OR EMOTIONAL DISTRESS AND
                            DISCOMFORT) ARISING OUT OF YOUR USE OF THE SERVICES, ANY SERVICES PROVIDED BY
                            THIRD PARTY PROVIDERS, OR ANY PRODUCTS REQUESTED BY YOU OR DELIVERED TO YOU,
                            EVEN IF Cherry Valley OR Cherry Valley’S AGENTS OR REPRESENTATIVES KNOW OR HAVE BEEN
                            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.&nbsp;</p>
                        <p>Cherry Valley, ITS SUBSIDIARIES, AFFILIATES, RETAIL PARTNERS, LICENSORS, SUPPLIERS
                            AND DISTRIBUTORS WILL NOT BE LIABLE FOR AGGREGATE LIABILITY FOR ALL CLAIMS
                            RELATING TO THE SERVICES, ANY SERVICES PROVIDED BY THIRD PARTY PROVIDERS, OR ANY
                            PRODUCTS REQUESTED BY YOU OR DELIVERED TO YOU FOR MORE THAN THE GREATER OF $100
                            OR THE AMOUNTS PAID BY YOU TO Cherry Valley DURING THE PAST 12 MONTHS IN CONNECTION
                            WITH THE SERVICES.&nbsp;</p>
                        <p>U.S. FEDERAL LAW, SOME STATES, PROVINCES AND OTHER JURISDICTIONS DO NOT ALLOW THE
                            EXCLUSION AND LIMITATION OF CERTAIN LIABILITIES, SO THE ABOVE EXCLUSIONS MAY NOT
                            APPLY TO YOU. THESE TERMS GIVE YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE
                            OTHER RIGHTS WHICH VARY FROM JURISDICTION TO JURISDICTION. THE EXCLUSIONS AND
                            LIMITATIONS UNDER THESE TERMS WILL NOT APPLY TO THE EXTENT PROHIBITED BY
                            APPLICABLE LAW.&nbsp;</p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-12e18563-38f2-4f89-a579-db4ef22dc6dc">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            14. Indemnification</h2>
                        <p><br></p>
                        <p id="isPasted">You agree to defend, indemnify and hold harmless Cherry Valley and its
                            officers, directors, employees, agents, shareholders, subsidiaries, affiliates,
                            and retail partners (each, an "Indemnified Party") from and against any losses,
                            claims, actions, costs, damages, penalties, fines and expenses, including
                            without limitation attorneys’ and experts’ fees and expenses, that may be
                            incurred by an Indemnified Party arising out of, relating to or resulting from:
                            (i) your unauthorized use of the Services or from any breach by you of these
                            Terms, including without limitation any actual or alleged violation of any law,
                            rule or regulation; (ii) any third party’s access or use of the Services using
                            your Cherry Valley user account; or (iii) any dispute or issue between you and any
                            third party, including without limitation any Retailer or other Third Party
                            Provider.&nbsp;</p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-abe70605-720f-4328-aec8-ea30f2c138c6">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            15. Disputes &amp; Arbitration</h2>
                        <p><br></p>
                        <p id="isPasted">This Section 15 (the “Arbitration Agreement”) applies to and
                            governs any dispute, controversy, or claim between you and Cherry Valley, including
                            but not limited to those that arise out of or relate to: (a) these Terms,
                            including the formation, existence, breach, termination, enforcement,
                            interpretation, validity, scope, or enforceability thereof; (b) access to or use
                            of the Services, including receipt of any advertising or marketing
                            communications or any information contained on the Services; (c) any
                            transactions through, by, or using the Services, including any goods or services
                            purchased or sold through, by, or using the Services or your consumption or use
                            of those goods or services; or (d) any other aspect of your relationship or
                            transactions with Cherry Valley as a consumer. You and Cherry Valley agree that the
                            Retailers from which orders may be placed and goods may be purchased through the
                            Services and/or through white label sites that are powered by Cherry Valley are
                            intended third party beneficiaries of this Arbitration Agreement.</p>
                        <p><strong>Informal Dispute Resolution</strong>: You and Cherry Valley agree that
                            good-faith informal efforts to resolve disputes often can result in a prompt,
                            low-cost and mutually beneficial outcome. Therefore, before initiating formal
                            proceedings, you and Cherry Valley agree to first attempt to work out any such
                            dispute amicably. The initiating party must give notice to the other party in
                            writing of its, his, or her dispute, including a written description of the
                            dispute. For any dispute you initiate, you agree to provide written notice to:
                        </p>
                        <p>Cherry Valley<br>ATTN: Legal Department<br>155 Thomaston Ave<br>Waterbury, CT 06702</p>
                        <p>Your written description must be on an individual basis and personally signed,
                            and also provide at least the following information: your name; the email
                            address associated with your account; a detailed description of the nature and
                            basis of the dispute, including any transaction details; and the specific relief
                            sought and how it was calculated. For any dispute that Cherry Valley raises, we will
                            send our written description of the dispute (including the information listed
                            above), signed by an authorized Cherry Valley representative, to the most recent
                            delivery address associated with your account. The initiating party must allow
                            the other party 60 days to respond and attempt to resolve the dispute amicably
                            before initiating an arbitration or other proceeding per the terms set forth
                            below.</p>
                        <p>You and Cherry Valley agree that this informal dispute resolution process is a
                            requirement that must be fulfilled prior to initiating an arbitration
                            proceeding. The applicable statute of limitations period and any filing fee
                            deadlines shall be tolled from the time the initiating party sends their written
                            notice through the 60 day period set forth above.</p>
                        <p><strong>For residents of the United States, you agree to the following mandatory
                                arbitration provisions:&nbsp;</strong></p>
                        <p>Mandatory Arbitration: If we’re unable to work out a solution amicably, both you
                            and Cherry Valley agree to resolve through binding arbitration, rather than in
                            court, any dispute, controversy, or claim arising at any time, including but not
                            limited to those arising out of or relating to: (i) these Terms, including the
                            formation, existence, breach, termination, enforcement, interpretation,
                            validity, scope, or enforceability thereof; (ii) access to or use of the
                            Services, including receipt of any advertising or marketing communications
                            and/or any information contained on the Services; (iii) any transactions
                            through, by, or using the Services, including any goods or services purchased or
                            sold through, by, or using the Services and any consumption or use of those
                            goods and services; or (iv) any other aspect of your relationship or
                            transactions with Cherry Valley as a consumer. A Claim under this Section also
                            includes all claims or disputes between you and any Retailer arising out of or
                            related to the Services performed under this Agreement and/or services in
                            connection with any white label sites that are powered by Cherry Valley. Any
                            disputes or claims in this regard shall be resolved exclusively by an
                            arbitrator.</p>
                        <p>Notwithstanding this mandatory arbitration provision, (a) you may assert claims
                            in small claims court, if your claims qualify and so long as the matter remains
                            in such court and advances only on an individual (non-class, non-representative)
                            basis, and (b) you and Cherry Valley each retain the right to seek injunctive or
                            other equitable relief in a court of competent jurisdiction to prevent the
                            actual or threatened infringement of a party’s intellectual property
                            rights.&nbsp;</p>
                        <p>This Arbitration Agreement shall apply, without limitation, to all claims that
                            arose or were asserted before the Effective Date of these Terms.&nbsp;</p>
                        <p>The Federal Arbitration Act, 9 U.S.C. § 1 et seq. (the “FAA”), governs the
                            interpretation and enforcement of this Arbitration Agreement and preempts all
                            state laws to the fullest extent permitted by law. If the FAA is found to not
                            apply to any issue that arises from or relates to this Arbitration Agreement,
                            then that issue shall be resolved under and governed by the law of the
                            claimant’s state of residence.&nbsp;</p>
                        <p><strong>TRIAL BY JURY WAIVER</strong>: BY ENTERING INTO THESE TERMS, YOU
                            ACKNOWLEDGE AND AGREE THAT, WITH RESPECT TO ANY CLAIM(S) WITHIN THE SCOPE OF
                            THIS ARBITRATION AGREEMENT, YOU AND Cherry Valley ARE EACH WAIVING THE RIGHT TO A
                            TRIAL BY JURY.&nbsp;</p>
                        <p><strong>CLASS, COLLECTIVE, REPRESENTATIVE ACTION WAIVER</strong>: BY ENTERING
                            INTO THESE TERMS, YOU ACKNOWLEDGE AND AGREE THAT, WITH RESPECT TO ANY CLAIM(S)
                            WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT, YOU AND Cherry Valley ARE EACH
                            WAIVING THE RIGHT TO BRING, JOIN, OR PARTICIPATE IN, EITHER AS A PLAINTIFF OR
                            CLASS MEMBER, IN ANY PURPORTED CLASS, COLLECTIVE, OR REPRESENTATIVE ACTION OR
                            PROCEEDING. YOU ALSO ACKNOWLEDGE AND AGREE THAT, UNLESS YOU AND Cherry Valley
                            OTHERWISE AGREE IN WRITING, ANY ARBITRATION OF ANY CLAIM(S) WITHIN THE SCOPE OF
                            THIS ARBITRATION AGREEMENT WILL BE CONDUCTED ONLY ON AN INDIVIDUAL BASIS AND NOT
                            IN A CLASS, COLLECTIVE, CONSOLIDATED, OR REPRESENTATIVE PROCEEDING. YOU FURTHER
                            ACKNOWLEDGE THAT THE ARBITRATOR MAY AWARD RELIEF ONLY IN YOUR FAVOR AND ONLY TO
                            THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY YOUR INDIVIDUAL CLAIM(S),
                            AND ANY RELIEF AWARDED CANNOT AFFECT OTHER Cherry Valley USERS. THE ARBITRATOR MAY
                            NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS OR ENGAGE IN ANY CLASS,
                            COLLECTIVE, OR REPRESENTATIVE ARBITRATION.&nbsp;</p>
                        <p>Opt-out of Mandatory Arbitration: You can reject and opt-out of this Arbitration
                            Agreement within 30 days of first accepting these Terms by emailing Cherry Valley at
                            <a href="mailto:arbitration-opt-out@CherryValley.com"
                                rel="nofollow">arbitration-opt-out@CherryValley.com</a> with your first and
                            last name and the email address associated with your account and stating your
                            intent to opt-out of the Arbitration Agreement. Note that opting out of this
                            Arbitration Agreement does not affect any other part of these Terms, including
                            the provisions regarding controlling law or in which courts any disputes must be
                            brought.&nbsp;
                        </p>
                        <p>Changes to Agreement to Arbitrate: If Cherry Valley changes this Section after the
                            date you last accepted these Terms (or accepted any subsequent changes to these
                            Terms), you may reject that change by sending us written notice via email as set
                            forth above, within 30 days of the date the change is effective. Rejecting a new
                            change, however, does not revoke or alter your prior consent or any earlier
                            agreements to arbitrate any dispute between you and Cherry Valley and/or its
                            Retailers.&nbsp;</p>
                        <p><strong>Except as described below for California
                                residents</strong><strong>,</strong> the arbitration shall be administered
                            by the American Arbitration Association (“AAA”) under its Consumer Arbitration
                            Rules (the “Rules”) in effect at the time the arbitration demand is made. The
                            Rules are available at www.adr.org. In the event of any conflict between the
                            Rules and this Arbitration Agreement, this Arbitration Agreement shall
                            apply.&nbsp;</p>
                        <p>The Parties agree to submit to the jurisdiction of a single neutral arbitrator
                            (the “Arbitrator”) selected in accordance with the Rules. The Arbitrator will
                            decide the rights and liabilities, if any, of you and Cherry Valley. The Arbitrator
                            will have the authority to award the same damages and relief on an individual
                            basis that a judge in a court of law can award to an individual. The Arbitrator
                            shall follow the applicable law. The Arbitrator’s decision is final and binding
                            on you and Cherry Valley.&nbsp;</p>
                        <p>You and Cherry Valley agree that the Arbitrator, and not any federal, state, or local
                            court or agency, shall have exclusive authority to resolve any disputes relating
                            to the interpretation, applicability, enforceability or formation of this
                            Arbitration Agreement, including any claim that all or any part of this
                            Arbitration Agreement is void or voidable. The Arbitrator shall also be
                            responsible for determining all threshold arbitrability issues, including issues
                            relating to whether the Terms are unconscionable or illusory and any defense to
                            arbitration, including waiver, delay, laches, unconscionability, or
                            estoppel.&nbsp;</p>
                        <p>The arbitration will be held in the United States county where you live or use
                            the Services, or a location you and Cherry Valley mutually agree upon in writing. If
                            your claim does not exceed $10,000, then the arbitration will be conducted
                            solely on the basis of documents you and Cherry Valley submit to the arbitrator,
                            unless the arbitrator determines that a hearing is necessary. If your claim
                            exceeds $10,000, your right to a hearing will be determined by the Rules.&nbsp;
                        </p>
                        <p>The Rules will govern the payment of all filing, administrative or arbitrator
                            fees (“Arbitration Fees”) and each party will be responsible for their own
                            Arbitration Fees under those Rules or to the maximum extent permitted by
                            law.&nbsp;</p>
                        <p><strong>For California residents,&nbsp;</strong>the arbitration shall be
                            administered by ADR Services, Inc. (“ADR Services”) under its Arbitration Rules
                            (the “ADR Services Rules”) in effect at the time the arbitration demand is made.
                            The ADR Services Rules are available at:
                            https://www.adrservices.com/services/arbitration-rules/. In the event of any
                            conflict between the ADR Services Rules and this Arbitration Agreement, this
                            Arbitration Agreement shall apply.&nbsp;</p>
                        <p>For California residents, the Parties agree to submit to the jurisdiction of a
                            single neutral arbitrator selected in accordance with the ADR Services Rules
                            (the “ADR Arbitrator”). The ADR Arbitrator will decide the rights and
                            liabilities, if any, of you and Cherry Valley. The ADR Arbitrator will have the
                            authority to award the same damages and relief on an individual basis that a
                            judge in a court of law can award to an individual. The ADR Arbitrator shall
                            follow the applicable law. The ADR Arbitrator’s decision is final and binding on
                            you and Cherry Valley.&nbsp;</p>
                        <p>For California residents, you and Cherry Valley agree that the ADR Arbitrator, and
                            not any federal, state, or local court or agency, shall have exclusive authority
                            to resolve any disputes relating to the interpretation, applicability,
                            enforceability or formation of this Arbitration Agreement, including any claim
                            that all or any part of this Arbitration Agreement is void or voidable. The ADR
                            Arbitrator shall also be responsible for determining all threshold arbitrability
                            issues, including issues relating to whether the Terms are unconscionable or
                            illusory and any defense to arbitration, including waiver, delay, laches,
                            unconscionability, or estoppel.&nbsp;</p>
                        <p>For California residents, the arbitration will be held in the California county
                            where you live or use the Services, San Francisco, California, or any other
                            location you and Cherry Valley mutually agree upon in writing.</p>
                        <p>For California residents, the ADR Services Rules will govern the payment of all
                            filing, administrative or arbitrator fees (“ADR Services Arbitration Fees”), but
                            in no event will you be responsible for any portion of those fees in excess of
                            the initial filing fee. After you pay your portion of any initial filing fee,
                            Cherry Valley shall pay any remaining costs and expenses unique to arbitration,
                            including, without limitation, the arbitrator’s fees and any ADR Services
                            administrative or case management expenses.&nbsp;</p>
                        <h3>SEVERANCE OF ARBITRATION AGREEMENT</h3>
                        <p>If this Arbitration Agreement is found to be void, unenforceable, or unlawful, in
                            whole or in part, the void, unenforceable, or unlawful provision, in whole or in
                            part, shall be severed. Severance of the void, unenforceable, or unlawful
                            provision, in whole or in part, shall have no impact on the remaining provisions
                            of the Arbitration Agreement, which shall remain in force, or the parties’
                            ability to compel arbitration of any remaining claims on an individual basis
                            pursuant to the Arbitration Agreement. Notwithstanding the foregoing, if the
                            Class, Collective, Representative Action Waiver above is found to be void,
                            unenforceable, or unlawful, in whole or in part, because it would prevent you
                            from seeking public injunctive relief, then any dispute regarding the
                            entitlement to such relief (and only that relief) must be severed from
                            arbitration and may be litigated in a civil court of competent jurisdiction. All
                            other claims for relief subject to arbitration under this Arbitration Agreement
                            shall be arbitrated under its terms, and the parties agree that litigation of
                            any dispute regarding the entitlement to public injunctive relief shall be
                            stayed pending the outcome of any individual claims in arbitration.&nbsp;</p>
                        <h3>SURVIVAL OF ARBITRATION AGREEMENT</h3>
                        <p>Except as otherwise provided in this Section 15, this Arbitration Agreement will
                            survive any termination of the Terms or of your access to the Services,
                            including under Section 16 or otherwise. &nbsp;This Arbitration Agreement will
                            continue to apply even if you or we close your Cherry Valley account.</p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-054ed992-fe5f-409d-a71a-1a7ef1e58cf1">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            16. Termination</h2>
                        <p><br></p>
                        <p id="isPasted">You can stop using the Services at any time and without notice to
                            us. Similarly, Cherry Valley may terminate access to the Services to you or any
                            other users or stop offering all or part of the Services at any time without
                            notice. In the event of Termination, Section 1 and Sections 4-22 survive and
                            continue to apply to you.&nbsp;</p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-8b451859-6a79-48ca-aa07-c133d7627890">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            17. Controlling Law</h2>
                        <p><br></p>
                        <p id="isPasted">To the extent permitted by applicable law, these Terms will be
                            governed by the laws of the State of Delaware for residents of the United States
                            and by the laws of the Province of Ontario for residents of Canada (except
                            Quebec), without respect to its conflicts of laws principles, except the
                            Arbitration Agreement, which is governed by the laws outlined in Section 15 of
                            these Terms. To the extent permitted by applicable law, any claims arising out
                            of or relating to these Terms or use of the Services that are not subject to
                            Section 15 (Disputes &amp; Arbitration) of these Terms shall be brought
                            exclusively in the federal or state courts of New Castle County, Delaware, USA,
                            for the residents of the United States, and the courts of competent jurisdiction
                            in the City of Toronto for residents of Canada (except Quebec), and you and
                            Cherry Valley consent to the personal jurisdiction of those courts.&nbsp;</p>
                        <p>For Quebec residents only, to the extent permitted by law, these Terms will be
                            governed by the laws of the Province of Quebec without respect to its conflicts
                            of laws principles. To the extent permitted by applicable law, any claims
                            arising out of or relating to these Terms or use of the Services that are not
                            subject to Section 15 (Disputes &amp; Arbitration) of these Terms shall be
                            brought exclusively in the courts of competent jurisdiction in the City of
                            Montreal, and you and Cherry Valley consent to the personal jurisdiction of those
                            courts.&nbsp;</p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-9668c594-2481-43a8-95c0-fdb728c37d6d">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            18. Entire Agreement &amp; Severability</h2>
                        <p><br></p>
                        <p id="isPasted">These Terms, subject to any amendments, modifications, or
                            additional agreements you enter into with Cherry Valley, shall constitute the entire
                            agreement between you and Cherry Valley with respect to the Services and any use of
                            the Services. If any provision of these Terms is found to be invalid by a court
                            competent jurisdiction, that provision only will be limited to the minimum
                            extent necessary and the remaining provisions will remain in full force and
                            effect.&nbsp;</p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-aa7b244a-93eb-400d-bd35-3482111fca57">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            19. No Waiver</h2>
                        <p><br></p>
                        <p id="isPasted">Cherry Valley’s failure to monitor or enforce a provision of these
                            Terms does not constitute a waiver of its right to do so in the future with
                            respect to that provision, any other provision, or these Terms as a whole.&nbsp;
                        </p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-9680122b-1656-4167-ba72-6b1d048d70cf">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            20. Assignment</h2>
                        <p><br></p>
                        <p id="isPasted">You may not assign any of your rights, licenses, or obligations
                            under these Terms. Any such attempt at assignment by you shall be void.
                            Cherry Valley may assign its rights, licenses, and obligations under these Terms
                            without limitation.&nbsp;</p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-919dc4e3-9b52-4a97-b6a4-f8dbed17b512">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            21. Changes to the Terms</h2>
                        <p><br></p>
                        <p id="isPasted">We may make changes to these Terms from time to time. When
                            Cherry Valley does so, Cherry Valley will post the most current version of the Terms on
                            Cherry Valley’s website and, if a revision to the Terms is material, Cherry Valley will
                            notify you of the new Terms (for example, by email or a notification on the
                            Services). Changes to these terms will not apply retroactively. If you do not
                            agree to the modified terms, you should discontinue your use of the
                            Services.&nbsp;</p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-cd777c41-834a-4bae-b0d6-a0485179e74a">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            22. Copyright and Trademark Policy</h2>
                        <p><br></p>
                        <p id="isPasted">Cherry Valley respects the intellectual property rights of others and
                            has implemented a copyright and trademark policy in accordance with the Digital
                            Millennium Copyright Act and other relevant laws. Cherry Valley will respond to
                            valid notices of copyright or trademark infringement and reserves the right to
                            terminate any users, at Cherry Valley’s sole discretion and without notice, who
                            infringe copyrights or other intellectual property rights.&nbsp;</p>
                        <p>If you believe any content posted or made available on the Services constitutes
                            infringement of your copyright rights, you may send a written notice of
                            infringement to Cherry Valley’s designated Copyright Agent using the contact
                            information listed below. In your notice, please specify the nature of the
                            copyright infringement and include the following information: (a) an electronic
                            or physical signature of the owner of the copyright in question or a person
                            authorized to act on behalf of the owner of the copyright; (b) a description of
                            the claimed infringing material as well as identification of the claimed
                            infringing material, including the location of such material on the Services
                            (e.g., the URL of the claimed infringing material if applicable or other means
                            by which Cherry Valley may locate the material); (c) complete contact information,
                            including the name of the owner of the copyright and your name, title, address,
                            telephone number, and email address; (d) a statement that you have a good faith
                            belief that the disputed use is not authorized by the copyright owner, its
                            agent, or the law; and (e) a statement, made under penalty of perjury, that the
                            information provided in your notice is accurate and that you are the copyright
                            owner or authorized to act on behalf of the owner.&nbsp;</p>
                        <p>If you believe any content posted or made available on the Services constitutes
                            infringement of your trademark rights, you may also send your notice to
                            Cherry Valley's designated Copyright Agent using the contact information listed
                            above. Please include as much detail as possible so that we may respond to your
                            notice in a timely manner, including but not limited to description(s) of your
                            trademark(s), your trademark registration number(s), description(s) of the
                            products allegedly using your trademark(s) without authorization, and the
                            location of such allegedly infringing product(s).&nbsp;</p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-2f533a9e-f014-4f09-b0c6-1c5b72f807c3">
                    <div class="otnotice-section-content">
                        <h2 id="isPasted"
                            style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 77 , 77 , 77 );font-style:normal;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:center;">
                            23. Contact Information</h2>
                        <p><br></p>
                        <p id="isPasted">If you have any questions, or comments about these Terms please
                            contact Cherry Valley at:&nbsp;</p>
                        <p>Cherry Valley&nbsp;<br>155 Thomaston Ave&nbsp;<br>Waterbury, CT 06702&nbsp;<br><a href="mailto:legal@CherryValley.com" rel="nofollow">legal@CherryValley.com</a><br><a href="tel:+12037545700" rel="nofollow">1-203-754-5700</a></p>
                        <p>For customer service inquiries, please review Your Account Settings, visit
                            Cherry Valley’s <a href="/help" rel="nofollow noopener noreferrer" target="_blank">Help
                                Center</a>, or call our Community Operations team at <a href="tel:+12037545700"
                                rel="nofollow">1 (203) 754-5700</a>.&nbsp;</p>
                    </div>
                </section>
            </div>
            <div class="otnotice-version"></div>
        </div>
    </div>


</div>
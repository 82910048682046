<div style="text-align: center;">
    <h1 class="title">Privacy Policy</h1>
</div>
<div class="section-wrap even">
    <div id="policy_v4" style="max-width: 954px; margin: auto;">
        <div class="otnotice-content">
            <div class="otnotice-sections">
                <section class="otnotice-section" id="otnotice-section-ca576218-8a56-462c-85cd-9a7f5d22cc26">
                    <div class="otnotice-section-content">
                        <h2
                                    style="margin:0px;padding:0px;font-weight:700;font-size:1.8rem;font-family:'open sans' , 'salesforce sans' , sans-serif;text-align:center">
                                    Last updated: November 17, 2022</h2>
                    </div>
                </section>
                <section class="otnotice-section" #otnoticeIntroduction>
                    <h2 class="otnotice-section-header">I. Introduction and Overview</h2>
                    <div class="otnotice-section-content">
                        <p open=""
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );color:rgb( 114 , 114 , 114 )">
                            <br><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )"><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px">Thank
                                    you for using the Cherry Valley Services! We are committed to providing you the
                                    best online shopping and delivery experience possible. This Privacy Policy
                                    explains what information Maplebear Inc. d/b/a Cherry Valley <span
                                        style="font-family:'open sans' , sans-serif;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap">(“Cherry Valley”,
                                        “we”, or “us”)</span> collects, how that information is used, under what
                                    circumstances we share information, and the choices you can make about that
                                    information.&nbsp;</span></span><span
                                style="color:rgb( 114 , 114 , 114 )"><br></span>
                        </p>
                        <p open=""
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );color:rgb( 114 , 114 , 114 )">
                            <br>
                        </p>
                        <p open=""
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );color:rgb( 114 , 114 , 114 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )"><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px">This
                                    Privacy Policy applies whether you access the Cherry Valley Service</span><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px">s
                                    (as defined in our&nbsp;</span></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif"><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px;color:rgb(51, 51, 219)"><a
                                        class="fr-strong" href="/legalpage"
                                        style="background-color:transparent;color:rgb( 18 , 103 , 206 );text-decoration:none;font-family:'open sans' , 'salesforce sans' , sans-serif;font-weight:700"
                                        target="_blank" rel="nofollow noopener noreferrer"></a></span></span><span
                                style="color:rgb( 0 , 0 , 238 )"><a href="/legalpage"
                                    rel="nofollow"></a></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif"><span
                                    style="color:rgb( 0 , 0 , 238 )"><a href="/legalpage"
                                        rel="nofollow"></a></span><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px;color:rgb( 0 , 0 , 238 )"><a
                                        href="/legalpage" target="_blank"
                                        rel="nofollow noopener noreferrer"><strong>Terms of
                                            Service</strong></a></span><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px;color:rgb( 0 , 0 , 238 )">)</span><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px;color:rgb( 114 , 114 , 114 )">&nbsp;</span></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb(119, 96, 96)"><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px">through
                                    a browser, a mobile application, or any other method.</span></span><span
                                style="color:rgb( 114 , 114 , 114 )"><br></span>
                        </p>
                        <p open=""
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );color:rgb( 114 , 114 , 114 )">
                            <br>
                        </p>
                        <p open=""
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );color:rgb( 114 , 114 , 114 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )"><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px">"Personal
                                    Information" or "PI" is information about you that may be used to identify
                                    you (such as your name, phone number, or address). Personal Information may
                                    also include health or medical information that relates to (a) your past,
                                    present, or future physical or mental health or condition, (b) the provision
                                    of health care to you, or (c) your past, present, or future payment for the
                                    provision of health care (“Personal Health Information” or
                                    “PHI”).</span></span>
                        </p>
                        <h3 open=""
                            style="margin:0px;padding:0px;font-weight:600;font-size:1.2em;font-family:'open sans' , 'salesforce sans' , sans-serif;color:inherit;font-style:normal;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span style="color:rgb( 114 , 114 , 114 )"><br></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );font-size:18px">Additional
                                Disclosures</span>
                        </h3>
                        <p open=""
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );color:rgb( 114 , 114 , 114 )">
                            <span style="color:rgb( 114 , 114 , 114 )"><br></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">We
                                may provide different or additional disclosures relating to the processing of
                                Personal Information of individuals who are <span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px">residents
                                    of certain countries, regions or states. Listed below are additional
                                    disclosures that may be applicable to you</span>.</span><br><br>
                        </p>
                        <ul open=""
                            style="color:rgb( 114 , 114 , 114 );font-family:'revert';font-size:14px;font-style:normal;font-weight:400;background-color:rgb( 255 , 255 , 255 );letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px">
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif">If you
                                    are a California resident, please see the additional privacy disclosures
                                    below in<strong>&nbsp;</strong><a
                                    href="javascript:'" (click)="scroll(otnoticeCalifornia)"
                                        rel="nofollow"><strong><span style="color:rgb( 0 , 0 , 238 )">Disclosure
                                                For California Residents</span></strong></a><a
                                        href="https://www.CherryValley1.com/privacy#california" rel="nofollow">.</a></span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif">If you
                                    are a Nevada resident, please see the additional privacy disclosures below
                                    in<strong>&nbsp;</strong><a
                                        href="javascript:" (click)="scroll(otnoticeNevada)"
                                        rel="nofollow"><strong><span style="color:rgb( 0 , 0 , 238 )">Disclosure
                                                For Nevada Residents</span></strong></a>.</span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif">If you
                                    are a resident of Canada, please see the additional privacy disclosures
                                    in<strong>&nbsp;</strong><a
                                        href="javascript:" (click)="scroll(otnoticeCanada)"
                                        rel="nofollow"><strong><span style="color:rgb( 0 , 0 , 238 )">Disclosure
                                                For Canada Residents</span></strong></a>.</span><br><br>
                            </li>
                        </ul>
                    </div>
                </section>
                <section class="otnotice-section" #otnoticewecollect>
                    <h2 class="otnotice-section-header">II. Information We Collect</h2>
                    <div class="otnotice-section-content">
                        <h3
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <br>
                        </h3>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:18px"><strong
                                    style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">A</span></strong></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )"><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:18px"><strong
                                        style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif">.
                                        Information You Provide to Us or Allow Others to Provide to
                                        Us</strong></span></span>
                        </p>
                        <h4
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span style="color:rgb( 114 , 114 , 114 )"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif"><em
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif"><span
                                            style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px"><strong
                                                style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif"><em
                                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif">Account
                                                    Creation and Orders</em></strong></span></span></em></span>
                        </h4>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">At
                                various points in the Cherry Valley experience, you may provide us with information
                                about yourself. For example, when you create an ac<span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px">count
                                    through the Services, you provide us with personal information like your
                                    name, email address, and zip or postal code. You may also provide us with
                                    information about your taste preferences and who you shop for. And if you
                                    place an order through the Services, we collect information including your
                                    physical address, phone number, birth date, driver’s license expiration date
                                    (for alcohol orders, prescription deliveries, or orders that are
                                    age-restricted or exceed a certain dollar amount, where available), credit
                                    card information, vehicle license plate number(s) (for curbside pickup
                                    orders) and the details of your order.</span></span><span
                                style="color:rgb( 114 , 114 , 114 )"><br><br></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">If
                                you use the Services for prescription delivery (where available), the
                                third-party pharmacy processing your prescription will provide us with your
                                name, address, email address, birth date, phone number, the total amount being
                                charged to you for the prescription drug product(s), and sales tax information
                                for the prescription drug product(s) (if applicable). The third-party pharmacy
                                will also share whether or not the prescription drug product you have ordered
                                requires temperature control. If a prescription drug product delivered to you
                                through the Services has been recalled, the third-party pharmacy will share your
                                name, email address, the date of the prescription drug product order, and the
                                total amount to be refunded to you with Cherry Valley. The pharmacy will not
                                disclose to us the name, quantity, manufacturer or distributor of the
                                prescription drug you have ordered, or any other PHI about you, other than your
                                status as a patient of the pharmacy.</span><span
                                style="color:rgb( 114 , 114 , 114 )"><br><br></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">We
                                may also collect health information that you provide directly to us regarding an
                                experience with a retail partner, which may require us to contact that retailer,
                                or other retailer partners, for public health or safety reasons, or to
                                facilitate a refund. We do not share your identity with the retailers we contact
                                in such&nbsp;capacity, but may share the date, time and location of a
                                transaction, which may allow a retailer to independently identify
                                you.</span><span style="color:rgb( 114 , 114 , 114 )"><br><br></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">If
                                you log into the Services through a third-party service, both we and that
                                third-party may receive some information about you and your use of the Services.
                                For example, if you choose to log into the Services with your Faceboo<span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px">k&nbsp;<span
                                        style="font-family:'open sans' , sans-serif;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre-wrap">or
                                        Google</span></span> account, we may receive information from these
                                companies<span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px">,</span>
                                such as your name, e-mail address, public profile information, and information
                                about your contacts. We may also offer social sharing tools (such as a “Like”
                                button) that let you share actions on the Services with other websites and vice
                                versa. In those cases, the providers of those tools may receive information
                                about you when you use those tools. You should check the privacy policies of
                                these third-party services and your settings there for more
                                information.</span><span style="color:rgb( 114 , 114 , 114 )"><br><br></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )"><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px">Our
                                    partners may let us collect information about use of their sites/apps or
                                    share such information with us. For example, if you use an Cherry Valley button
                                    or widget on another site or app, we may receive information about your use
                                    of that button or widget and the third-party site/app. We also may collect
                                    information about you from partners with whom we work for advertising
                                    measurement, attribution and analytics; from partners who assist us in
                                    payment collection, preventing or addressing fraud, or to assist us in
                                    connection with claims or disputes; from publicly-available sources; and
                                    from law enforcement, public health and other governmental
                                    authorities.</span></span><span
                                style="color:rgb( 114 , 114 , 114 )"><br></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )"><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px"><br
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif"></span></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">If
                                you wish to invite your friends and contacts to use the Services, we will give
                                you the option of either manually submitting their contact information or, for
                                United States residents, importing it from your address books on other
                                third-party services. In both cases, we will store this information for the sole
                                purposes of allowing you to send your friends referral offers, for determining
                                whether your friends use the Services after a referral is sent, and, for United
                                States residents, to remind your friends of the referral sent on your behalf.<br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif"></span>
                        </p>
                        <h4
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span style="color:rgb( 114 , 114 , 114 )"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif"><em
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif"><span
                                            style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px"><strong
                                                style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif"><em
                                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif">Community
                                                    Affairs</em></strong></span></span></em></span>
                        </h4>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">Cherry Valley
                                is active in local communities as part of our mission to create a world where
                                everyone has access to the food they love and more time to enjoy it together.
                                You may provide us your information in connection with our community affairs
                                efforts.</span>
                        </p>
                        <h4
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span style="color:rgb( 114 , 114 , 114 )"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif"><em
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif"><strong
                                            style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif"><em
                                                style="font-family:'open sans' , 'salesforce sans' , sans-serif">Location
                                                Information</em></strong></span></em></span>
                        </h4>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">When
                                you use the Services, we may collect location data. For instance, if you allow
                                the Services to access location services through the permission system used by
                                your device's mobile operating system or browser, we may collect the precise
                                location of your device. We use your location information to facilitate the
                                prompt hand-off of pickup orders (where available), to assist you in finding
                                nearby stores for which pickup or delivery are available, for other similar
                                purposes and for analytics purposes. You can choose whether or not to enable the
                                location tracking feature through the settings on your device or browser, or
                                when prompted by the Cherry Valley mobile app. We may also infer your general
                                location information, for example by using your internet protocol (IP)
                                address.</span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span style="color:rgb( 114 , 114 , 114 )"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif"></span>
                        </p>
                        <h3
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:18px;color:rgb( 114 , 114 , 114 )"><strong
                                    style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif">B</span></strong></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )"><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:18px"><strong
                                        style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif">.
                                        Technical Information about Usage of the Services</strong></span></span>
                        </h3>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span style="color:rgb( 114 , 114 , 114 )"><br></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">When
                                you use the Services, or browse our sites, either through a browser or mobile
                                app, we automatically receive some technical information - device and usage
                                information - about the hardware and software that are being used.</span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span style="color:rgb( 114 , 114 , 114 )"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif"></span>
                        </p>
                        <h4
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span style="color:rgb( 114 , 114 , 114 )"><em
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif"><strong
                                            style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif"><em
                                                style="font-family:'open sans' , 'salesforce sans' , sans-serif">Cookies,
                                                Pixels, and Other Tracking
                                                Technologies</em></strong></span></em></span>
                        </h4>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">We,
                                our partners, advertisers, and third-party advertising networks use various
                                technologies to collect information, including but not limited to cookies,
                                pixels, scripts, SDKs and device identifiers. Cookies are small text files that
                                are sent by your computer when you access our services through a browser. We,
                                our partners, advertisers, and third-party advertising networks may use session
                                cookies (which expire when you close your browser), persistent cookies (which
                                only expire when you choose to clear them from your browser), pixels, scripts,
                                and other identifiers to collect information from your browser or device that
                                helps us do things such as understand how you use our Services and other
                                services; personalize your experience; measure, manage, and display advertising
                                on the Services or on other services; understand your usage of the Services and
                                other services in order to serve customized ads; and remember that you are
                                logged into the Services. Our partners, advertisers, and third-party advertising
                                networks may use these technologies to collect information about your online
                                activity over time and across different websites or online services. By using
                                your browser settings, you may block cookies or adjust settings for
                                notifications when a cookie is set.&nbsp;</span><span
                                style="color:rgb( 114 , 114 , 114 )"><br><br></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">We
                                work with third-party companies to help us understand the usage of the Services
                                and the performance of advertising, and these third parties may also deploy
                                cookies, pixels, or other identifiers on the Services or collect information
                                through our mobile applications. For example, we use Google Analytics to
                                understand how users interact with various portions of the Services -- you
                                can</span><span style="color:rgb( 114 , 114 , 114 )">&nbsp;</span><a
                                href="https://www.google.com/policies/privacy/"
                                style="background-color:transparent;color:rgb( 18 , 103 , 206 );text-decoration:none;font-family:'open sans' , 'salesforce sans' , sans-serif;font-weight:700"
                                rel="nofollow"><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 0 , 0 , 238 )">learn
                                    more about information that Google may collect here.</span></a>
                        </p>
                        <h4
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span style="color:rgb( 114 , 114 , 114 )"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif"><em
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif"><strong
                                            style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif"><em
                                                style="font-family:'open sans' , 'salesforce sans' , sans-serif">Log
                                                Information</em></strong></span></em></span>
                        </h4>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">When
                                you use the Services, or browse our sites, our servers will record information
                                about your usage of the Services and information that is sent by your browser or
                                device. Log information can include things like the IP address of your device,
                                information about the browser, operating system and/or app you are using, unique
                                device identifiers, pages that you navigate to and links that you click,
                                searches that you run on the Services, and other ways you interact with the
                                Services. If you are logged into the Services, this information is stored with
                                your account information.</span>
                        </p>
                        <h4
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span style="color:rgb( 114 , 114 , 114 )"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif"></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )"><em
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif"><strong
                                        style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif"><em
                                            style="font-family:'open sans' , 'salesforce sans' , sans-serif">Interest-Based
                                            or Online Behavioral Advertising</em></strong></em></span>
                        </h4>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">Cherry Valley
                                may use third-party advertising companies to serve interest-based advertisements
                                to you. These companies compile information from various online sources
                                (including mobile-enabled browsers and applications) to match you with ads that
                                will be the most relevant, interesting, and timely for you. If you would like to
                                opt-out of interest-based advertising, please visit&nbsp;</span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif"><a
                                    href="http://optout.networkadvertising.org/#/"
                                    style="background-color:transparent;color:rgb( 18 , 103 , 206 );text-decoration:none;font-family:'open sans' , 'salesforce sans' , sans-serif;font-weight:700"
                                    rel="nofollow"><span
                                        style="color:rgb( 0 , 0 , 238 )">http://optout.networkadvertising.org/#/</span></a><span
                                    style="color:rgb( 0 , 0 , 238 )">.</span></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">&nbsp;Please
                                note that you will be opted out of all interest-based advertising from all
                                business members of the Network Advertising Initiative for that specific browser
                                on that specific device. If you opt-out, you may continue to see Cherry Valley’s or
                                our partners’ online advertisements; however, these ads will not be as relevant
                                to you.</span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span style="color:rgb( 114 , 114 , 114 )"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif"></span>
                        </p>
                        <h4
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )"><em
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif"><strong
                                        style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif">Do
                                        Not Track</strong></em></span>
                        </h4>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">Your
                                browser settings may allow you to automatically transmit a "Do Not Track" signal
                                to online services you visit. Cherry Valley does not respond to "Do Not Track"
                                signals. For more information, visit&nbsp;</span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif"><a
                                    href="//www.allaboutdnt.com" rel="nofollow"><strong><span
                                            style="color:rgb( 0 , 0 , 238 )">www.allaboutdnt.com</span></strong></a><span
                                    style="color:rgb( 0 , 0 , 238 )">.</span></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span style="color:rgb( 114 , 114 , 114 )"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif"></span>
                        </p>
                        <h3
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:18px;color:rgb( 114 , 114 , 114 )"><strong
                                    style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif">C</span></strong></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )"><strong
                                    style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:18px">.
                                        Children</span></strong></span>
                        </h3>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span style="color:rgb( 114 , 114 , 114 )"><br></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">Our
                                Services are not intended for children under 16 years of age, and we do not
                                knowingly collect Personal Information (as defined by the U.S. Children’s Online
                                Privacy Protection Act, or “COPPA”) in a manner not permitted by COPPA. If we
                                obtain actual knowledge that any information we</span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">&nbsp;collect
                                has been provided by a child under the age of 16, we will delete that
                                information to the extent required by applicable laws.</span><span
                                style="color:rgb( 114 , 114 , 114 )"><br><br></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">We
                                do not knowingly “sell,” as that term is defined under the California Consumer
                                Protect Act (“CCPA”), the Personal Information of minors under 16 years old who
                                are California residents.</span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <br>
                        </p>
                    </div>
                </section>
                <section class="otnotice-section" #otnoticeuserinfor>
                    <h2 class="otnotice-section-header">III. How We Use Your Information</h2>
                    <div class="otnotice-section-content">
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <br>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">We
                                use the information we collect for various purposes, including to:</span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <br>
                        </p>
                        <ul
                            style="color:rgb( 65 , 65 , 65 );font-family:'revert';font-size:14px;font-style:normal;font-weight:400;background-color:rgb( 255 , 255 , 255 );letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px">
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif">Provide
                                    and improve the quality of the Services, and develop new products and
                                    services</span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif">Allow
                                    your personal shopper (meaning those that shop for and/or deliver the order
                                    for you, including our retail partners and their employees/agents where
                                    applicable or our third-party providers) to choose your items at retailer
                                    sites, deliver your items to you, and/or call or text you with any updates
                                    or issues</span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif">Charge
                                    you for the purchase and delivery costs through one or more payment
                                    processing partners</span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif">Offer you
                                    customized content (including advertising, coupons, and promotions)</span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif">Understand
                                    how users interact with the Services (including advertising both on and off
                                    the Services) as a whole and to test new features or changes in our
                                    features</span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif">Provide
                                    customer service, respond to your communications and requests, and contact
                                    you about your use of the Services</span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif">Send you
                                    messages related to our community affairs efforts</span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif">Fulfill
                                    any other business or commercial purposes at your direction or with prior
                                    notice to you and your consent</span>
                            </li>
                        </ul>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">You
                                can opt-out of receiving promotional communications from Cherry Valley by using the
                                settings on the Account Info page or by using the unsubscribe mechanism included
                                in the message, where applicable.</span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <br>
                        </p>
                    </div>
                </section>
                <section class="otnotice-section" #otnoticewhatweshare>
                    <h2 class="otnotice-section-header">IV. What We Share</h2>
                    <div class="otnotice-section-content">
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <br>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">The
                                Services comprise a platform that presents you with a set of one or more
                                retailer virtual storefronts from which you can select goods for picking,
                                packing, and delivery by individual Personal Shopper(s) to your location or, if
                                available, for you to pick up in-store. In order to make this work, we need to
                                share information about you and your order with the other parties who help
                                enable the service. This includes, for example, the Personal Shopper(s) who pick
                                and deliver your order, the payment processing partner(s) that we use to
                                validate and charge your credit card, and the retail partner(s) from which you
                                are purchasing goods. To be clear, only our payment processing partner(s)
                                receive credit card information.</span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <br><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">We
                                also share information under the following principles:</span><br><br>
                        </p>
                        <ul>
                            <li id="isPasted"
                                style="margin:0in;font-size:16px;font-family:'calibri' , sans-serif;color:#727272;background:white">
                                <em><span style="font-size:14px;font-family:'open sans' , sans-serif">With your
                                        consent or at your direction</span></em><span
                                    style="font-size:14px;font-family:'open sans' , sans-serif">— We will share
                                    your information with entities outside of the Services when we have your
                                    consent to do so or it is done at your direction. For example:</span>
                                <ul style="margin-bottom:0in" type="circle">
                                    <li
                                        style="margin:0in;font-size:16px;font-family:'calibri' , sans-serif;color:rgb( 114 , 114 , 114 );background:white;text-align:justify">
                                        <span style="font-size:14px;font-family:'open sans' , sans-serif">If you
                                            enter loyalty card information from a particular retailer, we share
                                            that information with the retailer you chose along with your order
                                            so that information can be added to your loyalty card
                                            account.</span>
                                    </li>
                                    <li
                                        style="margin:0in;font-size:16px;font-family:'calibri' , sans-serif;color:rgb( 114 , 114 , 114 );background:white;text-align:justify">
                                        <span style="font-size:14px;font-family:'open sans' , sans-serif">If you
                                            share a recipe publicly on the Services, it is viewable by anyone
                                            along with your first name and last initial.</span>
                                    </li>
                                    <li
                                        style="margin:0in;font-size:16px;font-family:'calibri' , sans-serif;color:rgb( 114 , 114 , 114 );background:white;text-align:justify">
                                        <span style="font-size:14px;font-family:'open sans' , sans-serif">If you
                                            invite friends to use the Services through our referral program or
                                            to share a shopping cart, we will share some information with the
                                            friends you invite like your name and picture. Likewise, if you
                                            choose to join someone else’s cart, they will see some of your
                                            information.</span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <ul
                            style="color:rgb( 65 , 65 , 65 );font-family:'revert';font-size:14px;font-style:normal;font-weight:400;background-color:rgb( 255 , 255 , 255 );letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px">
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif"><em
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif">With
                                        our retail partners</em> — We may share your information with our retail
                                    partners in order to provide and maintain the Services.</span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify">
                                <span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif"><em
                                            style="font-family:'open sans' , 'salesforce sans' , sans-serif">For
                                            external processing or service provision</em> — We sometimes share
                                        information with third parties to process information on our behalf or
                                        to otherwise provide certain services (such as delivery services,
                                        advertising services, or information to better tailor our services to
                                        you). For the purposes of this processing or provision of services, we
                                        may share your information with such third parties under appropriate
                                        confidentiality provisions.</span></span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify">
                                <span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif"><em
                                            style="font-family:'open sans' , 'salesforce sans' , sans-serif">For
                                            legal purposes&nbsp;</em>— We may share your information when we
                                        believe that the disclosure is reasonably necessary to (a) comply with
                                        applicable laws, regulations, legal process, or requests from law
                                        enforcement or regulatory authorities, (b) prevent, detect, or otherwise
                                        handle fraud, security, or technical issues, and (c) protect the safety,
                                        rights, or property of any person, the public, or
                                        Cherry Valley.</span></span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify">
                                <span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif"><em
                                            style="font-family:'open sans' , 'salesforce sans' , sans-serif">On
                                            a non-personal or aggregate basis</em> — We share information on
                                        both a non-personally identifying basis (including, but not limited to,
                                        order and delivery details but not including credit card information) or
                                        an aggregate basis.</span></span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify">
                                <span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif"><em
                                            style="font-family:'open sans' , 'salesforce sans' , sans-serif">To
                                            enable purchase of alcohol (not available in all jurisdictions)</em>
                                        — When you buy alcohol using the Services, we may be required by law to
                                        share certain information with the retailer who makes the sale. This
                                        information could include, among other things, the names and addresses
                                        of the purchaser and recipient, government issued ID information, the
                                        quantity, brand, price, proof, and volume of alcohol purchased, and a
                                        recipient signature.</span></span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify">
                                <span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif"><em
                                            style="font-family:'open sans' , 'salesforce sans' , sans-serif">For
                                            business purposes</em> - We may share you information in connection
                                        with, or during negotiations of any merger, sale of company assets,
                                        financing, restructuring (including transfers made as a part of
                                        insolvency or bankruptcy proceedings) or acquisition of all or a portion
                                        of our business by another company. We may also share your information
                                        between and among Cherry Valley, and its current and future parents,
                                        affiliates, subsidiaries, and other companies under common control and
                                        ownership.</span></span><br><br>
                            </li>
                        </ul>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-d9637872-f5e6-43df-b862-3dc9ff2ccb39">
                    <h2 class="otnotice-section-header">V. Personal Health Information</h2>
                    <div class="otnotice-section-content">
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <br>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px"><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">This
                                    Section (Personal Health Information) governs our use and disclosure of your
                                    Personal Health Information. If there is a conflict between the terms of
                                    this Section and any other terms of this Privacy Policy or the Cherry Valley
                                    Terms of Services, the terms in this Section will govern. To the extent we
                                    receive, create, maintain, use or disclose any of your PHI, we will maintain
                                    the privacy and security of such information as required by the federal
                                    patient privacy law known as the Health Insurance Portability and
                                    Accountability Act and the regulations promulgated under that Act ("HIPAA"),
                                    as well as any applicable state and other federal privacy policy
                                    laws.</span></span><span style="color:rgb( 114 , 114 , 114 )"><br><br></span>
                        </p>
                        <p><span style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px"><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">Your
                                    PHI is protected under HIPAA and under certain state laws. Those laws give
                                    you rights with respect to the access, use, and disclosure of PHI by your
                                    health care providers, such as pharmacies, and us. When you place a pharmacy
                                    order using our Services, the pharmacy responds as we have described above
                                    under the Section entitled&nbsp;</span><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">"</span></span><span
                                style="color:rgb( 114 , 114 , 114 )"><a
                                    href="#otnotice-section-f9c8fcb6-d534-414c-aae2-f265c2fedf38"
                                    rel="nofollow"></a></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px"><span
                                    style="color:rgb( 114 , 114 , 114 )"><a
                                        href="#otnotice-section-f9c8fcb6-d534-414c-aae2-f265c2fedf38"
                                        rel="nofollow"></a>Information we collect</span><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">"</span><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )">&nbsp;by
                                    disclosing to Cherry Valley your status as a patient of the pharmacy.
                                    Information concerning your status as a patient of the pharmacy is PHI and
                                    protected by HIPAA. As discussed above, no other PHI will be disclosed to us
                                    by your pharmacy and no other PHI will be disclosed by Cherry Valley to your
                                    personal shopper other than your status as a patient of the pharmacy. For a
                                    more complete description of your rights under HIPAA and the uses and
                                    disclosures of your PHI, please refer to your pharmacy's Notice of Privacy
                                    Practices. We will not disclose your PHI without your prior written consent
                                    with other people or non-affiliated companies unless: (i) it is needed to
                                    provide our Services, (ii) it has been "de-identified" so that it cannot
                                    identify you, (ii) we have your prior written consent, (iv) disclosure is
                                    required by law, or (v) we are acquired or file for
                                    bankruptcy.</span></span></p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <br>
                        </p>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-936773a7-aa72-482e-b389-93ccd7f9528a">
                    <h2 class="otnotice-section-header">VI. Security</h2>
                    <div class="otnotice-section-content">
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <br>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )"><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px">We
                                    employ and maintain reasonable administrative, physical, and technical
                                    measures designed to safeguard and protect information under our control
                                    from unauthorized access, use, and disclosure. In addition, when we collect,
                                    maintain, access, use, or disclose your PHI, we will do so using systems and
                                    processes consistent with information privacy and security requirements
                                    under applicable federal and state laws, including, without limitation,
                                    HIPAA. All electronic PHI will be encrypted at rest and in transit.
                                    Nevertheless, transmission via the internet is not completely secure and we
                                    cannot guarantee the security of information about you.</span></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span style="color:rgb( 114 , 114 , 114 )"><br></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )"><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px">We
                                    will make any legally required notifications of any breach of the security,
                                    confidentiality, or integrity of your PHI or PI, including, without
                                    limitation, breaches of your stored PHI or PI (as breaches are defined under
                                    applicable state or federal statutes on security breach notification). To
                                    the extent permitted by applicable laws, we will make such notifications to
                                    you without unreasonable delay, as consistent with (i) the legitimate needs
                                    of law enforcement or (ii) any measures necessary to determine the scope of
                                    the breach and restore the reasonable integrity of the data
                                    system.</span></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <br>
                        </p>
                    </div>
                </section>
                <section class="otnotice-section" #otnoticeyourchoices>
                    <h2 class="otnotice-section-header">VII. Your Choices</h2>
                    <div class="otnotice-section-content">
                        <p open=""
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );color:rgb( 114 , 114 , 114 );">
                            <br>
                        </p>
                        <p open=""
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );color:rgb( 114 , 114 , 114 );">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );">We
                                provide you with the right to access or request the deletion of your Personal
                                Information. You may also correct or update your account information.</span>
                            <span
                                style="color:rgb( 114 , 114 , 114 );"><strong><em><strong><em><br></em></strong></em></strong></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );"><br></span>
                        </p>
                        <h3 style="color:rgb(114, 114, 114);"><em><strong>Correcting or Updating your Account
                                    Information<br></strong></em></h3>
                        <p>You may update your name, telephone number, address and payment information by
                            logging into your Cherry Valley account and visiting the “Account Settings” page. You
                            may also view existing and past orders.&nbsp;</p>
                        <p><br></p>
                        <p><br></p>
                        <h3 style="color:rgb(114,114,114);"><em><strong>Requesting Access to or
                                    Deletion&nbsp;</strong></em><em><strong>of your Personal
                                    Information</strong></em></h3>
                        <p>You may request access to the Personal Information we collected in the past twelve
                            (12) months and you may request the deletion of your Personal Information. Note that
                            access and deletion requests are subject to certain exceptions, including to protect
                            the security of the information and of your account.</p>
                        <ul open=""
                            style="color:rgb( 114 , 114 , 114 );font-family:'revert';font-size:14px;font-style:normal;font-weight:400;background-color:rgb( 255 , 255 , 255 );letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;">
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify;">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif;"><em><strong>Account
                                            Holders</strong></em>. If you are an Cherry Valley account holder,
                                    click<strong>&nbsp;</strong></span><a
                                    href="/AccountPage"
                                    rel="nofollow noopener noreferrer" target="_blank"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;"><strong><span
                                                style="color:rgb( 0 , 0 , 238 );">here</span></strong></span></a><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;">&nbsp;to
                                    exercise these choices. For verification purposes, you will be prompted to
                                    re-log into the Services<a href="https://www.CherryValley1.com/privacy#california"
                                        rel="nofollow"
                                        style="background-color:transparent;color:rgb( 58 , 113 , 136 );text-decoration:none;font-family:'open sans' , 'salesforce sans' , sans-serif;font-weight:700;">.</a></span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify;">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif;"><em><strong>Non-Account
                                            Holders</strong></em>. If you do not have an Cherry Valley account or
                                    are unable to log into the Services, you may contact us
                                    at<strong>&nbsp;</strong></span><a href="mailto:customerprivacy@CherryValley.com"
                                    rel="nofollow"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;"><strong><span
                                                style="color:rgb( 0 , 0 , 238 );">customerprivacy@CherryValley.com</span></strong></span></a><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;">.</span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify;">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif;"><em><strong>Authorized
                                            Agents</strong></em>. If you have designated an authorized agent to
                                    submit requests on your behalf, the authorized agent must submit requests
                                    at<strong>&nbsp;</strong></span><a href="mailto:customerprivacy@CherryValley.com"
                                    rel="nofollow"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;"><strong><span
                                                style="color:rgb( 0 , 0 , 238 );">customerprivacy@CherryValley.com</span></strong></span></a><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;">. Before
                                    processing a request from an authorized agent, we require written proof of
                                    the authorization you have provided the agent and will also verify your
                                    identity directly.</span><br><br>
                            </li>
                        </ul>
                    </div>
                </section>
                <section class="otnotice-section" id="otnotice-section-a9657477-11ad-4579-a91e-114364923a98">
                    <h2 class="otnotice-section-header">VIII. Changes to this Policy</h2>
                    <div class="otnotice-section-content">
                        <p style="text-align:justify"><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );color:rgb( 114 , 114 , 114 )">Cherry Valley
                                may occasionally update this Privacy Policy. If we make changes, we will notify
                                you by revising the date at the top of this Privacy Policy and, in the case of
                                material changes to the Privacy Policy, we may provide you with additional
                                notice (such as a notice in our user interface or sending you a notification by
                                email). Use of the Cherry Valley Services following an update to this Privacy Policy
                                constitutes consent to the updated Privacy Policy.</span></p>
                        <p><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );color:rgb( 40 , 50 , 78 )"><br></span>
                        </p>
                    </div>
                </section>
                <section class="otnotice-section" #otnoticeCalifornia>
                    <h2 class="otnotice-section-header">IX. Disclosures for Residents of California</h2>
                    <div class="otnotice-section-content">
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <br>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );">Only
                                to individuals who reside in California. The California Consumer Privacy Act of
                                2018 (“CCPA”) provides California residents certain additional notice
                                rights.</span><span style="color:rgb( 114 , 114 , 114 );">&nbsp;</span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;"></span>
                        </p>
                        <h3
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );font-size:18px;"><strong
                                    style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif;">A.
                                    Notice of Collection</strong></span>
                        </h3>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;"></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );">Although
                                the information we collect is described in greater detail in&nbsp;</span> <a
                                href="javascript:;" (click)="scroll(otnoticewecollect)" rel="nofollow"></a><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;"><a
                                href="javascript:;" (click)="scroll(otnoticewecollect)" rel="nofollow"><span
                                        style="color:rgb( 0 , 0 , 238 );"><strong>Section
                                            II</strong></span></a></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );">&nbsp;above,
                                the categories of Personal Information that we have collected – as described by
                                the CCPA – including in the past 12 months are:</span><br><br>
                        </p>
                        <ul
                            style="color:rgb( 65 , 65 , 65 );font-family:'revert';font-size:14px;font-style:normal;font-weight:400;background-color:rgb( 255 , 255 , 255 );letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;">
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify;">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif;"><u
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;">Identifiers</u>
                                    - including name, email address, and IP address.</span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify;">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif;"><u
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;">Other
                                        individual customer records</u> - including phone number , billing and
                                    shipping address, and credit or debit card information. This category
                                    includes Personal Information protected under pre-existing California law
                                    (Cal. Civ. Code 1798.80(e)), and overlaps with other categories listed
                                    here</span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify;">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif;"><u
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;">Demographics</u>
                                    - including your age. This category includes data that may qualify as
                                    protected classifications under other California or federal laws.</span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify;">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif;"><u
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;">Commercial
                                        information</u> - including purchases and engagement with our
                                    Services.</span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify;">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif;"><u
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;">Internet
                                        activity</u> - including your interactions with our Service.</span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify;">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif;"><u
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;">Geolocation
                                        data</u> - including location enabled services such as WiFi and
                                    GPS.</span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify;">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif;"><u
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;">S</u><u
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;">ensory
                                        Information</u> - such as recordings of phone calls between you and us
                                    and surveillance video at our properties, where permitted by law.</span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify;">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif;"><u
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;">Inferences</u>
                                    - including information about your interests, preferences and
                                    favorites.</span>
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify;">
                                <span style="font-family:'open sans' , 'salesforce sans' , sans-serif;"><u
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;">Health
                                        Information</u> - including any information you provide to us regarding
                                    an experience with a retailer that may require us to contact that retailer
                                    or other retailer partners for public health or safety reasons, or to
                                    facilitate a refund. We do not share your identity with retailers we may
                                    contact in such a capacity, but do share date, time and location of a
                                    transaction, which may allow a retailer to independently identify
                                    you.</span>
                            </li>
                        </ul>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );">For
                                more information on our collection practices, including the sources we receive
                                information from, please review “</span> <a  
                                href="javascript:;" (click)="scroll(otnoticewecollect)" rel="nofollow"></a><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;"><a
                                href="javascript:;" (click)="scroll(otnoticewecollect)" rel="nofollow"><strong
                                        style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif;"><span
                                            style="color:rgb( 0 , 0 , 238 );">Information We
                                            Collect</span></strong></a></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );">”
                                (Section II above). We collect and use these categories of <span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;">Personal
                                    Information</span> for the business purposes described in “</span> <a
                                    href="javascript:;" (click)="scroll(otnoticeuserinfor)" rel="nofollow"></a><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;"><a
                                href="javascript:;" (click)="scroll(otnoticeuserinfor)" rel="nofollow"><strong
                                        style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif;"><span
                                            style="color:rgb( 0 , 0 , 238 );">How We Use Your
                                            Information</span></strong></a></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );">”
                                (Section III above), including to provide and manage our Services.</span><span
                                style="color:rgb( 114 , 114 , 114 );"><br><br></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );">We
                                disclose the following categories of <span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;">Personal
                                    Information</span> to third parties for our commercial purposes:
                                identifiers, demographic information, commercial information, relevant order
                                information, internet activity, geolocation data, sensory information, and
                                inferences. We partner with different types of entities to assist with our daily
                                operations and manage our Services. Please review “</span> <a
                                href="javascript:;" (click)="scroll(otnoticewhatweshare)" rel="nofollow"></a><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;"><a
                                href="javascript:;" (click)="scroll(otnoticewhatweshare)" rel="nofollow"><strong
                                        style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif;"><span
                                            style="color:rgb( 0 , 0 , 238 );">What We
                                            Share</span></strong></a></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );">”
                                (Section IV above) for more detail about the third parties we have shared
                                information with and the underlying principles that guide our sharing
                                practices.</span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;"></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );"><strong
                                    style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif;"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:18px;">B.
                                        Right to Know and Delete</span></strong></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;"></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );">California residents have the right to
                                delete the personal information we have collected from you, and the right to
                                know certain information about our data practices in the preceding twelve (12)
                                months. In particular, you have the right to request the following from
                                us:</span><br><br>
                        </p>
                        <ul
                            style="color:rgb( 65 , 65 , 65 );font-family:'revert';font-size:14px;font-style:normal;font-weight:400;background-color:rgb( 255 , 255 , 255 );letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;">
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify;">
                                The categories of personal information we have collected about you;</li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify;">
                                The categories of sources from which the personal information was collected;
                            </li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify;">
                                The categories of personal information about you we disclosed for a business
                                purpose or sold;</li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify;">
                                The categories of third parties to whom the personal information was disclosed
                                for a business purpose or sold;</li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify;">
                                The business or commercial purpose for collecting or selling the personal
                                information; and</li>
                            <li
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );text-align:justify;">
                                The specific pieces of personal information we have collected about you.</li>
                        </ul>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );">To request access to or deletion of your
                                information, please see Section VII (</span> <a 
                                href="javascript:;" (click)="scroll(otnoticeyourchoices)" rel="nofollow"></a><a
                                href="javascript:;" (click)="scroll(otnoticeyourchoices)" rel="nofollow"><span
                                    style="color:rgb( 0 , 0 , 238 );"><strong>Your
                                        Choices</strong></span></a><span style="color:rgb( 114 , 114 , 114 );">)
                                above.</span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;"></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:18px;color:rgb( 114 , 114 , 114 );"><strong
                                    style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif;">C.
                                    Right to Opt-Out</strong></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;"></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );">We do not generally sell information as
                                the term “sell” is traditionally understood. However, if and to the extent
                                “sale” under the CCPA is interpreted to include advertising technology
                                activities such as those implemented specifically for interest-based
                                advertising, we will comply with applicable law as to such activity.</span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;"></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:18px;color:rgb( 114 , 114 , 114 );"><strong
                                    style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif;"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;">D</span></strong></span><span
                                style="color:rgb( 114 , 114 , 114 );"><strong
                                    style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif;"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:18px;"><strong
                                            style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif;">.&nbsp;</strong>Right
                                        to Non-Discrimination</span></strong></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;"></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );">You have the right not to receive
                                discriminatory treatment by us for the exercise of any your rights.</span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;"></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:18px;color:rgb( 114 , 114 , 114 );"><strong
                                    style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif;"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;">E</span></strong></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );"><strong
                                    style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif;"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:18px;">.
                                        Financial Incentives</span></strong></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;"></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );">Financial incentives are programs,
                                benefits, or other offerings, including payments to consumers as compensation,
                                for the disclosure, deletion, or sale of personal information about them. We
                                offer a number of promotions and other incentives at any given time, each with
                                their own individual terms. For a list and more details about our current
                                promotions and other incentives, please visit our “Terms of Promos and Credits”
                                page which lists each promotion and links its respective terms here. Your
                                intentional participation in any of the programs, benefits, or other offerings
                                under this Section will be interpreted as affirmative consent to the terms of
                                such incentive.<br><br></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );">For example, we offer a referral rewards
                                program to our users who recommend our services to their contacts as prospective
                                customers, when those prospective customers sign up for, and make a purchase
                                using our Services. We generally do not treat consumers differently if they
                                exercise a right under California law. However, in certain circumstances,
                                discounted prices or rewards will require you to be part of the particular
                                program. In such circumstances, we may offer a price difference because the
                                price is reasonably related to the value of your data.</span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;"></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );"><strong
                                    style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif;"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:18px;">F</span></strong></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:18px;color:rgb( 114 , 114 , 114 );"><strong
                                    style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif;"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;">.
                                        Shine the Light</span></strong></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;"></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );">If you are a California resident, you
                                may ask Cherry Valley for a notice describing what categories of personal
                                information Cherry Valley shares with third parties or affiliates for those third
                                parties or affiliates’ direct marketing purposes and identify the name and
                                address of the third parties that received such personal information. Please
                                submit a written request to the address provided below and specify you want a
                                copy of your California Shine the Light Notice. We may require additional
                                information from you to allow us to verify your identity and are only required
                                to respond to requests once during any calendar year.</span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;"></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 );"><strong
                                    style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif;"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:18px;">G</span></strong></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:18px;color:rgb( 114 , 114 , 114 );"><strong
                                    style="font-weight:700;font-family:'open sans' , 'salesforce sans' , sans-serif;"><span
                                        style="font-family:'open sans' , 'salesforce sans' , sans-serif;">.
                                        Consumer Affairs</span></strong></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );"><br
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;"></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <span style="color:rgb( 114 , 114 , 114 );">Under California Civil Code Section
                                1789.3, California residents are entitled to the following specific consumer
                                rights notice: If you have a question or complaint regarding our website, please
                                send an email to&nbsp;</span><a href="mailto:legal@CherryValley.com" rel="nofollow"
                                style="background-color:transparent;color:rgb( 18 , 103 , 206 );text-decoration:none;font-family:'open sans' , 'salesforce sans' , sans-serif;font-weight:700;"><span
                                    style="color:rgb( 0 , 0 , 238 );">legal@CherryValley.com</span></a><span
                                style="color:rgb( 114 , 114 , 114 );">. You may also contact us by writing to us
                                at the address provided below under the Section entitled
                                <strong>“</strong></span><strong><a 
                                    href="javascript:;" (click)="scroll(otnoticecontactinfo)"  rel="nofollow"></a><a
                                    href="javascript:;" (click)="scroll(otnoticecontactinfo)"  rel="nofollow"><span
                                        style="color:rgb( 0 , 0 , 238 );">Contact
                                        Information</span></a></strong><span
                                style="color:rgb( 114 , 114 , 114 );"><strong>”</strong>. California residents
                                may reach the Complaint Assistance Unit of the Division of Consumer Services of
                                the California Department of Consumer Affairs may be contacted in writing at 155 Thomaston Ave, Waterbury, CT 06702
                                 or by telephone at <a
                                    href="tel:+12037545700" rel="nofollow">(203) 754-5700</a>.</span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );">
                            <br>
                        </p>
                    </div>
                </section>
                <section class="otnotice-section" #otnoticeNevada>
                    <h2 class="otnotice-section-header">X. Disclosure for Residents of Nevada</h2>
                    <div class="otnotice-section-content">
                        <p><br></p>
                        <p><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );display:inline !important;color:rgb( 114 , 114 , 114 )">We
                                do not sell Personal Information as defined under Nevada law.</span></p>
                        <p><br></p>
                    </div>
                </section>
                <section class="otnotice-section" #otnoticeCanada>
                    <h2 class="otnotice-section-header">XI. Disclosure for Residents of Canada</h2>
                    <div class="otnotice-section-content">
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <br>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )"><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px">Cherry Valley
                                    is located in the United States and some of its service providers (including
                                    affiliates acting in this capacity) are located in the United States or
                                    elsewhere outside of Canada. As a result, your Personal Information will be
                                    processed and stored outside of Canada for the purposes described in this
                                    Privacy Policy. While outside of Canada, Personal Information will be
                                    subject to applicable local laws, which may permit government and national
                                    security authorities in those jurisdictions to access your Personal
                                    Information in certain circumstances.</span></span><span
                                style="color:rgb( 114 , 114 , 114 )"><br><br></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:justify;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )"><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px">The
                                    file containing your Personal Information will be maintained on our servers
                                    (or those of our service providers) and will be accessible by our authorized
                                    employees, representatives and service providers who require access for the
                                    purposes described in this Privacy Policy. You may request access to or
                                    correction of your Personal Information, or withdraw consent to our
                                    collection, use or disclosure of your Personal Information, as explained in
                                    Section VII </span></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif"><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px;color:rgb( 0 , 0 , 238 )"><strong>(</strong></span></span><span
                                style="color:rgb( 0 , 0 , 238 )"><strong><a
                                    href="javascript:;" (click)="scroll(otnoticeyourchoices)"
                                        rel="nofollow"></a></strong></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 0 , 0 , 238 )"><strong><a
                                    href="javascript:;" (click)="scroll(otnoticeyourchoices)"
                                        rel="nofollow"></a></strong></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif"><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px;color:rgb( 0 , 0 , 238 )"><strong><a
                                        href="javascript:;" (click)="scroll(otnoticeyourchoices)"
                                            rel="nofollow">Your Choices</a>)</strong></span></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif"><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px;color:rgb( 0 , 0 , 238 )">&nbsp;</span></span><span
                                style="font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 114 , 114 , 114 )"><span
                                    style="font-family:'open sans' , 'salesforce sans' , sans-serif;font-size:14px">above.
                                    These rights are subject to applicable contractual and legal restrictions
                                    and reasonable notice. We may take reasonable steps to verify your identity
                                    before honoring any such requests.</span></span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <br>
                        </p>
                    </div>
                </section>
                <section class="otnotice-section" #otnoticecontactinfo>
                    <h2 class="otnotice-section-header">XII. Contact Information</h2>
                    <div class="otnotice-section-content">
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <br>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span style="color:rgb( 114 , 114 , 114 )">Questions or comments about our Privacy
                                Policy? Contact Cherry Valley’s Global Privacy Team at:</span><br><br>
                        </p>
                        <p
                            style="margin:0px 0px 0px 220px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:justify">
                            <span style="color:rgb( 114 , 114 , 114 )"><strong>Cherry Valley</strong></span>
                        </p>
                        <p
                            style="margin:0px 0px 0px 220px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:justify">
                            <span style="color:rgb( 114 , 114 , 114 )">155 Thomaston Ave. </span>
                        </p>
                        <p
                            style="margin:0px 0px 0px 220px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:justify">
                            <span style="color:rgb( 114 , 114 , 114 )">Waterbury, Connecticut 06702</span>
                        </p>
                        <p
                            style="margin:0px 0px 0px 220px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:justify">
                            <a href="mailto:privacy@CherryValley.com"
                                style="background-color:transparent;color:rgb( 18 , 103 , 206 );text-decoration:none;font-family:'open sans' , 'salesforce sans' , sans-serif;font-weight:700"
                                rel="nofollow"><span style="color:rgb( 0 , 0 , 238 )">privacy@CherryValley.com</span></a>
                        </p>
                        <p
                            style="margin:0px 0px 0px 220px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 );text-align:justify">
                            <span style="color:rgb( 114 , 114 , 114 )"><a href="tel:+12037545700"
                                    rel="nofollow">1-203-754-5700</a></span><br><br>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span style="color:rgb( 114 , 114 , 114 )">For customer service inquiries visit
                                our&nbsp;</span><a href="https://www.CherryValley1.com/help"
                                style="background-color:transparent;color:rgb( 18 , 103 , 206 );text-decoration:none;font-family:'open sans' , 'salesforce sans' , sans-serif;font-weight:700"
                                target="_blank" rel="nofollow noopener noreferrer"><span
                                    style="color:rgb( 0 , 0 , 238 )">Help Center</span></a><span
                                style="color:rgb( 0 , 0 , 238 )">.</span><br><br>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span style="color:rgb( 114 , 114 , 114 )">If you have a disability and would like
                                to access this Privacy Policy in an alternative format, please contact us at <a
                                    href="tel:+12037545700" rel="nofollow">(203) 754-5700</a>. Cherry Valley is
                                committed to making its electronic and information technologies accessible to
                                individuals with disabilities.</span>
                        </p>
                        <p
                            style="margin:0px;padding:0px;font-family:'open sans' , 'salesforce sans' , sans-serif;color:rgb( 65 , 65 , 65 );font-size:14px;font-style:normal;font-weight:400;letter-spacing:normal;text-align:left;text-indent:0px;text-transform:none;white-space:normal;word-spacing:0px;background-color:rgb( 255 , 255 , 255 )">
                            <span style="color:rgb( 114 , 114 , 114 )">If you have comments concerning this
                                website’s accessibility, please contact us at&nbsp;</span><a
                                href="mailto:accessibility-feedback@CherryValley.com"
                                style="background-color:transparent;color:rgb( 18 , 103 , 206 );text-decoration:none;font-family:'open sans' , 'salesforce sans' , sans-serif;font-weight:700"
                                rel="nofollow"><span
                                    style="color:rgb( 0 , 0 , 238 )">accessibility-feedback@CherryValley.com</span></a><span
                                style="color:rgb( 0 , 0 , 238 )">.</span>
                        </p>
                    </div>
                </section>
            </div>
       
        </div>
    </div>


</div>
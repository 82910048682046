import { Component, OnInit } from '@angular/core';
import { ApiProvider } from '../Services/api';
import { DataService } from '../Services/data.service';

@Component({
  selector: 'app-outgoing-order',
  templateUrl: './outgoing-order.component.html',
  styleUrls: ['./outgoing-order.component.css']
})
export class OutgoingOrderComponent implements OnInit {
  orders: any = [];
 
  public status: string[] = ["Delivered", "PickUp Ready","Processed"];

  constructor(public api: ApiProvider, private dataService: DataService) { }

  ngOnInit(): void {
    this.GetItems();
    this.dataService.currentData.subscribe(data => {
      if (data.route == "OUTGOING") {
        this.GetItems();
      }
    });
  }
  eliminate(Order: any) {

    var stat = {
      IDTR: Order,
      STATUS: '0',
      STATUSPROCC: 'Processed'
    }

    this.api.post(this.api.endpoint + '/Orders', stat).subscribe(data => {
      this.GetItems();
    });
  }

  GetItems() {
    this.api.get(this.api.endpoint + '/Orders/Getprocessedorderstore/' + this.api.userprofile.IDCLOUD).subscribe(data => {
  //   console.log(data);
      if (data.toString().length > 5) {
        this.orders = data;

      }

    }, error => {
      console.log("error");

    });
  }
  changestatsel(order: string,status: string) {
   
    let statcode=1;
    if(status=='Processed')
    {
      statcode=0;
    }
   
      //actualizar status
      var stat = {
        IDTR: order,
        STATUS: statcode,
        STATUSPROCC: status
      }

      this.api.post(this.api.endpoint + '/Orders', stat).subscribe();
      if(status=='Processed')
      {
        this.GetItems();
      }
  
  }

}

<!-- Header-->
<div class="breadcrumb mb-0 p-0">
    <div class="row">
        <div class="col-sm-11">
            <div style="display:flex ;">
                <h2> <a>
                        <mat-icon>local_shipping</mat-icon>
                    </a> Products <mat-icon>fast_forward</mat-icon></h2>
                <h1 style="font-weight: bold;margin-left: 20px;color:rgb(37, 163, 37)">
                    {{api.getDepatName(depsel)}}</h1>
                
            </div>

        </div>
        <div class="col-sm-1">
            <a href="javascript:;" (click)="api.CloseW()"> <img src="assets/images/Close.png" style="width: 40px; "></a>
        </div>
    </div>
</div>
<!--end Header-->

<div class="panel-box" style="height:auto;width: 96%;">
    <div class="row">
        <div class="col-sm-3">
            <button mat-button [matMenuTriggerFor]="menu"><mat-icon>menu</mat-icon>Store Departments</button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item *ngFor="let dep of deparments"
                    (click)="selectdep(dep.ID)">{{dep.DESCRIPTION}}</button>
            </mat-menu>
          
        </div>
        <div class="col-sm-4" >
            <mat-form-field class="full-width" appearance="outline" >
                <mat-label>Sub-Deparments</mat-label>
                <mat-select [(ngModel)]="this.subdepsel" (selectionChange)="selecsubdep()" >
                    <mat-option *ngFor="let ct1 of subdeparments" [value]="ct1.SID">
                        {{ct1.DESCRIPTION | titlecase}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-sm-5">
            <mat-select class="search" placeholder="Search......." #singleSelect>
                <mat-option>
                    <ngx-mat-select-search [formControl]="SearchCtrlFilter">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option style="font-size: 12px;" *ngFor="let resul of results " (click)="Selected(resul.ID)">
                    {{resul.DT}}
                </mat-option>
            </mat-select>
            <mat-icon style="margin-left:20px">search</mat-icon>

        </div>
    </div>
    <br>

    <div class="card">
        <div class="card-body">
            <div style="height: 350px;background-color: rgb(228, 228, 228);">
                <div class="table-responsive">
                    <div class="loader" *ngIf="Showspinner"></div>
                    <table class="table table-striped" style="background-color:white;font-size: 12px;">
                        <thead>
                            <tr style="background-color:rgb(37, 61, 88);color: darkorange;">
                                <th scope="col">UPC</th>
                                <th scope="col">IMAGEN</th>
                                <th scope="col">DESCRIPTION</th>
                                <th scope="col">#</th>
                                <th scope="col">PRICE</th>
                                <th scope="col">Edit/Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of Items | paginate: config">
                                <th scope="row">{{item.UPC}}</th>
                                <td> <img class="image" [src]="getImg(item)" alt="" default="assets/images/icon1.png" (error)="loadDefault($event)"></td>
                                <td> {{item.DESCRIPTION}}</td>
                                <td style="text-align:center;">{{item.NP_PRICE}}</td>
                                <td style="text-align:center;">{{item.PRICE}}</td>
                                <td>
                                    <a href="javascript:'" style="font-size: 20px;" (click)="edit(item)">
                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                    </a>
                                    <a href="javascript:'" style="font-size: 20px;margin-left: 20px;"
                                        (click)="delete(item.ID)">
                                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                   
                </div>
            </div>

            <div style="margin-top:20px ;height: 40px;margin-left: 500px;">
                <button mat-flat-button color="accent" (click)="AddProduct()">
                    <mat-icon> person_pin</mat-icon>Add Product
                </button>
            </div>
            <br>
        </div>
    </div>
</div>
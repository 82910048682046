import { Component,  OnInit } from '@angular/core';
import { ApiProvider } from 'src/app/Services/api';
import { Location } from '@angular/common'
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-print-report',
  templateUrl: './print-report.component.html',
  styleUrls: ['./print-report.component.css']
})
export class PrintReportComponent implements OnInit {
  public page:any;
private parameters:any;
  private ini:number=1;
 
constructor(public api: ApiProvider,private activatedRoute: ActivatedRoute, 
  private location: Location ,private sanitizer: DomSanitizer) { 
  this.activatedRoute.params.subscribe(params => {
    this.parameters =params['DATA'];
    });
  }
  ngOnInit(): void {
     this.api.get(this.api.endpoint + '/Reports/Getreport/'+  this.parameters).subscribe((data:any) => {
      this.page=this.sanitizer.bypassSecurityTrustHtml(data);
      window.onafterprint = (event:any) => {
        //  console.log('After print');
        setTimeout(() => {
          this.location.back();
        }, 200);
      };
     
     }, error => {
      this.ini=3;
      this.location.back();
     });
     /*
    this.http.get('https://kissht.com/',{responseType:'text'}).subscribe(res=>{
      this.page = this.sanitizer.bypassSecurityTrustHtml(res);
    })
    */
   /* 
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/example'])
    );
    window.open(url);*/
   // window.open("http://localhost:52404/login" , '_blank');
   
    
   
    
  
  }
  ngAfterViewChecked() {
    setTimeout(() => {
      if (this.ini == 1) {

        window.print();
        this.ini += 1;
      }

    }, 400);

  }

}

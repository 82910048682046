<div style="background-color: rgb(0, 0, 0);;padding-left: 4%;">
    <h3 style="color:rgb(243, 155, 23)">REWARDS</h3>
</div>
<br>

<mat-card class="info">
    <mat-card-content style="margin-top: 5px;">
        <div class="form-row">
            <div class="col-sm-3">
                <div *ngIf="this.productdata.ISNEW">
                    <input class="form-control" style="width: 200px;" type="text" placeholder="UPC"
                        [(ngModel)]="this.productdata.UPC" (input)="handleInput($event)">
                </div>
                <div *ngIf="!this.productdata.ISNEW">
                    <label class="upclabel">{{this.productdata.UPC}}</label>
                </div>
                <div class="search" *ngIf="isShow">
                    <mat-selection-list class="search" [multiple]="false">
                        <mat-list-option style="font-size: 12px;" *ngFor="let resul of results "
                            (click)="Selected(resul.ID)">
                            {{resul.DT}}
                        </mat-list-option>
                    </mat-selection-list>

                </div>
            </div>
            <div class="col-sm-6">
                <input class="form-control" style="width: 300px;" type="text" placeholder="Description"
                    [(ngModel)]="this.productdata.DESCRIPTION">
            </div>

        </div>
        <div class="form-row">
            <div class="col-sm-8">
                <div style=" display: flex;">
                    <input class="form-control" style="width:100px ;" type="text" placeholder="Points"
                    [(ngModel)]="this.productdata.VALUEREW">
                <input class="form-control" style="width:100px ;" type="text" placeholder="Discount $"
                    [(ngModel)]="this.productdata.DSCTO">
                </div>
               
            </div>

            <div class="col-sm-4">
                <div style="position: absolute; height: 200px;width: 150px; border-color: rgb(194, 192, 192);border-width: thin; border-style: solid;margin-top: 10%;">
                    <img style="height: 100%; width: 100%; object-fit: contain" src="{{image}}"
                    (error)="loadDefault($event)" (click)="paste()">
                    <button mat-flat-button (click)="fileUpload.click()" style="margin-top:5%;background-color:gainsboro">
                        <mat-icon> attach_file</mat-icon>File
                      </button>
                      <div hidden>
                        <input type="file" class="file-input" [accept]="requiredFileType" (change)="onFileSelected($event)"
                          #fileUpload>
                      </div>
                </div>
                   
            </div>

        </div>
        <div class="form-row">
            <div class="col-sm-8">
                <mat-label class="label">Date</mat-label>
                <div class="container1">
                    <input class="form-control" style="width: 150px;" type="date" placeholder="Start"
                        [(ngModel)]="this.productdata.DTSTART">
                    <input class="form-control" style="width: 150px;" type="date" placeholder="End"
                        [(ngModel)]="this.productdata.DTEND">
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="col-sm-12">
                <mat-form-field appearance="fill" style="width: 350px;">
                    <mat-label>Notes</mat-label>
                    <textarea matInput  [(ngModel)]="this.productdata.NOTES"
                    matInput cdkTextareaAutosize cdkAutosizeMinRows="4" 
                                    cdkAutosizeMaxRows="4"
                                        maxlength="250" ></textarea>
                  </mat-form-field>
            </div>
        </div>
       
    </mat-card-content>
</mat-card>
<br>
<br>
<div style="text-align: center;">
    <button mat-raised-button color="accent" (click)="Updatedata()">
        <mat-icon>check</mat-icon>{{commnad}}
    </button>
    <button mat-raised-button color="light" style="margin-left: 20px;" (click)="Close()">
        <mat-icon>close</mat-icon>Close
    </button>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ApiProvider } from 'src/app/Services/api';

@Component({
  selector: 'app-creditcard',
  templateUrl: './creditcard.component.html',
  styleUrls: ['./creditcard.component.css']
})
export class CreditcardComponent implements OnInit {
  @ViewChild('form', { static: false }) slForm!: NgForm;
  payment_form!: FormGroup;
  public loader: boolean = false;
  private Subtotal: number = 0;
  private Tax: number = 0;
  private Delivery: number = 0;
  private Service: number = 0;
  private Saved: number = 0;
  private lastindex: number = 0;
  private isLoading: boolean = false;
  public leyends = [];
  public CC: any = {};
  public contact: string = "";
  private subscription!: Subscription;
  public instructions: string = "";
  timeLeft: number = 4;
  interval = 0;
  public month: string[] = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  public year: string[] = ["2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"];
  public HasCard: boolean = false;
  public group: any = [{ 'enable': true, 'color': 'light' }, { 'enable': false, 'color': 'light' }, { 'enable': false, 'color': 'light' }, { 'enable': false, 'color': 'light' }];
  userData = { "Account": "", "ExpirationMonth": "", "ExpirationYear": "", "CVV": "", "ZIP": "" };
  public expandable: any = false;

  constructor(public api: ApiProvider,public dialogRef: MatDialogRef<CreditcardComponent>) { }

  ngOnInit(): void {
 //   this.leyends = this.api.getLabel("CHK");
 //   console.log(this.leyends );
 this.setForm();
  }
  setForm() {
    this.payment_form = new FormGroup({
      card_number: new FormControl('', [Validators.required]),
      exp_month: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]),
      exp_year: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
      cvv: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
      zip: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(8)]),
    });
    if(this.api.istest){
    this.userData.Account='4761739001010119';
    this.userData.ExpirationMonth='12';
    this.userData.ExpirationYear ='2023';
    this.userData.CVV ='201';
    this.userData.ZIP = '11706';
    }
  }
  post() {
  
    if (this.HasCard) {
      this.userData.Account = this.CC.Account;
      this.userData.ExpirationMonth = this.CC.Expirationmonth;
      this.userData.ExpirationYear = this.CC.Expirationyear;
      this.userData.CVV = this.CC.CVV;
      this.userData.ZIP = this.CC.ZIPCODE;
    }
    this.loader = true;
 
      this.Datacap("").then((data: any) => {
        if (this.api.purchaseamount.toFixed(2) != Number(data["Amount"]).toFixed(2)) {
          this.Datacap(data["RefNo"]).then(datavoid => {
            console.log(datavoid);
            this.loader = false;
            this.api.presentToast("Payment not accepted. Please use another card", "Ok", 0);
          })

        }
        else {
          this.loader = false;
      //    console.log(data);
          this.dialogRef.close(data);
          // this.completepayment(data);
        }

      }).catch(error => {

        this.loader = false;
        if (error.error == undefined) this.api.presentToast("Payment Required", "Ok", 0);
        else
          this.api.presentToast(error.error.Message, "Ok", 0);
        return;
      });
   


  }
  Datacap(Href: string) {
    return new Promise((resolve, reject) => {
      console.log();
      this.api.postDatacapToken(this.api.compheader.TOKENURL, JSON.stringify(this.userData),
        '168c80851322414ca0dbf873165d682f').subscribe(data => {
             console.log(data);
          var d1 = JSON.parse(JSON.stringify(data));
          if (d1.Token != undefined) {
            var sale = {
              Amount: this.api.purchaseamount,
              Token: d1.Token
            }
         //   console.log(sale);
            var endpoint;
            if (Href.length > 0) endpoint = this.api.compheader.CCURL + '/credit/sale/' + Href + "/void";
            else
              endpoint = this.api.compheader.CCURL + '/credit/preauth';

            this.api.postDatacapSale(endpoint,
              JSON.stringify(sale)).subscribe((datasale: any) => {
                  console.log(datasale);
                var card;
                if (datasale["ReturnCode"] == "000000") {
                  card = {
                    Cash: 'No',
                    Brand: datasale["Brand"].replace("/", ""),
                    Amount: Number(datasale["Amount"]),
                    Account: String(datasale["Account"]).replace("X", ""),
                    AuthCode: datasale["AuthCode"],
                    Authorized: datasale["Authorized"],
                    Token:datasale["Token"],
                    RefNo: datasale["RefNo"],
                    Message: datasale["Status"],
                    Code: datasale["ReturnCode"]
                  }
                } else {
                  card = {
                    Cash: 'Yes',
                    Brand: "",
                    Amount: "",
                    Account: "",
                    AuthCode: "",
                    Authorized: "",
                    Token:"",
                    RefNo: "",
                    Message: datasale["Status"],
                    Code: datasale["ReturnCode"]
                  }
                }
            //    console.log(card);
                resolve(card);
              }, error => {
                reject(error);
              });
            ;
          }
        }, error => {

          reject(error);
        });
    });
  }

}

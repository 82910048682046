import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ApiProvider } from 'src/app/Services/api';
import { AddagencyComponent } from '../addagency/addagency.component';

@Component({
  selector: 'app-agencies',
  templateUrl: './agencies.component.html',
  styleUrls: ['./agencies.component.css']
})
export class AgenciesComponent implements OnInit {
  public agencies: any = [];
  public zone: any = [];
  constructor(public api: ApiProvider, public dialog: MatDialog,private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.loadagency();
  }

  loadagency() {

    this.api.get(this.api.endpoint + '/Agencies').subscribe(data => {
     //  console.log(data);
      var dat1 = data;
      this.agencies =null;
      if (dat1.toString().length > 10) {
        var data1: any = data;
        if(data1.AGENCIES.toString().length>0)  this.agencies = JSON.parse(data1.AGENCIES);
        if(data1.ZONES.toString().length>0) this.zone = JSON.parse(data1.ZONES);
      //  console.log(this.agencies);
      }
      else {


      }
    }, error => {

    });


  }
  setflag(it: string) {
    return this.api.toBoolean(it);
  }
  Addagency() {
    const dialogRef = this.dialog.open(AddagencyComponent, {
      data: {
        "AGENCY": "",
        "ZONES": this.zone
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == "1") this.loadagency();
    });
  }

  edit(item: any) {
    const dialogRef = this.dialog.open(AddagencyComponent, {panelClass: 'my-class',
         data: {
        "AGENCY": item,
        "ZONES": this.zone
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == "1") this.loadagency();


    });
  }
  delete(id: string) {
    
    this.api.ConfirmationDlg("This Procedure Delete records permanently, Are you sure you want to continue?",
    "WARNING....","Yes","No").then(result=>{
      if(result){

        this.api.delete(this.api.endpoint + '/agencies/' + id).subscribe(data => {
          this.loadagency();
         });

      }
    })
    
  }

  CloseW()
  {
    this.api.menusel=-10;
  }
  loadDefault(event: any) {
 
 //   (event.target as HTMLImageElement).style.display = 'none';
 //this.image= "assets/images/icon1.png";
  
   }
   getImgContent(item:any): SafeUrl {
   // console.log(imgFile);
   if(item.ISPHOTO=='False') return "assets/images/icon1.png";
   else
       return this.sanitizer.bypassSecurityTrustUrl( this.api.endointdata+'/data/Images/' + item.ID + '.jpg');
   }
 

}

import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})


export class ConnectService {
  public isopen: boolean = false;
  public Name: string = "";
  public ID: string = "";
  public setupinfo: any = {};
  constructor(public socket: Socket) {

  }

  public connectserver() {
    this.setupinfo.ACCOUNT = "CHERRYVALLEY";
    this.setupinfo.DEVICE = "";
    this.setupinfo.Socketid = "";
  

    
   // this.socket.ioSocket.io.uri = config.NODE;
    this.socket.ioSocket.io.uri = "https://www.cherryvalleymarketplace1.com:3000"
   //this.socket.ioSocket.io.uri = "http://127.0.0.1:3000"

    this.socket.connect();
  //  console.log(this.setupinfo);
    this.socket.emit('set-connection', this.setupinfo);
    this.isopen = true;
  };

  public Reconnect(Socketid: any) {
    if (this.isopen) return;
    console.log("reconect");
    this.socket.emit('reestablish', this.setupinfo);
    this.isopen = true;
  }

  sendMessage(data: any) {

    this.socket.emit('command', data);
  }

  getMessages() {
    let observable = new Observable(observer => {
      this.socket.on('cmd', (data: any) => {
        console.log(data);
        observer.next(data);
      });
    })
    return observable;
  };

 
  public isOpenConnection() {
    let observable = new Observable(observer => {
      this.socket.on('connect', function () {
        console.log('server connected');
        ;
        observer.next(true);
      });

    })
    return observable;
  }
  public isreconnection_attempt() {
    let observable = new Observable(observer => {
      this.socket.on('reconnect', (reason: any) => {
        console.log('attent reconnection server');
        observer.next(true);
      });

    })
    return observable;
  }
  public isdisconneted() {
    let observable = new Observable(observer => {
      this.socket.on('disconnect', (reason: any) => {
        console.log('disconnect');

        observer.next(true);
      });

    })
    return observable;
  }
  public close() {
    this.socket.disconnect();
    this.isopen = false;
    this.socket.ioSocket.io.uri = "";


  }
  ngOnDestroy() {

  }
}

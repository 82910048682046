<mat-toolbar>
    <span>Order: {{OrderID}}</span>
    <div style="margin-left: 75%;">
        <a href="javascript:;" (click)="Close()"> <img src="assets/images/Close.png" style="width: 40px; "></a>
    </div>
</mat-toolbar>
<mat-card>
    <mat-card-content style="margin-top: 5px;">
        <div style="height: 250px;overflow-y: scroll;">
            <mat-list role="list" *ngIf="ordersdetail.length>0">
                <mat-list-item *ngFor="let order of ordersdetail;let i=index">
                    <div class="row" style="width:100%">
                        <div class="col-sm-2">
                            <img class="image" [src]="getImgContent(order.UPC)" (error)="loadDefault($event)">
                        </div>
                        <div class="col-sm-5 ">
                            <div class="desc">{{order.DESCRIPTION}}</div>
                            <div class="size">{{order.SIZE}}</div>
                        </div>
                        <div class="col-sm-1">
                            <a href="javascript:;" class="qtysc" (click)="changeqty($event,i)">{{order.QTY}}</a>
                        </div>
                        <div class="col-sm-3">
                            <label class="pricesc">{{order.PRICE *
                                order.QTY|currency}}</label>
                        </div>
                        <div class="col-sm-1">
                            <button
                                style="background-color:red ;color: white;position: relative;right: -20px;margin-top:15px"
                                (click)="delete(i,order.ID)">X</button>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                </mat-list-item>
            </mat-list>
        </div>
        <div style="text-align: center;background-color: rgb(235, 232, 232);height: 50px;">
            <button mat-raised-button style="width: 250px;height: 30px;background-color: black; color:whitesmoke;
            font-size: 14px;margin-top: 10px;" (click)="Print()">
                <mat-icon>print</mat-icon>
                Print Order
            </button>
        </div>
    </mat-card-content>
</mat-card>

<div class="row" style="margin-top: 10px">

    <div class="col-sm-4">
        <mat-card style="height: 260px;">
            <mat-card-header>
                <h2>Shipping...</h2>
            </mat-card-header>
            <mat-card-content style="margin-top: 5px;">
                <span style=" white-space: pre-line;">{{info.SHIPPING}}<br>
                  <label style=" font-weight: bold;">Contact: {{info.CONTACT}}</label>  
                </span><br><br>
                <span style="color:rgb(250, 8, 8);
                  font-size: 16px; "> Special Instructions: </span>
                <div class="specInst">
                    {{info.SPECIALINSTR}}</div>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="col-sm-8">
  
        <mat-card>
            <mat-card-header>
                <h2>Payment Process...</h2>
            </mat-card-header>
            <mat-card-content style="margin-top: 15px;padding-left: 10px;">
                <div class="row ">
                    <div class="col-sm-12 priceSM">
                        <label>Date: </label>
                        <label>{{info.DATETR|date: 'MM/dd/yy'}} {{info.TIME}}</label><br>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-8 priceSM">
                        <label>Delivery:</label>
                        <label *ngIf="info.DELIVERY >0"
                            style="background-color: red;color:white;margin-left: 10px;">Yes</label>
                        <label *ngIf="info.DELIVERY ==0">Pickup</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 priceSM">
                        <label
                            style="font-weight: 600;font-size: 16px;margin-top: 20px;margin-left: 60%;">Status:</label>
                    </div>
                    <div class="col-sm-3">
                        <mat-form-field appearance="outline" style="margin-left: 0%;">
                            <mat-select [(ngModel)]="statsel" (selectionChange)="changestatsel()">
                                <mat-option *ngFor="let st of status;" [value]="st">
                                    {{st}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <label *ngIf="info.PAIDCLOSED=='True'" style="font-weight: 600;
                        font-size: 20px;color:red">[ PAID ]</label>
                    </div>
                </div>
                <div class="row" style="text-align: center;">
                    <div class="col-sm-6 priceSM">
                        <div class="row totalrow">
                            <div class="col-sm-5">
                                <label style="margin-top: 15px; font-size: 16px;">Total:</label>
                            </div>
                            <div class="col-sm-3">
                                <label
                                    style="margin-top: 15px;font-size: 16px;font-weight: 900;">{{info.TOTAL|currency}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="loader" *ngIf="loader"></div>
                        <button mat-raised-button [disabled]="nopay" color="primary" style="width: 200px;
                        height: 40px; font-size: 16px;" (click)="pay()">
                            <mat-icon>credit_card</mat-icon>
                            Payment
                        </button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

    </div>
</div>
import { Component,Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiProvider } from 'src/app/Services/api';


export interface DialogData {
  TITLE:string;
  MESSAGE: string;
  BUTTON1:string;
  BUTTON2:string;
}

@Component({
  selector: 'app-confirm-dlg',
  templateUrl: './confirm-dlg.component.html',
  styleUrls: ['./confirm-dlg.component.css']
})
export class ConfirmDlgComponent implements OnInit {
  public confirmMessage: string = "";
  public title: string = "";
  public butt1: string = "";
  public butt2: string = "";
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, public dialogRef: MatDialogRef<ApiProvider>) {
    this.confirmMessage = data.MESSAGE;
    this.title=data.TITLE;
    this.butt1=data.BUTTON1;
    this.butt2=data.BUTTON2;
    
  }

  ngOnInit(): void {
  }

}

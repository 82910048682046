import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiProvider } from 'src/app/Services/api';

@Component({
  selector: 'app-custorders',
  templateUrl: './custorders.component.html',
  styleUrls: ['./custorders.component.css']
})
export class CustordersComponent implements OnInit {
  orders: any = [];
  config: any;
  public bags: any = ["badge badge-primary", "badge badge-dark", "badge badge-success"];
  constructor(public api: ApiProvider, private router: Router) { }

  ngOnInit(): void {
    this.config = {
      itemsPerPage: 18,
      currentPage: 1,
      totalItems: 0
    };
    if (!this.api.userWEBprofile.login) this.router.navigateByUrl("/home");
    else
      this.GetItems();
  }
  pageChanged(event: any) {
    this.config.currentPage = event;
  }
  GetItems() {

    this.api.get(this.api.endpoint + '/Orders/Getorder/' + this.api.userWEBprofile.UID).subscribe(data => {
      //  console.log(data);
      if (data.toString().length > 5) {

        this.orders = data;

      }

    }, error => {
      console.log('error');

    });
  }
  setclass(stat: string) {
    var ret = this.bags[0];
    //console.log(stat);
    switch (stat) {
      case "PickUp Ready": {
        ret = this.bags[2];
        break;
      }
      case "Delivered": {
        ret = this.bags[2];
        break;
      }
      case "Processed": {
        ret = this.bags[2];
        break;
      }
      case "Canceled": {
        ret = this.bags[1];
        break;
      }
    }
    return ret;
  }
}

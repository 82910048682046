import { Component, ElementRef, Inject, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiProvider } from 'src/app/Services/api';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-mantenance',
  templateUrl: './mantenance.component.html',
  styleUrls: ['./mantenance.component.css']
})
export class MantenanceComponent implements OnInit {
  public deparments: any = [];
  public subdeparments: any = [];
  public depsel: string = "0";
  public subdepsel: string = "0";
  public productdata: any = {};
  private id: string = "*";
  public commnad: string = "Add";
  public image: any;
  public isphoto = true;
  public updatephoto = false;
  public isShow: boolean = false;
  public results: any = [];
private checkdone:boolean=true;
  constructor(public api: ApiProvider, private router: Router,
    private datePipe: DatePipe, public dialogRef: MatDialogRef<MantenanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.productdata = {};
    if (data.PRODUCTS.toString().length > 0) {
      this.productdata = data.PRODUCTS;
    //    console.log(this.productdata );
      this.depsel = data.DEP.toString();
      this.subdepsel = this.productdata.SUBDEPID.toString();
      this.updatedep();
      if (this.productdata.UPC == undefined) {

        this.productdata.ISNEW = true;
        this.productdata.DEPID = "0";
        this.productdata.SUBDEPID="0";
        this.productdata.IDCLOUD = this.api.userprofile.IDCLOUD;
      }
      else {
        this.commnad = "Save";
        this.productdata.ISNEW = this.api.toBoolean(this.productdata.ISNEW);
        this.productdata.COUNTED = this.api.toBoolean(this.productdata.COUNTED);
        this.productdata.EBT = this.api.toBoolean(this.productdata.EBT);
        this.productdata.WEIGHT = this.api.toBoolean(this.productdata.WEIGHT);
        this.productdata.WIC = this.api.toBoolean(this.productdata.WIC);
        this.productdata.FEATURED = this.api.toBoolean(this.productdata.FEATURED);
        this.productdata.INVENTORY = Number(this.productdata.INVENTORY);
        this.productdata.STARTDATE = this.datePipe.transform(this.productdata.STARTDATE, "yyyy-MM-dd");
        this.productdata.ENDDATE = this.datePipe.transform(this.productdata.ENDDATE, "yyyy-MM-dd");
        this.image = this.api.endointdata + this.productdata.UPC + ".jpg"  + '?'+Date.now();
        console.log(this.image);
      }


    }
    else {
      this.productdata.ISNEW = true;
      this.productdata.DEPID = "0";
      this.productdata.SUBDEPID="0";
      this.productdata.INVENTORY = 0;
      this.productdata.IDCLOUD = this.api.userprofile.IDCLOUD;
      this.productdata.NP_PRICE = "1";
      this.productdata.EBT = false;
      this.productdata.WEIGHT = false;
      this.productdata.WIC = false;
      this.productdata.FEATURED = false;
    }
    this.deparments = JSON.parse("[" + (this.api.userprofile.DEPARTMENTS) + "]");
    //   console.log(this.deparments);
   // console.log(this.productdata);

  }
  @Input() requiredFileType: string = "";
  @ViewChild("idupc")  idupc!: ElementRef;
  @ViewChild("price")  price!: ElementRef;
  @ViewChild('descript') descript!:ElementRef;
  loadDefault(event: any) {
    this.isphoto = false
    event.target.src = "assets/images/notItems.png";
  }


  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    this.isphoto = false;
    this.updatephoto = false;
    if (file) {

      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (_event) => {
        this.image = reader.result;
        this.isphoto = true;
        this.updatephoto = true;
      }

    }
  }
  NewUpc(){
    this.api.get(this.api.endpoint + "/stores/getupc/" + this.api.IDCLOUD).subscribe(data => {
           //   console.log(data);
              this.productdata.UPC=data;
              this.checkdone=false;
    }, error => {
 
    });
  }
  ngOnInit(): void {
    if (this.api.userprofile.login == undefined)
      this.router.navigateByUrl("/home");
    else {
      if (!this.api.userprofile.login)
        this.router.navigateByUrl("/home");
      else {

      }
    }

  }

  Updatedata() {
    if (this.depsel == "0") {
      this.api.presentToast("DATA ERROR, PLEASE CHECK DEPARMENT .....", "", 2000);
      return;
    };
    if (this.productdata.INVENTORY == 0) {
      this.api.presentToast("INVENTORY CANNOT BE ZERO .....", "", 2000);
      return;
    };
    if (this.isphoto && this.updatephoto) {
      var imageData = this.image
      if (String(imageData).length > 0) {
        if (String(imageData).indexOf("assets/Images/notfound.png") > -1) return;
        imageData = imageData.toString().replace("data:image/jpg;base64,", "");
        imageData = imageData.toString().replace("data:image/jpeg;base64,", "");
      }

      var dataout1 = {
        ID: this.productdata.UPC,
        IMAGE: imageData
      }
      console.log(dataout1);
      this.api.put('https://www.updatapos.com/sc/POSMAINTENANCE/2', JSON.stringify(dataout1)).subscribe(data => {
        console.log("Photo Updated");


      });
    }
    this.productdata.DEPID = this.depsel;
    this.productdata.SUBDEPID=this.subdepsel;
    this.productdata.ISPHOTO = this.isphoto;
    this.productdata.LST_MODIF = this.api.userprofile.INITIALS;
    this.productdata.LST_MODIF_DATE = this.datePipe.transform(new Date(), "MM/dd/yyyy");
  //  console.log(this.productdata);

    var dataout = {
      ID: "1",
      DATAFORM: btoa(JSON.stringify(this.productdata))
    }
    this.api.post(this.api.endpoint + '/storeitems', dataout).subscribe(async data => {
      console.log(data);
      var dat1 = data;
      if (dat1.toString().length == 0) {
        this.dialogRef.close("1");
        this.api.presentToast("DATA UPTATED .....", "", 2000);
      }
      else {
        this.api.presentToast(dat1.toString(), "", 2000);
      }



    });

  }

  Close() {
    this.dialogRef.close("0");
  }

  updatedep() {
    this.subdeparments = this.api.getsubDepat(this.depsel);
  }
  getsearch() {
    try {
      
   
      if (this.productdata.UPC.length > 5) {
        this.results = [];
        this.api.get(this.api.endpoint + "/storeitems/searchitemsMT/" + this.productdata.UPC).subscribe(data => {
          this.isShow = true;
          //          console.log(data);
          this.results = data;
        }, error => {
          this.isShow = false;
        });

      }
      else
        this.isShow = false;
    } catch (e) { }
  }
  Selected(id: string) {
   
  
  //  console.log(id);
    this.isShow = false;
    this.api.get(this.api.endpoint + "/storeitems/SingletitemsMaster/" + id).subscribe((data: any) => {
      console.log(data);
     if(data.length>0)
     {
      this.results = [];
      this.productdata.UPC = data[0].UPC;
      this.productdata.DESCRIPTION = data[0].DESCRIPTION;
      this.productdata.CASE_SIZE = data[0].CASE_SIZE;
      this.productdata.SIZE_IT = data[0].SIZE_IT;
      this.productdata.ISPHOTO = true;
      this.productdata.INVENTORY = 0;
      this.productdata.TAX = "0";
      this.productdata.EBT = false;
      this.productdata.WIC = false;
      this.productdata.WEIGHT = false;
      this.productdata.FEATURED = false;
      this.productdata.COUNTED = false;

      this.image = this.api.endointdata + this.productdata.UPC + ".jpg";
      this.productdata.NP_PRICE = "1";
       this.price.nativeElement.focus();
     }
     else
     {
        this.descript.nativeElement.focus();
     }
      //  console.log(this.productdata);
    }, error => {

    });

  }
  handkey(value: any){
    if(value.key=='Enter'){
      if (this.productdata.UPC.length < 5) {
        let result = this.productdata.UPC.padStart(10, '0');
        this.productdata.UPC="4" + result;
       this.Selected( this.productdata.UPC);
      
      }
      
    }

  }
  handleInput(value: any) {
    this.getsearch();

  }
  paste() {

    (navigator as any).clipboard.readText().then((clipboard: any) => {
      //   console.log(clipboard);
      //  this.image = clipboard;
      this.isphoto = false;
      this.updatephoto = false;
      this.api.loadImage(clipboard).then(result => {
        //  console.log(result);
        this.image = result;
        this.isphoto = true;
        this.updatephoto = true;
      });


    })

  }

  checkupc() {
    if (this.productdata.UPC.length == 0) return;
if(!this.checkdone){
 // this.checkdone=true;
  return;
}
    if (this.api.isNumber(this.productdata.UPC)) {
      this.results = [];
      this.isShow = false;
      this.productdata.UPC = this.api.UPCCK(this.productdata.UPC);
      if (this.productdata.UPC.length < 11) {
        this.productdata.UPC = "";
        this.idupc.nativeElement.focus();
        this.api.presentToast("ERROR UPC", "Ok", 2000);
      }
    } else {
      this.results = [];
      this.isShow = false;
      this.productdata.UPC = "";
      this.idupc.nativeElement.focus();
      this.api.presentToast("ERROR UPC", "Ok", 2000);
    }
  }

}

<body style="font:12px Helvetica, Arial, sans-serif;overflow-y: scroll;height: 600px;width:auto;">
	<table style="width:700px; border:2px solid #58717e; border-radius:5px; font:12px Helvetica, Arial, sans-serif;">
		<tr>
			<td style="padding:0px;">
				<table style="border-collapse:collapse; width:100%; background-color:#13547a; font:28px Helvetica, Arial, sans-serif;">
					<tr>
						<td style="padding:10px; text-align:right;">
							<img src="http://web.iarcservices.com/Content/Images/IARC_Logo.png" style="border:#1a5878 1px solid;" width="140" alt="IARC" />
						</td>
						<td style="padding:10px; color:white;">
							Live Rating Notice
						</td>
					</tr>
				</table>
			</td>
        </tr>

        <tr>
            <td style="padding:2px; font:12px Helvetica, Arial, sans-serif;">				
                <table style="border-collapse:collapse; width:100%; margin:10px; font:12px Helvetica, Arial, sans-serif;">
                    
					<tr style="padding-top: 8px">
						<td style="white-space:nowrap; width:15%; padding-right:5px; font-weight:bold; vertical-align:top;">
							Product Title:
						</td>
						<td style="white-space:nowrap; width:35%; padding-right:5px; vertical-align:top;">
							Cherry Valley
						</td>

						<td style="white-space:nowrap; width:15%; padding-right:5px; font-weight:bold; vertical-align:top;">
							Company:
						</td>
						<td style="white-space:nowrap; width:35%; padding-right:5px; vertical-align:top;">
							Rbrlink LLC
						</td>
					</tr>
					<tr>
						<td style="white-space:nowrap; width:15%; padding-right:5px; font-weight: bold; vertical-align:top;">
							Rating Date:
						</td>
						<td style="white-space:nowrap; width:35%; padding-right:5px; vertical-align:top;">
							Monday, November 28, 2022
						</td>

						<td style="white-space:nowrap; width:15%; padding-right:5px; font-weight: bold; vertical-align:top;">
							Storefront:
						</td>
						<td style="white-space:nowrap; width:35%; padding-right:5px; vertical-align:top;">
							Google Play
						</td>
					</tr>					
                </table>
            </td>
        </tr>
        
		<tr>
			<td style="padding: 0px 15px 0px 15px; font:12px Helvetica, Arial, sans-serif;">
                <p><strong>Ratings for this product are now live on the storefront listed above.  These ratings may be viewed in the storefront developer portal or on public product pages.</strong></p>
                <p><strong>These ratings may only be used on digital storefronts that have licensed IARC (<a href="https://www.globalratings.com/about.aspx#participants" target="_blank">list here</a>) and cannot be used on any physical product or on any storefront that has not licensed IARC.</strong></p>
                <p>To use these ratings on another storefront that has licensed IARC, enter the Global Rating ID above when you are asked for a Global Rating ID or an IARC Certificate ID during that storefont's product onboarding process.</p>
                <p>Additional information about the rating authorities participating in IARC can be found <a href="https://www.globalratings.com/about.aspx" target="_blank">here</a>.</p>
                <p>If any of the ratings generated by IARC for your product appear to be incorrect, you may request a rating check <a href="https://web.iarcservices.com/Dashboard/Request/7ff2831f-42c0-4344-b79d-40a06c89157d" target="_blank">here</a>. Prior to requesting a rating check, please confirm that the IARC questionnaire was filled out correctly for your product. It will take 1-3 business days to begin a rating check and the process may require you to submit additional materials.</p>
                <p><strong>These ratings were generated by IARC and terms for their use are included below.</strong></p>
                <p><strong>Terms of Use for Your Product's Ratings: </strong></p>

                <ol style="padding-left:25px; font:12px Helvetica, Arial, sans-serif;">
                    <li style="padding-top: 5px;">IARC has generated ratings for your product on the condition that you agree and comply with all of these terms. If you do not agree and comply with all of these terms then you may not use or display any ratings generated by IARC.</li>
                    <li style="padding-top: 5px;">You agree that your responses to the IARC questionnaire, which have been used to generate ratings for your product, are complete, accurate, and take into account all content in your product.</li>
                    <li style="padding-top: 5px;">The rating authority for each region may revise the rating for your product for its region if it determines that such rating is not appropriate for the product.</li>
                    <li style="padding-top: 5px;">If a rating generated by IARC is revised, you and storefronts that have licensed IARC will be notified and required to update the rating wherever it is displayed (e.g., product pages, advertising, websites, social media).</li>
                    <li style="padding-top: 5px;">Ratings generated by IARC may only be used on digital storefronts that have licensed IARC (<a href="https://www.globalratings.com/about.aspx#participants" target="_blank">list here</a>) and cannot be used on any physical product or on any storefront that has not licensed IARC.</li>
                    <li style="padding-top: 5px;">The ratings generated by IARC are trademarks of their respective rating authorities and may only be used to inform consumers about the content, age-appropriateness, and interactive elements of the product rated.</li>
                    <li style="padding-top: 5px;">If you believe a rating generated by IARC for your product is incorrect, you may request a rating check directly from the corresponding rating authority by using the "request a rating check" link provided upon the product's release.</li>
                    <li style="padding-top: 5px;">Ratings generated by IARC may only be used for the product for which they are generated and subsequent changes and additions to that product (e.g., updates, add-ons, downloadable content) that would not change the questionnaire responses originally provided by the developer concerning that product.</li>
                    <li style="padding-top: 5px;">If subsequent changes or additions to your product (e.g., updates, add-ons, downloadable content) would change your questionnaire responses concerning your product, then you must complete the IARC questionnaire again and receive new ratings generated by IARC for your product.</li>
                    <li style="padding-top: 5px;">For the benefit of consumers, participating rating authorities strongly encourage you to use the ratings generated by IARC on all advertising and marketing materials, subject to the <a href="https://web.iarcservices.com/Content/Guidelines/IARC_Advertising_and_Marketing_Guidelines.pdf" target="_blank">IARC Advertising and Marketing Guidelines</a>.</li>
                    <li style="padding-top: 5px;">You acknowledge that the ratings are generated by IARC and do not imply or guarantee your compliance with the rules, laws, or regulations applicable to the advertising, marketing, or sale of your product in any region or jurisdiction.</li>
                    <li style="padding-top: 5px;">These terms have been prepared in English; translations are provided for informational purposes only. In the event of a conflict or inconsistency between versions of these terms, the English-language version shall prevail and be binding.</li>
                </ol>
			</td>
		</tr>
	</table>
</body>
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { ApiProvider } from 'src/app/Services/api';

export interface DialogData {
  productCount: number;
 
}

@Component({
  selector: 'app-qtyselector',
  templateUrl: './qtyselector.component.html',
  styleUrls: ['./qtyselector.component.css']
})
export class QtyselectorComponent implements OnInit {
  public qty:number=0;
  constructor(public api: ApiProvider, public dialogRef: MatDialogRef<QtyselectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData ) { }

  ngOnInit(): void {
    
  this.qty=   this.data.productCount;
 
  }
  increaseProduct() {
    this.qty += 1;
  
  }
   removeProduct() {
    this.qty -= 1;
  }

  removeall()
  {
    this.qty=0;
 this.dialogRef.close(0);
  }
  
}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginComponent } from 'src/app/login/login.component';
import { ApiProvider } from 'src/app/Services/api';

@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.css']
})
export class ResetPassComponent implements OnInit {

  public password:string="";
  public confpassword:string="";
    constructor(public dialogRef: MatDialogRef<LoginComponent>, public api: ApiProvider) { }
  
    ngOnInit(): void {
    }
  
    Change()
    {
      if(this.password!=this.confpassword){
        this.api.presentToast("The Confirmation Password doesn't match with password","",3000);
        this.password="";
        this.confpassword="";
      }
      else
      this.dialogRef.close(this.password);
    }
    Close()
    {
      this.dialogRef.close("");
    }

}

import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiProvider } from 'src/app/Services/api';
import { QtyselectorComponent } from '../qtyselector/qtyselector.component';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.css']
})
export class OrderDetailComponent implements OnInit {
  public loader: boolean = false;
  OrderID: string = '';
  orders: any = [];
  ordersdetail: any = [];
  ordermodif: any = [];
  public info: any = {};
  IDTR: string = "";
  statsel: string = "";
  nopay: boolean = true;
  public status: string[] = ["Received", "In Progress", "Ready in 15 mins", "Ready in 30 mins",
    "Ready in 45 mins", "Ready in 1 hour", "Ready to Dispatch", "Canceled"];

  constructor(public api: ApiProvider,
    private datePipe: DatePipe, public dialogRef: MatDialogRef<OrderDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) {
    this.info = data.ORDER;
    this.OrderID = data.ORDER.IDTR;
    //console.log(data);
  }

  ngOnInit(): void {
    this.orderN();
  }
  getImgContent(imgFile: string) {

    //  if ( imgFile == null) return '../../assets/Images/imageNotFound.png';

    return this.api.getImgContent(imgFile);
  }

  delete(index: any, ID: string) {
    console.log(ID);

    this.api.delete(this.api.endpoint + '/Orders/' + ID).subscribe(data => {
      //console.log(data);
      this.setnewval(index, 0);

    }, error => {
      console.log("error");

    });



  }

  async changeqty(ev: any, item: string | number) {
    const dialogRef = this.dialog.open(QtyselectorComponent, {
      width: 'auto',
      data: {
        productCount: this.ordersdetail[item].QTY
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.setnewval(item, Number(result));
      }
    });
  }
  loadDefault(event: any) {

    event.target.src = "assets/Images/imageNotFound.png";
  }


  setnewval(index: string | number, qty: number) {
    if (qty <= this.ordersdetail[index].OQTY) {
      let discount = this.ordersdetail[index].TOTAL - this.ordersdetail[index].PRICE * qty;
      this.ordersdetail[index].QTY = qty;
      this.ordersdetail[index].TOTAL = Number(this.ordersdetail[index].PRICE * qty).toFixed(2);
      this.ordermodif.push(this.ordersdetail[index]);
      this.calculate();
    }
  }

  orderN() {
    this.statsel = "";
    this.api.get(this.api.endpoint + '/Orders/Getorderdetail/' + this.OrderID).subscribe(data => {
      if (data.toString().length > 5) {
        this.ordersdetail = data;
        this.statsel = this.info.STATUSPROCC;

        var dat = JSON.parse(this.info.SHIPPING);
        var ret = dat.Address + '\n' + dat.City + ' ' + dat.State + ',' + dat.Zip;
        this.info.SHIPPING = ret;
        this.calculate();
        if (this.statsel == "Ready to Dispatch") this.nopay = false;
      }

    }, error => {
      console.log("error");

    });
  }
  changestatsel() {

    if (this.statsel.length > 0) {
      //actualizar status
      var status = "1";
      if (this.statsel == "Canceled") status = "-1";
      var stat = {
        IDTR: this.OrderID,
        STATUS: status,
        STATUSPROCC: this.statsel
      }

      this.api.post(this.api.endpoint + '/Orders', stat).subscribe();
      if (this.statsel == "Ready to Dispatch" || this.statsel == "Canceled") this.nopay = false;
      if (this.info.PAIDCLOSED == 'True') this.nopay = true;
    }
  }
  pay() {

    var payment = JSON.parse(atob(this.info.PAYMENT));
    //grabar data;
    if (payment.CASH == "No") {
      this.Datacap(payment).then((data: any) => {

        console.log(data);
        var submitOrder = {
          Total: this.info.TOTAL,
          Subtotal: this.info.SUBTOTAL,
          Tax: this.info.TAX,
          Payment: btoa(JSON.stringify(data)),
          PAIDCLOSED: "True",
          Order: btoa(JSON.stringify(this.ordermodif))
        }
        this.api.put(this.api.endpoint + '/Orders/' + this.OrderID, JSON.stringify(submitOrder)).subscribe(result => {
          this.loader = false;
          var data = {
            "cmd": "2",
            "DATA": "",
            "TO": "CHERRYVALLEY",
            "DEVICE": this.api.IDCLOUD
          };
          this.api.connect.sendMessage(data);
          this.dialogRef.close();
        });

      }).catch(error => {

        this.loader = false;
        if (error.error == undefined) this.api.presentToast("Payment Required", "Ok", 0);
        else
          this.api.presentToast(error.error.Message, "Ok", 0);
        return;
      });
    }
    else {
      var submitOrder = {
        Total: this.info.TOTAL,
        Subtotal: this.info.SUBTOTAL,
        Payment: this.info.PAYMENT,
        Tax: this.info.TAX,
        PAIDCLOSED: "True",
        Order: btoa(JSON.stringify(this.ordermodif))
      }
      this.api.put(this.api.endpoint + '/Orders/' + this.OrderID, JSON.stringify(submitOrder)).subscribe(result => {
        this.loader = false;
        var data = {
          "cmd": "2",
          "DATA": "",
          "TO": "CHERRYVALLEY",
          "DEVICE": this.api.IDCLOUD
        };
        this.api.connect.sendMessage(data);
        this.dialogRef.close();
      });
    }



  }
  Print() {

    this.dialogRef.close("print");
 /*
    var data = {
      "cmd": "1",
      "DATA": this.OrderID,
      "TO": "CHERRYVALLEY",
      "DEVICE": "1"
    };

    this.api.connect.sendMessage(data);
   
    const dialogRef = this.dialog.open(PrintReportComponent, {
      width: '70%', height: '90%', panelClass: 'printclss',
      data: {
        "PAGE": "<h1>ESTE ES UN TEST</h1>"
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });*/
  }
  Close() {
    this.dialogRef.close();
  }
  Datacap(Href: any) {
    return new Promise((resolve, reject) => {

      console.log(Href);
      var sale = {
        Amount: this.info.TOTAL,
        RefNo: Href.RefNo,
        Token: Href.Token
      }
      console.log(sale);
      console.log(this.api.compheader.CCURL + '/credit/preauth/' + Href.RefNo);
      var endpoint = this.api.compheader.CCURL + '/credit/preauth/' + Href.RefNo;

      this.api.putDatacapSale(endpoint,
        JSON.stringify(sale)).subscribe((datasale: any) => {
          console.log(datasale);
          resolve(datasale);
        }, error => {
          reject(error);
        });
      ;

    });
  }
  calculate() {
    this.info.SUBTOTAL = 0;
    this.info.TAX = 0;
    for (var i = 0; i < this.ordersdetail.length; i++) {
      this.info.TAX += this.api.calcTax(this.ordersdetail[i].TAX, this.ordersdetail[i].QTY * this.ordersdetail[i].PRICE);
      this.info.SUBTOTAL += this.ordersdetail[i].QTY * this.ordersdetail[i].PRICE;
    }
    this.info.TOTAL = this.info.SUBTOTAL + this.info.DELIVERY + this.info.SERVICE + this.info.TAX;

  }
}

import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ThemePalette } from '@angular/material/core';
import { UsersComponent } from '../users/users.component';
import { ApiProvider } from 'src/app/Services/api';

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}
export interface DialogData {
  TITLE: string;
  INFO: any;
}

@Component({
  selector: 'app-users-add',
  templateUrl: './users-add.component.html',
  styleUrls: ['./users-add.component.css']
})
export class UsersAddComponent implements OnInit {
  @Input() requiredFileType: string = "";
  public datedbo: any;
  public image: any;
  public SetLang: string = "";
  public imagen: any = "../../assets/Images/icon1.png";
  public leyends = [];
  public contentdk: string = "content";
  public name: string = "";
  public emailadd: string = "";
  public isadm: boolean = false;
  public isdisabled: boolean = false;
  public resetp: boolean = false;
  public ID: string = "*";
  task: Task = {
    name: 'All',
    completed: false,
    color: 'primary',
    subtasks: [
      { name: 'Dashboard', completed: false, color: 'accent' },
      { name: 'Incoming Orders', completed: false, color: 'accent' },
      { name: 'Outgoing Orders', completed: false, color: 'accent' },
      { name: 'Departments', completed: false, color: 'accent' },
      { name: 'Products', completed: false, color: 'accent' },
      { name: 'Rewards', completed: false, color: 'accent' },
      { name: 'AddBranches', completed: false, color: 'accent' },
      { name: 'Add Users', completed: false, color: 'accent' },
    ],
  };
  taskReports: Task = {
    name: 'All',
    completed: false,
    color: 'primary',
    subtasks: [
      { name: 'Sales', completed: false, color: 'accent' },
      { name: 'Products', completed: false, color: 'accent' },
   
    ],
  };
  allServices: boolean = false;
  allReports: boolean = false;
  public Title: string = "";
  constructor(public api: ApiProvider, private router: Router,
    private datePipe: DatePipe, public dialogRef: MatDialogRef<UsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      console.log(data);
    this.Title = this.data.TITLE;
    if (this.data.INFO != null) {
      this.name = this.data.INFO.NAME;
      this.ID = this.data.INFO.UID;
      this.emailadd = this.data.INFO.EMAIL;
      this.isadm = this.api.toBoolean(this.data.INFO.ISADM);
      this.isdisabled = this.api.toBoolean(this.data.INFO.USER_BLOCKED);
      for (var i = 0; i < this.task.subtasks!.length; i++) {
        this.task.subtasks![i].completed = this.api.toBoolean(this.data.INFO.GROUP1.substring(i, i + 1));
      }
      for (var i = 0; i < this.taskReports.subtasks!.length; i++) {

        this.taskReports.subtasks![i].completed = this.api.toBoolean(this.data.INFO.GROUP2.substring(i, i + 1));
      }
    }

  }

  ngOnInit() {
    if (this.api.userprofile.login == undefined)
      this.router.navigateByUrl("/home");
    else {
      if (!this.api.userprofile.login)
        this.router.navigateByUrl("/home");
      else {

      }
    }

    // console.log(this.leyends);
  }
 
  updateAllComplete(value: number) {
    if (value == 0) this.allServices = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
    if (value == 1) this.allReports = this.taskReports.subtasks != null && this.taskReports.subtasks.every(t => t.completed);
  }

  someComplete(value: number): boolean {
    if (value == 0) {
      if (this.task.subtasks == null) {
        return false;
      }
      return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allServices;
    }
    if (value == 1) {
      if (this.taskReports.subtasks == null) {
        return false;
      }
      return this.taskReports.subtasks.filter(t => t.completed).length > 0 && !this.allReports;
    }
    return false;
  }

  setAll(completed: boolean, value: number) {
    if (value == 0) {
      this.allServices = completed;
      if (this.task.subtasks == null) {
        return;
      }
      this.task.subtasks.forEach(t => (t.completed = completed));
    }
    if (value == 1) {
      this.allReports = completed;
      if (this.taskReports.subtasks == null) {
        return;
      }
      this.taskReports.subtasks.forEach(t => (t.completed = completed));
    }
  }

  Updatedata() {
    var group1: string = "";
    var group2: string = "";
    var i: number;

    for (i = 0; i < this.task.subtasks!.length; i++) {
      group1 += this.api.BooltoNumber(this.task.subtasks![i].completed).toString();
    }
    for (i = 0; i < this.taskReports.subtasks!.length; i++) {
      group2 += this.api.BooltoNumber(this.taskReports.subtasks![i].completed).toString();
    }
    var dat: any;

    if (this.resetp) {
      dat = {
        "Name": this.name,
        "Email": this.emailadd,
        "AccessKey": "",
        "GROUP1": group1,
        "GROUP2": group2,
        "USER_BLOCKED": this.api.BooltoNumber(this.isdisabled).toString(),
        "IsADM": this.api.BooltoNumber(this.isadm).toString(),
        "IDCLOUD": this.api.userprofile.IDCLOUD
      }
    }
    else {
      dat = {
        "Name": this.name,
        "Email": this.emailadd,
        "GROUP1": group1,
        "GROUP2": group2,
        "USER_BLOCKED": this.api.BooltoNumber(this.isdisabled).toString(),
        "IsADM": this.api.BooltoNumber(this.isadm).toString(),
        "IDCLOUD": this.api.userprofile.IDCLOUD
      }

    }
//   console.log(dat);

    var dataout = {
      ID: "",
      DATAFORM: btoa(JSON.stringify(dat))
    }
    this.api.put(this.api.endpoint + '/login/' + this.ID, dataout).subscribe(async data => {
      //  console.log(data);
      var dat1 = data;
      if (dat1.toString().length > 10) {
        var data1: any = data;
        if (data1.StatusCode == 200) {
          this.dialogRef.close("1");
          this.api.presentToast("DATA UPTATED .....", "", 2000);
        }
        else {
          this.api.presentToast(data1.MESSAGE, "", 2000);
        }
      }
      else {


      }


    });

  }

  Close() {
    this.dialogRef.close("0");
  }
  
}

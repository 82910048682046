import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiProvider } from '../Services/api';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-ampl-photo',
  templateUrl: './ampl-photo.component.html',
  styleUrls: ['./ampl-photo.component.css']
})
export class AmplPhotoComponent implements OnInit {
 public image:SafeHtml;
  constructor(public dialogRef: MatDialogRef<AmplPhotoComponent>,public api: ApiProvider, private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
         this.image=this.sanitizer.bypassSecurityTrustUrl(this.api.endointdata + data.IMAGE + '.jpg') ; 
  }

  ngOnInit(): void {
  
  }

  Close() {
    this.dialogRef.close("0");
  }

  
}

<div mat-dialog-content class="main">
  <div class="row size" >
    <div class="col-sm-3">
      <div *ngIf="this.qty <=1">
        <button mat-button color="primary" (click)="removeall()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <div *ngIf="this.qty >1">
        <button mat-button color="primary" (click)="removeProduct()">
          <mat-icon>remove</mat-icon>
        </button>
      </div>
    </div>
    <div class="col-sm-3">
      <input matInput type="number" [(ngModel)]="this.qty" style="width: 50px; text-align: center; font-size: 1.8vh;margin-left: 5px;">
    </div>
    <div class="col-sm-2">
      <button mat-button color="primary" (click)="increaseProduct()" >
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div class="col-sm-4">
      <div mat-dialog-actions style="margin-top: -20px;margin-left: 40px;">
        <button mat-icon-button [mat-dialog-close]="qty" color="warn" cdkFocusInitial > 
          <mat-icon> shopping_cart</mat-icon>
        </button>
      </div>
    </div>
  </div>
 
</div>
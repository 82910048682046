import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { OrderDetailComponent } from '../component/order-detail/order-detail.component';
import { ApiProvider } from '../Services/api';
import { DataService } from '../Services/data.service';

@Component({
  selector: 'app-incoming-order',
  templateUrl: './incoming-order.component.html',
  styleUrls: ['./incoming-order.component.css']
})
export class IncomingOrderComponent implements OnInit {
  orders: any = [];
  
  subscription:any;
  config: any;
  constructor(public api: ApiProvider, public dialog: MatDialog, private dataService: DataService,private router: Router) { 
   
  }
  public bags: any = ["badge badge-primary", "badge badge-dark", "badge badge-success"];
  ngOnInit(): void {

    var lines=  window.innerHeight*15/1153;
    this.config = {
      itemsPerPage: lines,
      currentPage: 1,
      totalItems: 0
    };
    this.GetItems();
    this.dataService.currentData.subscribe(data => {
      if (data.route == "ORDERS") {
        this.GetItems();
      }
    });
  
  }
  pageChanged(event: any) {
    this.config.currentPage = event;
  }
  ngOnDestroy():void{
   // this.subscription.unsubscribe();
  }
  orderN(Order: any) {
    const dialogRef = this.dialog.open(OrderDetailComponent, {
      height: '740px', width: '750px', panelClass: 'dlg1',
      data: {
        "ORDER": Order
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'print') {
        var dat = {
          TYPE: "0",
          REPORT: "1",
          DATA: Order.IDTR,
          STORE: this.api.userprofile.IDCLOUD
        }
        this.router.navigateByUrl("/printreport/" + btoa(JSON.stringify(dat)));
      }
      else
        this.GetItems();
    });
  }
  getaddress(shipping: string) {

    try {
      var it = JSON.parse(shipping);
      return it.City;
    }
    catch {
      return "";
    }


  }
  Getstatus(index: any) {

    if ((this.orders[index].STATUSPROCC == 'Delivered' || this.orders[index].STATUSPROCC == 'Canceled'
      || this.orders[index].STATUSPROCC == 'PickUp Ready') && this.orders[index].PAIDCLOSED == 'True') return true;
    else return false;
  }

  eliminate(Order: any) {

    var stat = {
      IDTR: Order,
      STATUS: '2',
      STATUSPROCC: 'Processed/Deleted'
    }

    this.api.post(this.api.endpoint + '/Orders', stat).subscribe(data => {
      this.GetItems();
    });
  }

  GetItems() {
    this.api.get(this.api.endpoint + '/Orders/Getorderstore/' + this.api.userprofile.IDCLOUD).subscribe(data => {
   //  console.log(data);
      if (data.toString().length > 5) {
        this.orders = data;

      }

    }, error => {
      console.log("error");

    });
  }
  async openalert() {

  }

  setclass(stat: string) {

    return this.bags[0];
  }
  onResize(event: any)  {
    //console.log(event.target.innerWidth);
    var lines=  window.innerHeight*15/1153;
    this.config = {
      itemsPerPage: lines,
      currentPage: 1,
      totalItems: 0
    };
    this.GetItems();
  }
}

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiProvider } from 'src/app/Services/api';

@Component({
  selector: 'app-departmets',
  templateUrl: './departmets.component.html',
  styleUrls: ['./departmets.component.css']
})
export class DepartmetsComponent implements OnInit {
  public depsel: string = "";
  public subdepart: any = [];
  constructor(public api: ApiProvider, public dialog: MatDialog) { }
  @ViewChild('dialogRef')
  dialogRef!: TemplateRef<any>;
  @ViewChild('EditDLG')
  EditDLG!: TemplateRef<any>;
  @ViewChild('EditSubDLG')
  EditSubDLG!: TemplateRef<any>;
  @ViewChild('ADDDLG')
  ADDDLG!: TemplateRef<any>;


  ngOnInit(): void {

    this.depsel = this.api.departments[0].ID;
    this.subdepart = this.api.getsubDepat(this.depsel);
    //    console.log(this.subdepart );
    //   console.log(this.api.Subdepartments);
  }
  selectdept(id: string) {
    this.depsel = id;
    this.subdepart = this.api.getsubDepat(this.depsel);
   // console.log( this.subdepart );
  }

  edititem(dep: any) {
      console.log(dep);

    var ret = {
      MAIN: this.api.getfamily(dep.GRP),
      DESCRIPTION: dep.DESCRIPTION
    }
    var index = this.api.departments.findIndex(function (item: any) { return item.ID === dep.ID });
    const myTempDialog = this.dialog.open(this.EditDLG, { data: ret });
    myTempDialog.afterClosed().subscribe((res) => {
      if (res !== undefined) {
        //al borrar, chequear que todos los productos migren a otro departamento antes de borrar
      //  console.log({ res });
        if (res == '~Delete') {
          if (this.subdepart.length == 0) {
            this.api.departments.splice(index, 1);
            var dataout = {
              DEPARTMENT: JSON.stringify(this.api.departments),
              SUBDEPARTMENT: JSON.stringify(this.api.Subdepartments),
              OLDDEP:"",
              DEP:"",
              OLDSUB:"",
              SUB:""
            }
            this.save(dataout);
          }
          else {
            this.api.presentToast("Department is not EMPTY" , "", 2000);
          }

        }
        else {
          var re1 = res.split('|');
          if (re1[0].length > 0 && re1[1].length > 0) {
            this.api.departments[index].GRP = this.api.getfamilyID(re1[0]);
            this.api.departments[index].DESCRIPTION = re1[1];
            var dataout = {
              DEPARTMENT: JSON.stringify(this.api.departments),
              SUBDEPARTMENT: JSON.stringify(this.api.Subdepartments),
              OLDDEP:"",
              DEP:"",
              OLDSUB:"",
              SUB:""
            }
            this.save(dataout);
          }
        }
      }

    });
  }
  edititemsub(dep: any) {
 //   console.log(dep);
    var ret = {
      MAIN: this.api.getDepatName(this.depsel),
      DESCRIPTION: dep.DESCRIPTION
    }

    const myTempDialog = this.dialog.open(this.EditSubDLG, { data: ret });
    myTempDialog.afterClosed().subscribe((res) => {
      if (res !== undefined) {
        //   console.log(res);
        if (res == '~Delete') {
          this.api.Subdepartments.splice(dep.INDEX, 1);
          this.subdepart = this.api.getsubDepat(this.depsel);
          var dataout = {
            DEPARTMENT: JSON.stringify(this.api.departments),
            SUBDEPARTMENT: JSON.stringify(this.api.Subdepartments),
            OLDDEP:"",
            DEP:"",
            OLDSUB:"",
            SUB:""
          }
          this.save(dataout);
        }
        else {
         
          var re1 = res.split('|');
          if (re1[0].length > 0 && re1[1].length > 0) {
            var newdep:string= this.api.getIDDepatName(re1[0]);
            var list = this.api.getsubDepat(newdep);
            var index = "0";
            if (list.length > 0) index=String(Number(list[list.length - 1].SID) + 1);
    
            this.api.Subdepartments[dep.INDEX].ID = newdep;
            this.api.Subdepartments[dep.INDEX].DESCRIPTION = re1[1];
            this.api.Subdepartments[dep.INDEX].SID=index;
            this.subdepart = this.api.getsubDepat(this.depsel);
            var dataout = {
              DEPARTMENT: JSON.stringify(this.api.departments),
              SUBDEPARTMENT: JSON.stringify(this.api.Subdepartments),
              OLDDEP:String(dep.ID),
              DEP:newdep,
              OLDSUB:String(dep.SID),
              SUB:index
            }
        //    console.log(dataout);
          
            this.save(dataout);
          }
        }
      }
    });
  }
  Adddepart() {
    var ret = {
      DEP: "",
      NAME: ""
    }
    const myTempDialog = this.dialog.open(this.ADDDLG, { data: ret });
    myTempDialog.afterClosed().subscribe((res: string) => {
      //   console.log(res);

      if (res != undefined) {
        var re1 = res.split('|');
        if (re1[0].length > 0 && re1[1].length > 0) {

          var datin = {
            ID: String(Number(this.api.departments[this.api.departments.length - 1].ID) + 1),
            DESCRIPTION: re1[1],
            GRP: this.api.getfamilyID(re1[0]),
            selected: "0"
          }

          this.api.departments.push(datin);
          var dataout = {
            DEPARTMENT: JSON.stringify(this.api.departments),
            SUBDEPARTMENT: JSON.stringify(this.api.Subdepartments),
            OLDDEP:"",
            DEP:"",
            OLDSUB:"",
            SUB:""
          }
          this.save(dataout);
        }
      }
    });
  }
  Addsubdepart() {
    const myTempDialog = this.dialog.open(this.dialogRef, { data: "" });
    myTempDialog.afterClosed().subscribe((res) => {
      // Data back from dialog
      if (res != undefined) {
        var list = this.api.getsubDepat(this.depsel);
        var index = "0";
        if (list.length > 0) index=String(Number(list[list.length - 1].SID) + 1);

        var datin = {
          ID: String(this.depsel),
          SID: index,
          DESCRIPTION: res
        }
        this.api.Subdepartments.push(datin);
        this.subdepart = this.api.getsubDepat(this.depsel);
        var dataout = {
          DEPARTMENT: JSON.stringify(this.api.departments),
          SUBDEPARTMENT: JSON.stringify(this.api.Subdepartments),
          OLDDEP:"",
          DEP:"",
          OLDSUB:"",
          SUB:""
        }
        this.save(dataout);
      }

    });
  }

  save(dataout :any) {
    this.api.put(this.api.endpoint + '/StoreItems/' + this.api.userprofile.IDCLOUD, dataout).subscribe(async data => {
      //   console.log(data);
    });

  }
}

<div class="breadcrumbs">
  <div class="container">
    <div class="row">
      <div class="col-xs-12">
        <ul>
          <li class="home"> <a routerLink="/home" title="Go to Home Page">Home</a> <span>/</span> </li>
          <li> <strong>Checkout</strong> </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- main-container -->
<div class="main-container col1-layout">
  <div class="main container">
    <div class="row">
      <section class="col-sm-12 col-xs-12">
        <div class="col-main">
          <div class="page-title">
            <h1 sytle="font-weight: bolder;">Checkout</h1>
          </div>

          <div class="page-content checkout-page">
            <h3 class="checkout-sep" sytle="font-weight: bolder;">1. Checkout Method</h3>
            <div class="box-border" *ngIf="!api.userWEBprofile.login">
              <div class="row">
                <div class="col-sm-6">
                  <h4>Checkout as a Guest or Register</h4>
                  <p>Register with us for future convenience:</p>
                  <ul>
                    <li>
                      <label>
                        <input type="radio" name="radio1" [value]="false" [(ngModel)]="checkoutype">
                        Checkout as Guest</label>
                    </li>
                    <li>
                      <label>
                        <input type="radio" name="radio1" [value]="true" [(ngModel)]="checkoutype">
                        Register</label>
                    </li>
                  </ul>
                  <br>
                  <h4>Register and save time!</h4>
                  <p>Register with us for future convenience:</p>
                  <p><i class="fa fa-check-circle text-primary"></i> Fast and easy check out</p>
                  <p><i class="fa fa-check-circle text-primary"></i> Easy access to your order history and status</p>
                </div>
                <div class="col-sm-6">
                  <h4>Login</h4>
                  <p>Already registered? Please log in below:</p>
                  <label>Email address</label>
                  <input type="text" class="form-control input" [(ngModel)]="api.userWEBprofile.EMAIL">
                  <label>Password</label>
                  <input type="password" class="form-control input" [(ngModel)]="password">
                  <p><a href="#" style="color:rgb(131, 57, 119)">Forgot your password?</a></p>
                  <button class="button" (click)="login()">Login</button>
                </div>
              </div>
            </div>
            <form [formGroup]="checkFormbilling">
              <h3 class="checkout-sep" sytle="font-weight: bolder;">2. Billing Infomation</h3>
              <div class="box-border" >
                <ul>
                  <li class="row">
                    <div class="col-sm-6">
                      <label for="first_name" class="required">First Name</label>
                      <input type="text" class="input form-control" formControlName="Name">
                      <div class="alert"
                        *ngIf="checkFormbilling.controls['Name'].invalid && (checkFormbilling.controls['Name'].dirty || checkFormbilling.controls['Name'].touched)">
                        First Name is required.
                      </div>
                    </div>
                    <!--/ [col] -->
                    <div class="col-sm-6">
                      <label for="last_name" class="required">Last Name</label>
                      <input type="text" name="" class="input form-control" formControlName="Lastname">
                      <div class="alert"
                        *ngIf="checkFormbilling.controls['Lastname'].invalid && (checkFormbilling.controls['Lastname'].dirty || checkFormbilling.controls['Lastname'].touched)">
                        Last Name is required.
                      </div>
                    </div>
                    <!--/ [col] -->
                  </li>
                  <!--/ .row -->
                  <li class="row">
                    <div class="col-sm-6">
                      <label for="company_name">Company Name</label>
                      <input type="text" name="" class="input form-control" formControlName="Company">
                    </div>
                    <!--/ [col] -->
                    <div class="col-sm-6">
                      <label for="email_address" class="required">Email Address</label>
                      <input type="email" class="input form-control" name="" formControlName="Email">
                      <div class="alert"
                        *ngIf="checkFormbilling.controls['Email'].invalid && (checkFormbilling.controls['Email'].dirty || checkFormbilling.controls['Email'].touched)">
                        Entry valid EMAIL
                      </div>
                    </div>
                    <!--/ [col] -->
                  </li>
                  <!--/ .row -->
                  <li class="row">
                    <div class="col-xs-12">
                      <label for="address" class="required">Address</label>
                      <input type="text" class="input form-control" name="" formControlName="Address">
                      <div class="alert"
                        *ngIf="checkFormbilling.controls['Address'].invalid && (checkFormbilling.controls['Address'].dirty || checkFormbilling.controls['Address'].touched)">
                        Invalid Address
                      </div>
                    </div>
                    <!--/ [col] -->

                  </li>
                  <!-- / .row -->

                  <li class="row">
                    <div class="col-sm-6">
                      <label for="city" class="required">City</label>
                      <input class="input form-control" type="text" name="" formControlName="City">
                      <div class="alert"
                        *ngIf="checkFormbilling.controls['City'].invalid && (checkFormbilling.controls['City'].dirty || checkFormbilling.controls['City'].touched)">
                        Invalid City
                      </div>
                    </div>
                    <!--/ [col] -->

                    <div class="col-sm-6">
                      <label class="required">State/Province</label>
                      <mat-select [formControlName]="'State'"  #singleSelect  >
                        <mat-option  [value]="st.COD" *ngFor="let st of states">{{st.NAME}} </mat-option>
                    </mat-select>
                      <div class="alert"
                        *ngIf="checkFormbilling.controls['State'].invalid && (checkFormbilling.controls['State'].dirty || checkFormbilling.controls['bstate'].touched)">
                        Invalid State
                      </div>
                    </div>
                    <!--/ [col] -->
                  </li>
                  <!--/ .row -->

                  <li class="row">
                    <div class="col-sm-6">
                      <label for="postal_code" class="required">Zip/Postal Code</label>
                      <input class="input form-control" type="number" name="" formControlName="Zip">
                      <div class="alert"
                        *ngIf="checkFormbilling.controls['Zip'].invalid && (checkFormbilling.controls['Zip'].dirty || checkFormbilling.controls['Zip'].touched)">
                        Invalid ZipCode
                      </div>
                    </div>
                    <!--/ [col] -->

                    <div class="col-sm-6">
                      <label class="required">Country</label>
                      <select class="input form-control" name="" formControlName="Country">
                        <option value="USA">USA</option>
                      </select>
                      <div class="alert"
                        *ngIf="checkFormbilling.controls['Country'].invalid && (checkFormbilling.controls['Country'].dirty || checkFormbilling.controls['Country'].touched)">
                        Invalid Country selected
                      </div>
                    </div>
                    <!--/ [col] -->
                  </li>
                  <!--/ .row -->
                  <li class="row">
                    <div class="col-sm-6">
                      <label for="telephone" class="required">Telephone</label>
                      <input class="input form-control" type="tel" name="" formControlName="Phone">
                      <div class="alert"
                        *ngIf="checkFormbilling.controls['Phone'].invalid && (checkFormbilling.controls['Phone'].dirty || checkFormbilling.controls['Phone'].touched)">
                        Invalid Phone number
                      </div>
                    </div>
                    <!--/ [col] -->

                    <div class="col-sm-6">
                      <label for="fax">Fax</label>
                      <input class="input form-control" type="tel" name="" formControlName="Fax">
                    </div>
                    <!--/ [col] -->

                  </li>
                  <!--/ .row -->

                  <li class="row" >
                    <div class="col-sm-6" *ngIf="!api.userWEBprofile.login">
                      <label for="password" class="required">Password</label>
                      <input class="input form-control" type="password" name="" formControlName="password">
                      <div class="alert"
                        *ngIf="checkFormbilling.controls['password'].errors?.minlength ">
                        Password must have at least 4 characters
                      </div>
                    </div>
                    <!--/ [col] -->

                    <div class="col-sm-6" *ngIf="!api.userWEBprofile.login">
                      <label for="confirm" class="required">Confirm Password</label>
                      <input class="input form-control" type="password" name="" formControlName="compassword" (change)="onPasswordChange()">
                      <div class="alert"
                        *ngIf="checkFormbilling.controls['compassword'].hasError('mismatch')">
                        Password Doesn't match
                      </div>
                    </div>
                    <!--/ [col] -->
                  </li>
                </ul>
              </div>
              </form>
              <section >
                <mat-checkbox  value="sameasbilling" (click)="setshipping(sameasbilling)">Same as Billing</mat-checkbox>
              </section>
              <h3 class="checkout-sep" sytle="font-weight: bolder;">3. Shipping Information </h3>
              <form [formGroup]="checkFormshipping">
              <div class="box-border">
                <ul>
                  <li class="row">
                    <div class="col-sm-6">
                      <label for="first_name_1" class="required">First Name</label>
                      <input class="input form-control" type="text" name="" formControlName="Name">
                      <div class="alert"
                        *ngIf="checkFormshipping.controls['Name'].invalid && (checkFormshipping.controls['Name'].dirty || checkFormshipping.controls['Name'].touched)">
                        First Name is required.
                      </div>
                    </div>
                    <!--/ [col] -->

                    <div class="col-sm-6">
                      <label for="last_name_1" class="required">Last Name</label>
                      <input class="input form-control" type="text" name="" formControlName="Lastname">
                      <div class="alert"
                        *ngIf="checkFormshipping.controls['Lastname'].invalid && (checkFormshipping.controls['Lastname'].dirty || checkFormshipping.controls['Lastname'].touched)">
                        Last Name is required.
                      </div>
                    </div>
                    <!--/ [col] -->

                  </li>
                  <!--/ .row -->

                  <li class="row">
                    <div class="col-sm-6">
                      <label for="company_name_1">Company Name</label>
                      <input class="input form-control" type="text" name="" formControlName="Company">
                    </div>
                    <!--/ [col] -->

                    <div class="col-sm-6">
                      <label for="email_address_1" class="required">Email Address</label>
                      <input class="input form-control" type="email" name="" formControlName="Email">
                      <div class="alert"
                        *ngIf="checkFormshipping.controls['Email'].invalid && (checkFormshipping.controls['Email'].dirty || checkFormshipping.controls['Email'].touched)">
                        Entry valid EMAIL
                      </div>
                    </div>
                    <!--/ [col] -->

                  </li>
                  <!--/ .row -->

                  <li class="row">
                    <div class="col-xs-12">
                      <label for="address_1" class="required">Address</label>
                      <input class="input form-control" type="text" name="" formControlName="Address">
                      <div class="alert"
                        *ngIf="checkFormshipping.controls['Address'].invalid && (checkFormshipping.controls['Address'].dirty || checkFormshipping.controls['Address'].touched)">
                        Invalid Address
                      </div>
                    </div>
                    <!--/ [col] -->

                  </li>
                  <!--/ .row -->

                  <li class="row">
                    <div class="col-sm-6">
                      <label for="city_1" class="required">City</label>
                      <input class="input form-control" type="text" name="" formControlName="City">
                      <div class="alert"
                        *ngIf="checkFormshipping.controls['City'].invalid && (checkFormshipping.controls['City'].dirty || checkFormshipping.controls['City'].touched)">
                        Invalid City
                      </div>
                    </div>
                    <!--/ [col] -->

                    <div class="col-sm-6">
                      <label class="required">State/Province</label>
                      <div class="custom_select">
                        <mat-select [formControlName]="'State'"  #singleSelect  >
                          <mat-option  [value]="st.COD" *ngFor="let st of states">{{st.NAME}} </mat-option>
                      </mat-select>

                      </div>
                      <div class="alert"
                        *ngIf="checkFormshipping.controls['State'].invalid && (checkFormshipping.controls['State'].dirty || checkFormshipping.controls['State'].touched)">
                        Invalid State
                      </div>
                    </div>
                    <!--/ [col] -->

                  </li>
                  <!--/ .row -->

                  <li class="row">
                    <div class="col-sm-6">
                      <label for="postal_code_1" class="required">Zip/Postal Code</label>
                      <input class="input form-control" type="number" name="" formControlName="Zip">
                      <div class="alert"
                        *ngIf="checkFormshipping.controls['Zip'].invalid && (checkFormshipping.controls['Zip'].dirty || checkFormshipping.controls['Zip'].touched)">
                        Invalid ZipCode
                      </div>
                    </div>
                    <!--/ [col] -->

                    <div class="col-sm-6">
                      <label class="required">Country</label>
                      <div class="custom_select">
                        <select class="input form-control" name="" formControlName="Country">
                          <option value="USA">USA</option>
                        </select>
                      </div>
                      <div class="alert"
                        *ngIf="checkFormshipping.controls['Country'].invalid && (checkFormshipping.controls['Country'].dirty || checkFormshipping.controls['Country'].touched)">
                        Invalid Country
                      </div>
                    </div>
                    <!--/ [col] -->

                  </li>
                  <!--/ .row -->

                  <li class="row">
                    <div class="col-sm-6">
                      <label for="telephone_1" class="required">Telephone</label>
                      <input class="input form-control" type="tel" name="" formControlName="Phone">
                      <div class="alert"
                        *ngIf="checkFormshipping.controls['Phone'].invalid && (checkFormshipping.controls['Phone'].dirty || checkFormshipping.controls['Phone'].touched)">
                        Invalid Phone number
                      </div>
                    </div>
                    <!--/ [col] -->

                    <div class="col-sm-6">
                      <label for="fax_1">Fax</label>
                      <input class="input form-control" type="tel" name="" formControlName="Fax">
                    </div>
                    <!--/ [col] -->

                  </li>
                  <!--/ .row -->

                </ul>
              </div>
            </form>
            <h3 class="checkout-sep">4. Shipping Method</h3>
            <div class="box-border">
              <ul class="shipping_method">
                <li>
                  <label for="radio_button_3">
                    <input type="radio" checked  name="radio_3" value="Free $0" 
                      (click)="shippingck()">
                    Free $0</label>
                </li>
                <li>
                  <label for="radio_button_4">
                    <input type="radio" name="radio_3" value="Standard Shipping $5.00" 
                      (click)="shippingck()">
                    Standard Shipping $5.00</label>
                </li>
              </ul>

            </div>
            <h3 class="checkout-sep" sytle="font-weight: bolder;">5. Payment Information</h3>
            <div class="box-border">
              <ul>
                <li>
                  <label for="radio_button_5">
                    <input type="radio" checked name="radio_4" [value]="true" [(ngModel)]="typepayment">
                    Pay on Delivery</label>
                </li>
                <li>
                  <label for="radio_button_6">
                    <input type="radio" name="radio_4" [value]="false" [(ngModel)]="typepayment">
                    Credit card (saved)</label>
                </li>
              </ul>
            </div>
            <h3 class="checkout-sep">6. Order Review</h3>
            <div class="box-border">
              <div class="order-detail-content table-responsive">
                <table class="table table-bordered jtv-cart-summary">
                  <thead>
                    <tr>
                      <th class="cart_product">Product</th>
                      <th>Description</th>
                      <th>Avail.</th>
                      <th>Unit price</th>
                      <th>Qty</th>
                      <th>Total</th>
                      <th class="action"><i class="fa fa-trash-o"></i></th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr *ngFor="let order of api.orders;let i=index">
                      <td class="cart_product"><a href="#"><img [src]="api.getImgContent(order.UPC)"
                            alt="{{order.DESCRIPTION}}" style="width: 40px;"></a></td>
                      <td class="cart_description">
                        <p class="product-name"><a href="#"> {{order.DESCRIPTION}} </a></p>
                        <small class="cart_ref">SKU : #{{order.UPC}}</small><br>
                      <td class="cart_avail"><span class="label label-success">In stock</span></td>
                      <td class="price"><span>{{order.PRICE|currency}}</span></td>
                      <td class="qty"><input class="form-control input-sm" type="text" value="{{order.QTY}}">
                        <a href="javascript:;" (click)="add(i)"><i class="fa fa-plus"></i></a> <a href="javascript:;"
                          (click)="minus(i)"><i class="fa fa-minus"></i></a>
                      </td>
                      <td class="price"><span>{{order.PRICE*order.QTY|currency}}</span></td>
                      <td class="action"><a href="javascript:;" (click)="deleteitem(order)">Delete item</a></td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="2" rowspan="5"></td>
                      <td colspan="3">Sub Total products</td>
                      <td colspan="2"> {{Subtotal|currency}}</td>
                    </tr>
                    <tr>
                      <td colspan="3">Tax</td>
                      <td colspan="2"> {{Tax|currency}}</td>
                    </tr>
                    <tr>
                      <td colspan="3">Shipping fee</td>
                      <td colspan="2">{{shippingfee|currency}}</td>
                    </tr>
                    <tr>
                      <td colspan="3">Service</td>
                      <td colspan="2">{{servicefee|currency}}</td>
                    </tr>
                    <tr>
                      <td colspan="3"><strong>Total</strong></td>
                      <td colspan="2"><strong> {{api.purchaseamount|currency}}</strong></td>
                    </tr>
                  </tfoot>
                </table>
                <div class="loader" *ngIf="Showspinner"></div>
                <div class="cart_navigation">
                  <button class="pull-right" mat-raised-button color="warn" [disabled]="checkvalidation()" 
                    (click)="pay()">Place Order</button>
                  <!-- <button class="button pull-right" [disabled]="discanpay" style="height:50px" (click)="pay()">Place Order</button>-->
                </div>
              </div>
            </div>

          </div>

        </div>

      </section>
    </div>
  </div>
</div>
<!--End main-container -->
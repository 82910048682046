<div class="breadcrumb mb-0 p-0">
    <div class="row">
        <div class="col-sm-11">
            <h2> <a>
                    <mat-icon>shopping_cart</mat-icon>
                </a>Incoming Orders</h2>
        </div>
        <div class="col-sm-1">
            <a href="javascript:;" (click)="api.CloseW()"> <img src="assets/images/Close.png" style="width: 40px; "></a>
        </div>
    </div>
</div>
<div class="ms-content-wrapper long" (window:resize)="onResize($event)">
    <div class="row">
        <div class="col-md-12">
            <div class="col-12">
                <div class="ms-panel">
                    <div class="ms-panel-header">
                        <h1 style="font-weight: bold;">Customer Order List</h1>
                    </div>
                    <div class="ms-panel-body custable">
                        <div class="table-responsive">
                            <table class="table table-hover thead-primary table-striped">
                                <thead >
                                    <tr style="background-color:rgb(70, 105, 107);color:aliceblue">
                                        <th scope="col">Order ID</th>
                                        <th scope="col">Customer Name</th>
                                        <th scope="col">Location</th>
                                        <th scope="col">Order Status</th>
                                        <th scope="col">Order Time</th>
                                        <th scope="col">Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let items of orders | paginate: config">
                                        <th scope="row"><a href="javascript:;" (click)="orderN(items)">
                                                {{items.IDTR}}</a> </th>
                                        <td> {{items.CONTACT}}</td>
                                        <td>{{getaddress(items.SHIPPING)}}</td>
                                        <td><span [ngClass]="setclass(items.STATUSPROCC)" >{{items.STATUSPROCC}}</span></td>
                                        <td>{{items.DATETR|date:'MM-dd-yy'}} - {{items.TIME}}</td>
                                        <td>{{items.TOTAL|currency:'USD'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    </div>
</div>

<div class="fter">
    <mat-chip-list>
        <mat-chip class="chiptr">
            <mat-icon>shopping_cart</mat-icon>
            <label>Total Orders: {{orders.length}}</label>
        </mat-chip>
        <mat-chip class="chiptr" [(style)]="api.status.bk">
            <label style=" color:white">{{api.status.text}}</label>
        </mat-chip>
    </mat-chip-list>
</div>
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { SettingsComponent } from '../component/settings/settings.component';
import { ApiProvider } from '../Services/api';
import * as $ from 'jquery';
import { DataService } from '../Services/data.service';

@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.css']
})
export class MainpageComponent implements OnInit {
  public toggled: boolean = false;
  public subscriber: any;
  public results: any = "";
  public SearchCtrlFilter: FormControl = new FormControl();
  public Socketid: string = "";
  public Isconnected: boolean = false;
  public traymess: any = [];
  public messid: string = "";
  private onDestroy$ = new Subject<boolean>();
  @Input() requiredFileType: string = "";
  constructor(public api: ApiProvider, private router: Router, public dialog: MatDialog,
    private sanitizer: DomSanitizer, private dataService: DataService) { }

    public para1: string = "";
    public para2: string = "";
    public para3: string = "";
    ngOnInit() {
      if (!this.api.IsLoged()) this.router.navigateByUrl("/login");
      //  console.log(this.api.userprofile);
      $(document).ready(() => {
        $("#sidebar-toggle").click(function () {
          $(".sidebar").toggleClass("active");
        });
        $(".menu-toggle").click(function () {
          $("body").toggleClass("widescreen");
          console.log(window.screen.availWidth);
        });
      });
      var date = new Date();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
  
      var month = date.getMonth();
      var day = date.getDate();
      var year = date.getFullYear();
      var dayname = date.getDay();
  
      var monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
      var week = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  
      this.para1 = hours + "." + minutes + ampm;
  
      this.para2 = week[dayname];
      this.para3 = day + " " + monthNames[month] + " " + year;
      this.api.connect.connectserver();
      this.api.connect.getMessages().subscribe((mess: any) => {
        console.log(mess);
        if(mess.device==this.api.IDCLOUD){
          var route="ADMIN";
          if(mess.cmd=="1")  route="ORDERS";
          if(mess.cmd=="2")  route="OUTGOING";
          var data1 = {
            route: route,
            data: mess.data
          }
          this.dataService.changeData(data1);
        }
   
  
      });
      this.api.connect.isOpenConnection().subscribe(connected => {
        console.log(this.api.connect.socket.ioSocket.id);
        this.api.status.bk="background-color: rgb(68, 126, 66);";
        this.api.status.text="ONLINE";
        if (connected && !this.api.connect.isopen) this.api.connect.Reconnect(this.api.connect.socket.ioSocket.id);
      })
  
      this.api.connect.isdisconneted().subscribe(disconnected => {
        if (disconnected) {
         this.api.status.bk="background-color: rgb(253, 7, 7);";
         this.api.status.text="OFFLINE";
          this.api.connect.isopen = false}
      })
  
    }
    logout() {
  
      this.api.userprofile = {};
      this.traymess = [];
      this.api.menusel = -10;
      // this.subscriber.unsubscribe();
      //  this.api.connect.close();
  
      this.router.navigateByUrl("/login");
  
    }
    loadDefault(event: any) {
      // console.log("error");
      this.api.userimagen = "assets/images/icon1.png";
  
    }
    getImgContent(imgFile: any): SafeUrl {
      return this.sanitizer.bypassSecurityTrustUrl(imgFile);
    }
    selectmenu(item: number) {
      var active: boolean = false;
   //   console.log(this.api.userprofile.GROUP2);
      if (item == 0 && this.api.userprofile.GROUP1.substring(0, 1) == "1") active = true;
      if (item == 1 && this.api.userprofile.GROUP1.substring(1, 2) == "1") active = true;
      if (item == 2 && this.api.userprofile.GROUP1.substring(2, 3) == "1") active = true;
      if (item == 3 && this.api.userprofile.GROUP1.substring(3, 4) == "1") active = true;
      if (item == 4 && this.api.userprofile.GROUP1.substring(4, 5) == "1") active = true;
      if (item == 5 && this.api.userprofile.GROUP1.substring(5, 6) == "1") active = true;
      if (item == 6 && this.api.userprofile.GROUP1.substring(6, 7) == "1") active = true;
      if (item == 7 && this.api.userprofile.GROUP1.substring(7, 8) == "1") active = true;
      if (item == 71 && this.api.userprofile.GROUP1.substring(8, 9) == "1") active = true;
      if (item == 8 && this.api.userprofile.GROUP2.substring(0,1) == "1") active = true;
      if (item == 9 && this.api.userprofile.GROUP2.substring(2,3) == "1") active = true;
     
      if (item == -1 && this.api.userprofile.ISADM == 'True') active = true;
  
      if (active) this.api.menusel = item;
      else this.api.presentToast("ACCESS DENIED...","",2000);
      
    }
    Profile() {
      const dialogRef = this.dialog.open(SettingsComponent, {
        width: '90%', height: '90%',
        data: {
          "Name": "",
          "Phone": ""
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        // console.log(result);
        this.api.userimagen = result;
  
      });
    }
  
    onFileSelected(event: any) {
      const file: File = event.target.files[0];
  
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          var text = reader.result;
          var textline: any = text?.toString().split("\n");
          var linepos: any = {};
          var line = {
            ID: "",
            FIRSTNAME: "",
            LASTNAME: "",
            CELL: "",
            ADDRESS: "",
            ZIPCODE: "",
            COUNTRY: ""
          };
          var dat: any = [];
          for (let i = 0; i < textline.length; i++) {
            //  console.log(textline[i].toString().indexOf("<span id=") );
            if (textline[i].toString().indexOf("<span id=") > -1) {
              linepos = textline[i].split("|");
              line.ID = linepos[0].replace('<span id="', '');
              var j = linepos[2].toString().indexOf(" ");
              line.FIRSTNAME = linepos[2].toString().substring(0, j);
              line.LASTNAME = linepos[2].toString().substring(j + 1, linepos[2].toString().length);
              var phone = linepos[3];
              phone = phone.replace("(", "");
              phone = phone.replace(")", "");
              phone = phone.replace(" ", "");
              phone = phone.replace("-", "");
              line.CELL = phone;
              line.ADDRESS = linepos[5] + " " + linepos[11];
              line.ZIPCODE = linepos[8];
              line.COUNTRY = linepos[12];
              dat.push(line);
              line = {
                ID: "",
                FIRSTNAME: "",
                LASTNAME: "",
                CELL: "",
                ADDRESS: "",
                ZIPCODE: "",
                COUNTRY: ""
              };;
  
            }
          }
          console.log(dat);
       
  
        };
  
        reader.readAsText(file);
  
      }
    }

}

<div class="wrapper">
    <!--top bar-->
    <div class="topbar">
        <div class="topbar-left">
            <div class="text-center">
                <a href="home"><img src="assets/images/logo.png" alt="" style="height:35px;margin-top: 10px;"></a>
            </div>
        </div>
        <div
            style="margin-top: 15px;margin-left: 200px; font-size: 18px;font-weight: bolder;color: rgb(160, 143, 143);position:absolute;">
            <span> {{api.userprofile.COMPANY_NAME}}</span>
        </div>
        <!--
        <form class="pull-left app-search hidden-xs">
            <input type="text" class="form-control" placeholder="Search here...">
            <i class="fa fa-search"></i>
        </form>
        -->
        <ul class="nav navbar-nav  top-right-nav hidden-xs">
           
            <li>
                <div style="margin-top: 10px;font-size: 16px;font-weight: bolder;color: rgb(212, 135, 107);">
                    <span>{{para2}}</span>
                    <span>{{para3}}</span>
                    <span> {{para1}}</span>
                </div>
            </li>

            <li class="dropdown  hidden-xs">
                <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                    <i class="fa fa-bell-o"></i>
                    <span class="badge">5</span></a>
                <ul class="dropdown-menu dropdown-lg">
                    <li><a>Notifications (3)</a></li>
                    <li class="clearfix"><a href="#"><i class="fa fa-twitter"></i>
                            <div class="drop-content">
                                <h4>New Follower</h4>
                                <span>3 New Follower</span>
                            </div>
                        </a>
                    </li>
                    <li class="clearfix"><a href="#"><i class="fa fa-envelope-o"></i>
                            <div class="drop-content">
                                <h4>New Emails</h4>
                                <span>3 New Emails</span>
                            </div>
                        </a>
                    </li>
                    <li class="clearfix"><a href="#"><i class="fa fa-tasks"></i>
                            <div class="drop-content">
                                <h4>Pending tasks</h4>
                                <span>you have 3 pending tasks</span>
                            </div>
                        </a>
                    </li>
                    <li class="text-right"><a href="#">View All Notifications</a></li>
                </ul>
            </li>

            <li class="dropdown profile-link hidden-xs">
                <div class="clearfix">
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                        <img src="../assets/images/icon1.png" alt="" class="pull-left" style="width:36px;height:36px">
                        <span>{{api.userprofile.NAME}} <br>
                            <em>User</em>
                        </span>
                    </a>
                    <ul class="dropdown-menu">
                        <li><a href="../admin/account.aspx">Account</a></li>
                        <li><a href="javascript:;" (click)="logout()">Logout</a></li>
                    </ul>
                </div>

            </li>
            <li class=" hidden-xs"><a href="#" id="sidebar-toggle"><i class="fa fa-comments-o"></i></a></li>
        </ul>

    </div>
    <!--end top bar-->
    <div class="form-row">

        <div class="col-md-1 mb-3">

            <!--left menu start-->
            <div class="side-menu left" id="side-menu">
                <ul class="metismenu clearfix" id="menu" data-simplebar="true">
                    <li class="active"><a href="javascript:;" (click)="selectmenu(0)"><i class="fa fa-home"></i>
                            <span>Dashboard</span></a>
                    </li>
                    <li>
                        <a href="#collapse1" data-toggle="collapse"><i class="fa fa-bar-chart-o">
                            </i> <span>Orders</span><span class="fa arrow"></span>

                        </a>
                        <ul class="nav nav-second-level collapse" id="collapse1">
                            <li><a class="menuitem" href="javascript:;" (click)="selectmenu(1)">Incoming</a></li>
                            <li><a class="menuitem" href="javascript:;" (click)="selectmenu(2)">Outgoing</a></li>
                        </ul>
                    </li>
                    <!--<li>
                <a href="#collapse2" data-toggle="collapse"><i class="fa fa-envelope"></i> <span>Mailbox </span><span class="fa arrow"></span></a>
                 <ul class="nav nav-second-level collapse" id="collapse2">
                    <li><a href="javascript:;">Inbox</a></li>
                    <li><a href="javascript:;">Compose email</a></li>
                        </ul>
                    </li>-->

                    <li>
                        <a href="#collapse3" data-toggle="collapse"><i class="fa fa-edit"></i>
                            <span>Maintenance</span><span class="fa arrow"></span></a>
                        <ul class="nav nav-second-level collapse" id="collapse3">
                            <li><a class="menuitem" href="javascript:;" (click)="selectmenu(3)">Departments</a></li>
                            <li><a class="menuitem" href="javascript:;" (click)="selectmenu(4)">Products</a></li>
                            <li><a class="menuitem" href="javascript:;" (click)="selectmenu(5)">Rewards Products</a>
                            </li>

                        </ul>
                    </li>
                    <li>
                        <a href="#collapse4" data-toggle="collapse"><i class="fa fa-table"></i>
                            <span>Portal Access</span><span class="fa arrow"></span></a>
                        <ul class="nav nav-second-level collapse" id="collapse4">
                            <li><a class="menuitem" href="javascript:;" (click)="selectmenu(6)">Add Company</a></li>
                            <li><a class="menuitem" href="javascript:;" (click)="selectmenu(7)">Add Users</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href="#collapse5" data-toggle="collapse"><i class="fa fa-file-archive-o"></i>
                            <span>Reports</span><span class="fa arrow"></span></a>
                        <ul class="nav nav-second-level collapse" id="collapse5">
                            <li><a class="menuitem" href="javascript:;" (click)="selectmenu(8)">Sales</a></li>
                            <li><a class="menuitem" href="javascript:;" (click)="selectmenu(9)">Products</a></li>
                            <li><a class="menuitem" href="javascript:;" routerLink="/printreport">Test</a></li>
                        </ul>
                    </li>
                </ul>
                <div class="nav-bottom clearfix">
                    <a href="javascript:;" style="border-right: 0px; color: #f54b0f;"><i class="fa fa-lock"></i></a>
                    <a href="javascript:;" (click)="fileUpload.click()" style="border-right: 0px; color: #f54b0f;">
                        <i class="fa fa-download"></i></a>
                    <div hidden>
                        <input type="file" class="file-input" [accept]="requiredFileType"
                            (change)="onFileSelected($event)" #fileUpload>
                    </div>
                    <a href="javascript:;" style="border-right: 1px; color: #f54b0f;"><i class="fa fa-globe"></i></a>

                </div>
            </div>
            <!--left menu end-->
        </div>
        <div class="col-md-11 mb-3 wideclass">
            <div class="content">
                <div *ngIf="api.menusel==-10">
                    <div class="parent">

                    </div>
                </div>
                <app-users *ngIf="api.menusel==-1"></app-users>
                <app-dashboard *ngIf="api.menusel==0"></app-dashboard>
                <app-incoming-order *ngIf="api.menusel==1"></app-incoming-order>
                <app-outgoing-order *ngIf="api.menusel==2"></app-outgoing-order>
                <app-departmets *ngIf="api.menusel==3"> </app-departmets>
                <app-productlist *ngIf="api.menusel==4"></app-productlist>
                <app-promotional *ngIf="api.menusel==5"></app-promotional>
                <app-agencies *ngIf="api.menusel==6"></app-agencies>
                <app-users *ngIf="api.menusel==7"></app-users>
                <app-print-report *ngIf="api.menusel==8"></app-print-report>
                <app-delivery *ngIf="api.menusel==9"></app-delivery>
            </div>
            <!--content page-->
        </div>
    </div>
    <div class="sidebarHM">
        <div class='nicescroll'>
            <h4>Quick Support</h4>
            <form>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Name">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Email">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Subject">
                </div>
                <div class="form-group">
                    <textarea class="form-control" rows='5' placeholder="Name"></textarea>
                </div>
                <div class="text-right">
                    <button type="button" class=" btn btn-theme btn-lg">Send</button>
                </div>
            </form>
        </div>
    </div>

    <div class="footerHM">
        <span>Copyright &copy; {{api.getYear()}}. Cherry Valley. Version :({{api.VERSION}})</span>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ApiProvider } from 'src/app/Services/api';
import { UsersAddComponent } from '../users-add/users-add.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  public user: any = {};
 
  constructor(public api: ApiProvider, public dialog: MatDialog,private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.loaduser();
  }


  loaduser() {

    this.api.get(this.api.endpoint + '/login').subscribe(data => {
         console.log(data);
      var dat1 = data;
      this.user =null;
      if (dat1.toString().length > 10) {
        var data1: any = data;
        if (data1.StatusCode == 200) {
          this.user = JSON.parse(atob(data1.MESSAGE));
        //  console.log(this.user);
        }
        else {
          this.api.presentToast(data1.MESSAGE, "", 2000);
        }
      }
      else {


      }
    }, error => {

    });


  }
  setflag(it: string) {
    return this.api.toBoolean(it);
  }
  Adduser() {
    const dialogRef = this.dialog.open(UsersAddComponent, {panelClass: 'my-class',
      width: '70%', height: '95%',
      data: {
        "TITLE": "Create",
        "INFO": null
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == "1") this.loaduser();
    });
  }

  edit(item: any) {
    const dialogRef = this.dialog.open(UsersAddComponent, {panelClass: 'my-class',
         data: {
        "TITLE": "Update",
        "INFO": item
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == "1") this.loaduser();


    });
  }
  delete(id: string) {
    
    this.api.ConfirmationDlg("This Procedure Delete records permanently, Are you sure you want to continue?",
    "WARNING....","Yes","No").then(result=>{
      if(result){

        this.api.delete(this.api.endpoint + '/login/' + id).subscribe(data => {
          this.loaduser();
         });

      }
    })
    
  }

  CloseW()
  {
    this.api.menusel=-10;
  }
  loadDefault(event: any) {
 
 //   (event.target as HTMLImageElement).style.display = 'none';
 //this.image= "assets/images/icon1.png";
  
   }
   getImgContent(item:any): SafeUrl {
   // console.log(imgFile);
   if(item.ISPHOTO=='False') return "assets/images/icon1.png";
   else
       return this.sanitizer.bypassSecurityTrustUrl( this.api.endointdata+'/data/Images/' + item.ID + '.jpg');
   }
 

 

}



<!-- our features -->
<div class="our-features-box hidden-xs" style="background-color:#881f66;">
  <div class="container">
    <div class="row">
      <div class="col-md-3 col-xs-12 col-sm-6">
        <div class="feature-box first"> <i class="icon-plane icons"></i>
          <div class="content">
            <h6>Free & Next Day Delivery</h6>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-xs-12 col-sm-6">
        <div class="feature-box"> <i class="icon-earphones-alt icons"></i>
          <div class="content">
            <h6>Support 24/7 For Clients</h6>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-xs-12 col-sm-6">
        <div class="feature-box"> <i class="icon-like icons"></i>
          <div class="content">
            <h6>100% Satisfaction Guarantee</h6>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-xs-12 col-sm-6">
        <div class="feature-box last"> <i class="icon-tag icons"></i>
          <div class="content">
            <h6>Great Daily Deals Discount</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- JTV Home Slider -->

<div class="jtv-slideshow" #banner>
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div id='jtv-rev_slider_wrapper' class='rev_slider_wrapper fullwidthbanner-container'>
          <div id='jtv-rev_slider' #revslider class='rev_slider fullwidthabanner'>
            <ul>
              <li data-transition='random' data-slotamount='7' data-masterspeed='1000'
                data-thumb='assets/images/slider/slide-img1.jpg'><img src="assets/images/slider/slide-img1.jpg"
                  class="imgclass" alt="slide-img" data-bgposition='left top' data-bgfit='cover'
                  data-bgrepeat='no-repeat' />
                <div class="info">
                  <div class='tp-caption jtv-sub-title sft  tp-resizeme ' data-endspeed='500' data-speed='500'
                    data-start='1100' data-easing='Linear.easeNone' data-splitin='none' data-splitout='none'
                    data-elementdelay='0.1' data-endelementdelay='0.1'
                    style='z-index:2;max-width:auto;max-height:auto;white-space:nowrap;'><span>Growing Refresh
                      Together</span> </div>
                  <div class='tp-caption jtv-large-title sfl  tp-resizeme ' data-endspeed='500' data-speed='500'
                    data-start='1300' data-easing='Linear.easeNone' data-splitin='none' data-splitout='none'
                    data-elementdelay='0.1' data-endelementdelay='0.1'
                    style='z-index:3;max-width:auto;max-height:auto;white-space:nowrap;'><span>100% Natural</span>
                  </div>
                  <div class='tp-caption Title sft  tp-resizeme ' data-endspeed='500' data-speed='500' data-start='1450'
                    data-easing='Power2.easeInOut' data-splitin='none' data-splitout='none' data-elementdelay='0.1'
                    data-endelementdelay='0.1' style='z-index:4;max-width:auto;max-height:auto;white-space:nowrap;'>
                    Quality & Freshness
                    Guaranteed! Good Health.</div>
                  <div class='tp-caption sfb  tp-resizeme ' data-endspeed='500' data-speed='500' data-start='1500'
                    data-easing='Linear.easeNone' data-splitin='none' data-splitout='none' data-elementdelay='0.1'
                    data-endelementdelay='0.1' style='z-index:4;max-width:auto;max-height:auto;white-space:nowrap;'>
                    <a href='#' class="jtv-shop-now-btn">Shop Now</a>
                  </div>
                </div>
              </li>
              <li data-transition='random' data-slotamount='7' data-masterspeed='1000'
                data-thumb='assets/images/slider/slide-img2.jpg'><img src="assets/images/slider/slide-img2.jpg"
                  alt="slide-img" data-bgposition='left top' data-bgfit='cover' data-bgrepeat='no-repeat' />
                <div class="info">
                  <div class='tp-caption jtv-sub-title sft slide2  tp-resizeme ' data-endspeed='500' data-speed='500'
                    data-start='1100' data-easing='Linear.easeNone' data-splitin='none' data-splitout='none'
                    data-elementdelay='0.1' data-endelementdelay='0.1'
                    style='z-index:2;max-width:auto;max-height:auto;white-space:nowrap;padding-right:0px'>
                    <span>Daily Fresh Refresh Food</span>
                  </div>
                  <div class='tp-caption jtv-large-title sfl  tp-resizeme ' data-endspeed='500' data-speed='500'
                    data-start='1300' data-easing='Linear.easeNone' data-splitin='none' data-splitout='none'
                    data-elementdelay='0.1' data-endelementdelay='0.1'
                    style='z-index:3;max-width:auto;max-height:auto;white-space:nowrap;'>Fresh Vegetables</div>
                  <div class='tp-caption Title sft  tp-resizeme ' data-endspeed='500' data-speed='500' data-start='1500'
                    data-easing='Power2.easeInOut' data-splitin='none' data-splitout='none' data-elementdelay='0.1'
                    data-endelementdelay='0.1' style='z-index:4;max-width:auto;max-height:auto;white-space:nowrap;'>Forn
                    the Greatest Health
                    Benefits! Refreshing & Tasty!</div>
                  <div class='tp-caption sfb  tp-resizeme ' data-endspeed='500' data-speed='500' data-start='1500'
                    data-easing='Linear.easeNone' data-splitin='none' data-splitout='none' data-elementdelay='0.1'
                    data-endelementdelay='0.1' style='z-index:4;max-width:auto;max-height:auto;white-space:nowrap;'>
                    <a href='#' class="jtv-shop-now-btn">Find out more</a>
                  </div>
                </div>
              </li>
              <li data-transition='random' data-slotamount='7' data-masterspeed='1000'
                data-thumb='assets/images/slider/slide-img3.jpg'><img src="assets/images/slider/slide-img3.jpg"
                  alt="slide-img" data-bgposition='left top' data-bgfit='cover' data-bgrepeat='no-repeat' />
                <div class="info">
                  <div class='tp-caption jtv-sub-title sft slide2  tp-resizeme ' data-endspeed='500' data-speed='500'
                    data-start='1100' data-easing='Linear.easeNone' data-splitin='none' data-splitout='none'
                    data-elementdelay='0.1' data-endelementdelay='0.1'
                    style='z-index:2;max-width:auto;max-height:auto;white-space:nowrap;padding-right:0px'><span>Save
                      up to 35% off</span> </div>
                  <div class='tp-caption jtv-large-title sfl  tp-resizeme ' data-endspeed='500' data-speed='500'
                    data-start='1300' data-easing='Linear.easeNone' data-splitin='none' data-splitout='none'
                    data-elementdelay='0.1' data-endelementdelay='0.1'
                    style='z-index:3;max-width:auto;max-height:auto;white-space:nowrap;'>Fruit Flavoured</div>
                  <div class='tp-caption Title sft  tp-resizeme ' data-endspeed='500' data-speed='500' data-start='1500'
                    data-easing='Power2.easeInOut' data-splitin='none' data-splitout='none' data-elementdelay='0.1'
                    data-endelementdelay='0.1' style='z-index:4;max-width:auto;max-height:auto;white-space:nowrap;'>Most
                    Popular Ecommerce HTML
                    Template.</div>
                  <div class='tp-caption sfb  tp-resizeme ' data-endspeed='500' data-speed='500' data-start='1500'
                    data-easing='Linear.easeNone' data-splitin='none' data-splitout='none' data-elementdelay='0.1'
                    data-endelementdelay='0.1' style='z-index:4;max-width:auto;max-height:auto;white-space:nowrap;'>
                    <a href='#' class="jtv-shop-now-btn">Order now</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- end JTV Home Slider -->

    </div>
  </div>
</div>


<div class="content-page">
  <div class="container">
    <!-- Product Tabs  special prices-->
    <div class="category-product">
      <div class="navbar nav-menu">
        <div class="navbar-collapse">
          <div class="jtv-title">
            <h1 style="font-weight: bold;font-size: x-large;">Featured Products</h1>
          </div>
        </div>
        <!-- /.navbar-collapse -->

      </div>
      <div class="tab-container">
        <!-- tab product -->
        <div class="tab-panel active" id="tab-1">
          <div class="category-products ">
            <ul class="products-grid">
              <li class="item col-lg-3 col-md-3 col-sm-4 col-xs-6" *ngFor="let it of featured">
                <div class="item-inner ">
                  <div class="item-img ">
                    <div class="item-img-info"> <a class="product-image" title={{it.DESCRIPTION}}
                        routerLink="/SingleProduct">
                        <div style="height: 250px;">
                          <img alt="Product Title Here" class="imagecl" src={{loadimage(it.UPC)}}
                            (error)="loadDefault($event)">
                        </div>
                      </a>
                      <div class="jtv-box-hover ">
                        <ul class="add-to-links ">
                          <li><a class="link-quickview" href="quick_view.html"><i
                                class="icon-magnifier-add icons"></i><span class="hidden">Quick View</span></a></li>
                          <li><a class="link-wishlist" routerLink="/Wishlist"><i class="icon-heart icons"></i><span
                                class="hidden">Wishlist</span></a></li>
                          <li><a class="link-compare" href="compare.html"><i class="icon-shuffle icons"></i><span
                                class="hidden">Compare</span></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="item-info backcolor">
                    <div class="info-inner">
                      <div class="item-title">
                        <h6> <a title="Product Title Here" (click)="getinfo(it)">{{it.DESCRIPTION}} </a></h6>
                      </div>
                      <div class="item-content">
                        <div class="rating"> <i class="fa fa-star-o"></i> <i class="fa fa-star-o"></i> <i
                            class="fa fa-star-o"></i> <i class="fa fa-star-o"></i> <i class="fa fa-star-o"></i>
                        </div>
                        <div class="item-price">
                          <div class="price-box"> <span class="regular-price"> <span
                                class="price">{{it.PRICE|currency:'USD'}}</span>
                            </span> </div>
                        </div>
                        <div class="action">
                          <button class="button btn-cart" type="button" title="" (click)="addcar(it)"
                            data-original-title="Add to Cart"><span>Add to Cart</span> </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="jtv-top-banner">
  <div class="container">
    <div class="imgbox"><img src="assets/images/banner1.jpg" alt=""></div>
    <div class="jtv-cont-box">
      <h3>100% <br>
        Natural</h3>
      <div class="jtv-line-bg"></div>
      <p>We offer superior quality products </p>
    </div>
    <div class="imgbox"><img src="assets/images/banner2.jpg" alt=""></div>
    <div class="jtv-cont-box2">
      <h3>Alway <br>
        Fresh</h3>
      <div class="jtv-line-bg"></div>
      <p>Todos nuestros vegetales son frescos.</p>
    </div>
    <div class="imgbox"><img src="assets/images/banner3.jpg" alt=""></div>
    <div class="jtv-cont-box3">
      <h3>Healthy <br>
        Cooking</h3>
      <div class="jtv-line-bg"></div>
      <p>Eat healthy live healthy.</p>
    </div>
  </div>
</div>



<!-- Brand Logo -->

<div class="brand-logo">
  <div class="container">
  
  </div>
</div>
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ApiProvider } from '../Services/api';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { CreditcardComponent } from '../component/creditcard/creditcard.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  public checkoutype: boolean = true;
  public shippingmethod: boolean = false;
  public typepayment: boolean = true;
  public password: string = "";
  public firstaname: string = "";
  public lastname: string = "";
  public shippingfee: number = 0;
  public servicefee: number = 0;
  public discanpay: boolean = false;
  private Saved: number = 0;
  public Subtotal: number = 0;
  public sameasbilling: boolean = false;
  public Tax: number = 0;
  public checkFormbilling!: FormGroup;
  public checkFormshipping!: FormGroup;
  Showspinner = false;
  userData = { "Account": "", "ExpirationMonth": "", "ExpirationYear": "", "CVV": "", "ZIP": "" };
  public states=[
    {"COD":"CT","NAME":"CONNECTICUT"},
    {"COD":"NJ","NAME":"NEW JESEY"},
    {"COD":"NY","NAME":"NEW YORK"},
];
  EMAILPATTERN = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
  constructor(public api: ApiProvider, private router: Router, public dialog: MatDialog, private datePipe: DatePipe) {
    this.createForm();
  }


  createForm() {
    /*
        this.checkFormbilling = new FormGroup({
          Name: new FormControl('Eduardo', [Validators.required, Validators.minLength(2)]),
          Lastname: new FormControl('Briceno', [Validators.required, Validators.minLength(3)]),
          Company: new FormControl(''),
          Email: new FormControl('eba@rbrsupport.net', [Validators.required, Validators.pattern(this.EMAILPATTERN)]),
          Address: new FormControl('1552 Lincoln', [Validators.required]),
          City: new FormControl('Bay Shore', [Validators.required]),
          State: new FormControl('CT', [Validators.required]),
          Zip: new FormControl('11706', [Validators.required]),
          Country: new FormControl('USA', [Validators.required]),
          Phone: new FormControl('6319432806', [Validators.minLength(10)]),
          Fax: new FormControl('', []),
          password: new FormControl('1234', [Validators.minLength(4)]),
          compassword: new FormControl('1234'),
        });
        */
    this.checkFormbilling = new FormGroup({
      Name: new FormControl('', [Validators.required, Validators.minLength(2)]),
      Lastname: new FormControl('', [Validators.required, Validators.minLength(3)]),
      Company: new FormControl(''),
      Email: new FormControl('', [Validators.required, Validators.pattern(this.EMAILPATTERN)]),
      Address: new FormControl('', [Validators.required]),
      City: new FormControl('', [Validators.required]),
      State: new FormControl('CT', [Validators.required]),
      Zip: new FormControl('', [Validators.required]),
      Country: new FormControl('USA', [Validators.required]),
      Phone: new FormControl('', [Validators.minLength(10)]),
      Fax: new FormControl('', []),
      password: new FormControl(''),
      compassword: new FormControl(''),
    });

    this.checkFormshipping = new FormGroup({
      Name: new FormControl('', [Validators.required, Validators.minLength(2)]),
      Lastname: new FormControl('', [Validators.required, Validators.minLength(3)]),
      Company: new FormControl(''),
      Email: new FormControl('', [Validators.required, Validators.pattern(this.EMAILPATTERN)]),
      Address: new FormControl('', [Validators.required]),
      City: new FormControl('', [Validators.required]),
      State: new FormControl('CT', [Validators.required]),
      Zip: new FormControl('', [Validators.required]),
      Country: new FormControl('USA', [Validators.required]),
      Phone: new FormControl('', [Validators.minLength(10)]),
      Fax: new FormControl('', []),

    });
    // this.checkForm.controls["fname"].setErrors( {'status': false} );

  }
  ngOnInit() {
    //  console.log(this.api.userWEBprofile);
    this.Subtotal=0;
    if (this.api.productCount == 0) this.router.navigateByUrl("/home");
    if (!this.api.userWEBprofile.login) {

      try {
        if (this.api.userWEBprofile.SHIPPING.Name === undefined) {
          var dat = {
            Name: ""
          }
          this.api.userWEBprofile.SHIPPING = dat;
        }
      }
      catch {
        var dat = {
          Name: ""
        }
        this.api.userWEBprofile.SHIPPING = dat;
      }
      try {

        if (this.api.userWEBprofile.BILLING.Name === undefined) {
          var dat = {
            Name: ""
          }
          this.api.userWEBprofile.BILLING = dat;
        }
      }
      catch {
        var dat = {
          Name: ""
        }
        this.api.userWEBprofile.BILLING = dat;
      }

    }
    else {
      if (this.api.userWEBprofile.BILLING.length < 2) {
        var name = this.api.userWEBprofile.NAME.split(' ');
        var dat1 = {
          Name: name[0],
          Lastname: name[1],
          Email: this.api.userWEBprofile.EMAIL,
          Company: "",
          Address: "",
          City: "",
          State: "CT",
          Zip: "",
          Country: "",
          Phone: "",
          Fax: "",
          password: "",
          compassword: ""
        }
        this.api.userWEBprofile.BILLING = dat1;
      }
      console.log(this.api.userWEBprofile.BILLING);
   //   this.checkFormbilling.setValue(this.api.userWEBprofile.BILLING);
      this.checkFormbilling.setValue(this.api.userWEBprofile.BILLING);
      if (this.api.userWEBprofile.SHIPPING.Name == undefined) {
        this.setshipping(true);
      }
     
    }

  }
  ngAfterViewInit() {
    this.calculate();
  }
  add(index: any) {
    this.api.orders[index].QTY += 1;
    this.api.UpdateOders();
    this.calculate();

  }
  minus(index: any) {
    if (this.api.orders[index].QTY == 1) return;
    this.api.orders[index].QTY -= 1;
    this.api.UpdateOders();
    this.calculate();

  }

  deleteitem(index: any) {
    this.api.orders = this.api.orders.filter((item: any) => item !== index);
    this.api.UpdateOders();
    this.calculate();
  }
  shippingck() {
    this.shippingmethod = !this.shippingmethod;
    this.calculate();
  }
  calculate() {

    this.api.productCount = 0;
    this.api.purchaseamount = 0;
    if (this.shippingmethod) {
      this.shippingfee = 5;
    }
    else {
      this.shippingfee = 0;
    }
    this.Subtotal = 0;
    this.Tax = 0;

    for (var i = 0; i < this.api.orders.length; i++) {
      this.api.productCount += this.api.orders[i].QTY;
      this.Tax += this.api.calcTax(this.api.orders[i].TAX, this.api.orders[i].QTY * this.api.orders[i].PRICE);
      this.Subtotal += this.api.orders[i].QTY * this.api.orders[i].PRICE;
    }
    this.api.purchaseamount = this.Subtotal + this.shippingfee + this.servicefee + this.Tax;

  }

  login() {

    if (this.api.userWEBprofile.language == undefined) this.api.userWEBprofile.language = "1";
    if (this.api.userWEBprofile.name == undefined) this.api.userWEBprofile.name = "";
    //  this.router.navigateByUrl("/home");
    //  console.log(this.api.userWEBprofile );
    //  console.log(this.signupform.get('email')?.value.toLowerCase());
    if (this.api.endpoint.length > 0) {
      var dat;
      if (this.api.userWEBprofile != null) {
        dat = {
          "EMAIL": this.api.userWEBprofile.EMAIL.toLowerCase(),
          "PASSWORD": this.password,
          "NAME": this.api.userWEBprofile.name,
          "FCMID": "",
          "LANG": this.api.userWEBprofile.language,
          "PICTURE": this.api.userWEBprofile.picture
        }
      }
      else {
        dat = {
          "EMAIL": this.api.userWEBprofile.EMAIL.toLowerCase(),
          "PASSWORD": this.password,
          "LANG": "1",
          "NAME": "",
          "PICTURE": "",
          "FCMID": ""
        }
      };
      //    console.log(dat);

      var dataout = {
        ID: "2",
        DATAFORM: btoa(JSON.stringify(dat))
      }

      this.api.post(this.api.endpoint + '/loginapp', JSON.stringify(dataout)).subscribe(async data => {
          // console.log(data);
        if (data.toString().length > 10) {
          var data1: any = data;
          if (data1.StatusCode == 200) {
            if (data1.ERROR == '1') {
              this.api.presentToast("Invalid Username", "", 2000);
            }
            else {
              var dat = {
                Name: "",
                Lastname: "",
                Email: "",
                Company: "",
                Address: "",
                City: "",
                State: "CT",
                Zip: "",
                Country: "",
                Phone: "",
                Fax: "",
                password: "",
                compassword: ""
              }
              var prof = JSON.parse(atob(data1.MESSAGE));
         //    console.log(prof);
              this.api.userWEBprofile = prof;
             
              this.api.userWEBprofile.login = true;
              var name = this.api.userWEBprofile.NAME.split(' ');
              this.firstaname = name[0];
              this.lastname = name[1];
          
              if (prof.SHIPPING.length > 2) {
                this.api.userWEBprofile.SHIPPING = JSON.parse(prof.SHIPPING);
               this.api.userWEBprofile.SHIPPING.Name=this.firstaname;
              this.api.userWEBprofile.SHIPPING.Lastname=this.lastname;
              this.api.userWEBprofile.SHIPPING.Company="";
              this.api.userWEBprofile.SHIPPING.Email=prof.EMAIL;
              this.api.userWEBprofile.SHIPPING.Country="";
              this.api.userWEBprofile.SHIPPING.Phone=prof.PHONE;
              this.api.userWEBprofile.SHIPPING.Fax="";
              console.log( this.api.userWEBprofile.SHIPPING);
                this.checkFormshipping.setValue(this.api.userWEBprofile.SHIPPING);
              }
              else
               { this.api.userWEBprofile.SHIPPING = dat;}
              if (prof.BILLING.length > 2) {
                this.api.userWEBprofile.BILLING = JSON.parse(prof.BILLING);
                console.log( this.api.userWEBprofile.BILLING);
                this.checkFormbilling.setValue(this.api.userWEBprofile.BILLING);
              }
              else {
                dat.Name = this.firstaname;
                dat.Lastname = this.lastname;
                dat.Email = this.api.userWEBprofile.EMAIL;
                this.api.userWEBprofile.BILLING = dat;
                this.checkFormbilling.setValue(this.api.userWEBprofile.BILLING);
              }


              this.api.UpdateProfile();

            }
          }
          else {
            this.api.presentToast(data1.MESSAGE, "", 2000);
          }
        }
        else {
          this.api.presentToast("Invalid credentials", "", 2000);
          this.api.userWEBprofile.Username = "";
        }
      }, error => {
        this.api.presentToast("Server is not listening!!" + this.api.endpoint, "", 2000);
        this.api.userWEBprofile.Username = "";
      });


    }

  }

  pay() {
    if (this.typepayment) this.paycash();
    else
      this.creditcard();
  }
  creditcard() {
    const dialogRef = this.dialog.open(CreditcardComponent, {
      panelClass: 'my-class'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result != undefined) {
        this.completepayment(result);
      }

    });
  }
  paycash() {
    const card = {
      Cash: 'Yes',
      Brand: "",
      Amount: "",
      Account: "",
      AuthCode: "",
      Authorized: "",
      RefNo: "",
      Message: '',
      Code: '000000'
    };

    this.completepayment(card);
  }
  completepayment(data: any) {
    // console.log(data);
    var CCard = {};
    if (data["Code"] === "000000") {
      if (data["Cash"] === 'No') {
        CCard = {
          Account: this.userData.Account,
          Brand: data["Brand"],
          email: this.api.userWEBprofile.EMAIL,
          Expirationmonth: this.userData.ExpirationMonth,
          Expirationyear: this.userData.ExpirationYear,
          CVV: this.userData.CVV,
          ZIPCODE: this.userData.ZIP,
          Token: ""
        };
        // var d2 = JSON.parse(JSON.stringify(datasale));
        this.api.SaveCreditcard(CCard);
        //if Authorized<total  hacer un void
      }
      else {
        this.Showspinner = true;
      }

      var payment = {
        CASH: data["Cash"],
        Account: data["Account"],
        Brand: data["Brand"],
        Amount: data["Amount"],
        AuthCode: data["AuthCode"],
        Authorized: data["Authorized"],
        RefNo: data["RefNo"],
        Token: data["Token"]
      };

      this.api.userWEBprofile.BILLING = this.checkFormbilling.value
      this.api.userWEBprofile.SHIPPING = this.checkFormshipping.value
      var ID = this.api.userWEBprofile.UID;

      if (this.checkoutype && ID == undefined) ID = "NEW";

      var submitOrder = {
        Register: this.api.userWEBprofile.TERMINAL,
        StoreId: this.api.IDCLOUD,
        CustmID: ID,
        Shipping: JSON.stringify(this.api.userWEBprofile.SHIPPING),
        Billing: JSON.stringify(this.api.userWEBprofile.BILLING),
        DateTr: this.datePipe.transform(new Date(), "MM/dd/yyyy "),
        Time: this.datePipe.transform(new Date(), "h:mm:ss a"),
        Contact: this.api.userWEBprofile.SHIPPING.Name + ' ' + this.api.userWEBprofile.SHIPPING.Lastname,
        Instructions: "",
        Total: this.api.purchaseamount,
        Subtotal: this.Subtotal,
        Tax: this.Tax,
        Delivery: this.shippingfee,
        Service: this.servicefee,
        Saved: this.Saved,
        Notes: this.api.EncrypAESCC(JSON.stringify(CCard)),
        Payment: btoa(JSON.stringify(payment)),
        Order: btoa(JSON.stringify(this.api.orders))
      };
          console.log(submitOrder);

      this.api.post(this.api.endpoint + '/Stores', JSON.stringify(submitOrder)).subscribe((data: any) => {
        //  console.log(data);
        if (data.toString() === '200') {
          this.Showspinner = false;
          var mess = "";
          mess = 'Your receipt has been sent to your email';
          this.api.productCount = 0;
          this.api.purchaseamount = 0;
          this.api.presentToast("Order Accepted.." + mess, "OK", 0);
          this.api.DeleteOrder();
          this.api.UpdateProfile();
          this.router.navigateByUrl("/home");


        }
        else {
          this.Showspinner = false;
          this.api.presentToast("Error Payment Required Order not Accepted", "", 3000);
        }
      }, error => {
        this.Showspinner = false;
        this.api.presentToast("Error Payment Required Order not Accepted", "", 3000);
      });

    }
    else {
      this.Showspinner = false;
      this.api.presentToast("Payment Error.. Error code: " + data["Code"] + " " + data["Message"], "OK", 0);
    }

  }
  setshipping(value: boolean) {


    if (!this.sameasbilling) {
      this.checkFormshipping.controls['Name'].setValue(this.checkFormbilling.controls['Name'].value);
      this.checkFormshipping.controls['Lastname'].setValue(this.checkFormbilling.controls['Lastname'].value);
      this.checkFormshipping.controls['Company'].setValue(this.checkFormbilling.controls['Company'].value);
      this.checkFormshipping.controls['Email'].setValue(this.checkFormbilling.controls['Email'].value);
      this.checkFormshipping.controls['Address'].setValue(this.checkFormbilling.controls['Address'].value);
      this.checkFormshipping.controls['City'].setValue(this.checkFormbilling.controls['City'].value);
      this.checkFormshipping.controls['State'].setValue(this.checkFormbilling.controls['State'].value);
      this.checkFormshipping.controls['Zip'].setValue(this.checkFormbilling.controls['Zip'].value);
      this.checkFormshipping.controls['Country'].setValue(this.checkFormbilling.controls['Country'].value);
      this.checkFormshipping.controls['Phone'].setValue(this.checkFormbilling.controls['Phone'].value);
      this.checkFormshipping.controls['Fax'].setValue(this.checkFormbilling.controls['Fax'].value);
    }
    else {
      this.checkFormshipping.controls['Name'].setValue('');
      this.checkFormshipping.controls['Lastname'].setValue('');
      this.checkFormshipping.controls['Company'].setValue('');
      this.checkFormshipping.controls['Email'].setValue('');
      this.checkFormshipping.controls['Address'].setValue('');
      this.checkFormshipping.controls['City'].setValue('');
      this.checkFormshipping.controls['State'].setValue('');
      this.checkFormshipping.controls['Zip'].setValue('');
      this.checkFormshipping.controls['Country'].setValue('');
      this.checkFormshipping.controls['Phone'].setValue('');
      this.checkFormshipping.controls['Fax'].setValue('');
    }
    this.sameasbilling = !value;
  }
  onPasswordChange() {
    if (this.confirm_password.value == this.passwordin.value) {
      this.confirm_password.setErrors(null);
    } else {
      this.confirm_password.setErrors({ mismatch: true });
    }
  }
  get passwordin(): AbstractControl {
    return this.checkFormbilling.controls['password'];
  }

  get confirm_password(): AbstractControl {
    return this.checkFormbilling.controls['compassword'];
  }

  checkvalidation() {
   
    if (this.checkoutype) {
  //    console.log(this.checkFormshipping.status);
   //   console.log(this.checkFormbilling.status);
      if (this.checkFormshipping.status == 'VALID' && this.checkFormbilling.status == 'VALID') return false;
      else return true;
    }
    else
      if (this.checkFormshipping.status == 'VALID') return false;
      else return true;
  }
}

import { Router } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
import { ApiProvider } from './Services/api';
import { LegalComponent } from './component/legal/legal.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PrivacyComponent } from './component/privacy/privacy.component';
import { DialogData } from './component/confirm-dlg/confirm-dlg.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'CherryValleysite';
  public Counter: string = "";
  public searchkey: string = "";
  constructor(public api: ApiProvider, public router: Router, public dialog: MatDialog) {

  }

  ngOnInit(): void {
    this.api.userWEBprofile.login=false;
    this.GetDepart();
    this.api.GetOrders();
    this.api.getStore().then((data: any) => {
      this.api.compheader = data[0];
    })
  };
  godepartment(index: string) {
    // console.log(index);
    this.Counter = index;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl("/shop_grid/", { state: { ID: index } });

  }
  GetDepart() {

    this.api.get(this.api.endpoint + '/StoreItems/GetDepartment/' + btoa(this.api.IDCLOUD)).subscribe((data: any) => {
      //  console.log(data);
      this.api.departments = JSON.parse(data.DEP.toString());
      this.api.Subdepartments = JSON.parse(data.SUB.toString());
      for (let i = 0; i < this.api.departments.length; i++) {
        if (i == 0) this.api.departments[i].selected = "1";
        else this.api.departments[i].selected = "0";
      }
      // console.log( this.api.departments);
      //  console.log( this.api.Subdepartments);
    });

  }
  Search() {
   // console.log(this.searchkey);
    var src="*,*," + this.searchkey;
    this.router.navigateByUrl("/shop_grid/"+ src  );
    this.searchkey = "";
  }
  getitemdep(id: string) {

    //  [routerLink]="['/shop_grid/',listd.ID]"
    this.router.navigateByUrl("/shop_grid", { state: { ID: id } });
  }
  logout() {
    this.api.userWEBprofile = {};
    this.api.userWEBprofile.login = false;
    try {
      if (this.api.userWEBprofile.SHIPPING.Name === undefined) {
        var dat = {
          Name: ""
        }
        this.api.userWEBprofile.SHIPPING = dat;
      }
    }
    catch {
      var dat = {
        Name: ""
      }
      this.api.userWEBprofile.SHIPPING = dat;
    }
    try {
      if (this.api.userWEBprofile.BILLING.Name === undefined) {
        var dat = {
          Name: ""
        }
        this.api.userWEBprofile.BILLING = dat;
      }
    }
    catch {
      var dat = {
        Name: ""
      }
      this.api.userWEBprofile.BILLING = dat;
    }
    this.api.UpdateProfile();
  }
  terms() {
    const dialogRef = this.dialog.open(LegalComponent, {
      panelClass: 'termclass',
      width: '90%'
    });

  }
  policy() {
    const dialogRef = this.dialog.open(PrivacyComponent, {
      panelClass: 'termclass',
      width: '90%'
    });

  }
  iarc() {
    this.dialog.open(DialogDataiarc, {
    });
  }
}

@Component({
  selector: 'dialog-data-example-dialog',
  templateUrl: 'IARC.html',
})
export class DialogDataiarc {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }
}
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MantenanceComponent } from '../component/mantenance/mantenance.component';
import { ApiProvider } from '../Services/api';

@Component({
  selector: 'app-productlist',
  templateUrl: './productlist.component.html',
  styleUrls: ['./productlist.component.css']
})
export class ProductlistComponent implements OnInit {
  public SearchCtrlFilter: FormControl = new FormControl();
  public Items: any = [];
  config: any;
  Showspinner = false;
  public deparments: any = [];
  public depsel: string = "0";
  public results: any = "";
  public subscriber: any;
  public subdeparments: any = [];
  public subdepsel: string = "0";
  constructor(public api: ApiProvider, public dialog: MatDialog, private sanitizer: DomSanitizer,
    private router: Router) { }


  ngOnInit(): void {
    if (this.api.userprofile.login == undefined) {
      this.router.navigateByUrl("/home");
    }
    else {
      this.config = {
        itemsPerPage: 7,
        currentPage: 1,
        totalItems: 0
      };
      this.deparments = JSON.parse("[" + (this.api.userprofile.DEPARTMENTS) + "]");
      if (this.deparments.length > 0) this.depsel = this.deparments[0].ID;
      this.updatedep();
      this.loadProduct();

      this.subscriber = this.SearchCtrlFilter.valueChanges
        .subscribe(filtered => {
          this.getsearch(this.SearchCtrlFilter.value);
        },
          error => {
          });
    }
  }
  setcolor(id: string) {
    if (id == this.depsel)
      return "primary";
    else
      return "";
  }
  selectdep(id: string) {
    this.depsel = id;
    this.updatedep();
    this.loadProduct();

  }
  updatedep() {
    this.subdeparments = this.api.getsubDepat(this.depsel);
    //  console.log(this.subdeparments);
    this.subdepsel = this.subdeparments[0].SID;
  }
  selecsubdep() {

    this.loadProduct();
  }
  loadProduct() {

    var dataout = {
      DEP: this.depsel,
      SUB: this.subdepsel,
      STORE: this.api.userprofile.IDCLOUD,
      DISTANCE: "1", //dist.replace("millas", ""),
      "LOCATIONLAT": "1", //  this.api.latitude,
      "LOCATIONLON": "1", // this.api.longitude,
      INDEX: this.api.iniIndex
    }

    //  console.log(dataout);
    this.Showspinner = true;
    this.api.get(this.api.endpoint + '/StoreItems/GetitemsAll/' + btoa(JSON.stringify(dataout))).subscribe((data: any) => {
      //   console.log(data);
      this.Showspinner = false;
      this.Items = [];
      if (data.length > 0) {

        this.Items = data;


      }
      else {


      }
    }, error => {

    });


  }
  setflag(it: string) {
    return this.api.toBoolean(it);
  }
  AddProduct() {
    const dialogRef = this.dialog.open(MantenanceComponent, {
      data: {
        "PRODUCTS": "",
        "DEP": "0"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == "1") this.loadProduct();
    });
  }

  edit(item: any) {
    const dialogRef = this.dialog.open(MantenanceComponent, {
      panelClass: 'my-class',
      data: {
        "PRODUCTS": item,
        "DEP": item.DEPID
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == "1") this.loadProduct();


    });
  }
  delete(id: string) {

    this.api.ConfirmationDlg("This Procedure Delete records permanently, Are you sure you want to continue?",
      "WARNING....", "Yes", "No").then(result => {
        if (result) {
          var dataout = {
            ID: id,
            IDCLOUD: this.api.userprofile.IDCLOUD
          }
          this.api.delete(this.api.endpoint + '/storeitems/' + id).subscribe(data => {
            this.loadProduct();
          });

        }
      })

  }
  getsearch(ev: any) {

    // var text = ev.target.value;
    var text = ev;
    // console.log(text.includes('/'));
   // console.log(ev);
    try {

      if (text.length > 4) {
        var dat = {
          IDCLOUD: this.api.userprofile.IDCLOUD,
          TEXT: text
        }

        this.api.get(this.api.endpoint + "/StoreItems/searchitemsST/" + btoa(JSON.stringify(dat))).subscribe(data => {
      //    console.log(data);
          this.results = data;
        }, error => {
          this.results = [];
        });

      }
    } catch (e) { }
  }

  Selected(UPC: string) {
    var query = "";
    this.results = [];
    console.log(UPC);
    var dataout = {
      UPC: UPC,
      STORE: this.api.userprofile.IDCLOUD
    }
    this.api.get(this.api.endpoint + '/StoreItems/Singletitems/' + btoa(JSON.stringify(dataout))).subscribe( (data: any) => {
    //  console.log(data);
      this.Items = [];
      if (data.length > 0) {

        this.Items = data;
        this.depsel = this.Items[0].DEPID;
        this.subdepsel = this.Items[0].SUBDEPID;
       this.updatedep();
     
       
       
      }
      else {


      }
    }, error => {

    });


  }
  Logout() {
    this.api.istitles = true;
    this.api.userprofile = "";
    this.router.navigateByUrl("/home");
  }
  loadDefault(event: any) {
    (event.target as HTMLImageElement).style.display = 'none';
   
  }
  getImgContent(item: any): SafeUrl {
    
    if (item.ISPHOTO == 'False') return "assets/images/icon1.png";
    else
      return this.sanitizer.bypassSecurityTrustUrl(this.api.endointdata + '/data/Images/' + item.ID + '.jpg');
  }

  getImg(item: any): SafeUrl {
    if (item.ISPHOTO == 'False') return "";
    else
    return this.sanitizer.bypassSecurityTrustUrl(this.api.endointdata +  item.UPC + '.jpg'  + '?'+Date.now());
 }

  pageChanged(event: any) {

    this.config.currentPage = event;
  }


}
